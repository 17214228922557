import { ActionTree } from "vuex";
import { MutationTypes } from "./mutations";
import { Actions } from "./interfaces";
import { State } from "./index";
import { RootState, store } from "@/store";
import * as fb from "../../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";

import { RtcTokenBuilder, RtcRole } from "agora-access-token";

// Actions
export enum ActionTypes {
	initializeCallRoom = "CREATE_VOICE_CALL_ROOM",
	sendNotification = "SEND_NOTIFICATION_TO_REMOTE_USER",
	reset = "RESET",
}

//
export const actions: ActionTree<State, RootState> & Actions = {
	// Initialize call room
	async [ActionTypes.initializeCallRoom]({ commit }, payload) {
		// 1 - GET REMOTE USER & LOCAL USER DATA

		const remoteUserUID = payload;

		// Fetch remote user data from Firestore
		const remoteUserDocRef = firebase.firestore().collection("users");
		const remoteUserObject = (
			await remoteUserDocRef.doc(remoteUserUID).get()
		).data();

		// Create remote user payload
		const remoteUserPayload = {
			uid: remoteUserObject?.uid || payload,
			name: remoteUserObject?.name,
			imgUrl: remoteUserObject?.avatar,
		};

		// Fetch user data from local store
		const localUserObject = store.getters.userProfile;

		// Create local user payload
		const localUserPayload = {
			uid: localUserObject.uid,
			name: localUserObject.name,
			imgUrl: localUserObject.avatar,
		};

		// 2 - GET CHANNEL FROM FIRESTORE OR CREATE AND PUBLISH A NEW ONE

		// Check if channel already exists
		const uid1 = localUserPayload.uid;
		const uid2 = remoteUserPayload.uid;

		const queryRef = firebase.firestore().collection("calls");
		const query1 = (await queryRef.doc(`${uid1}${uid2}`).get()).data();
		const query2 = (await queryRef.doc(`${uid2}${uid1}`).get()).data();
		const channel = query1 || query2;

		if (channel) {
			// If channel exists, generate token & set channel data into the store
			// Generate token
			const config = {
				appid: process.env.VUE_APP_AGORA_APP_ID || "not-set",
				appCertificate: process.env.VUE_APP_AGORA_APP_CERTIFICATE || "not-set",
				channel: channel.channel,
				uid: localUserPayload.uid,
			};

			const role = RtcRole.PUBLISHER;
			const expirationTimeSec = 3600;
			const currentTimestamp = Math.floor(Date.now() / 1000);
			const privilegeExpiryTimestamp = currentTimestamp + expirationTimeSec;

			// Build token with uid
			const _token = RtcTokenBuilder.buildTokenWithUid(
				config.appid,
				config.appCertificate,
				config.channel,
				config.uid,
				role,
				privilegeExpiryTimestamp
			);

			// Create RTC options
			const options = {
				appid: config.appid,
				channel: channel.channel,
				timestamp: channel.timestamp,
				token: _token,
			};
			// Set RTC options in the store
			commit(MutationTypes.setCallRoom, { ...options });
		} else {
			// Create new channel
			// Generate token
			const config = {
				appid: process.env.VUE_APP_AGORA_APP_ID || "not-set",
				appCertificate: process.env.VUE_APP_AGORA_APP_CERTIFICATE || "not-set",
				channel: `${uid1}${uid2}`,
				uid: localUserPayload.uid,
			};

			const role = RtcRole.PUBLISHER;
			const expirationTimeSec = 3600;
			const currentTimestamp = Math.floor(Date.now() / 1000);
			const privilegeExpiryTimestamp = currentTimestamp + expirationTimeSec;

			// Build token with uid
			const _token = RtcTokenBuilder.buildTokenWithUid(
				config.appid,
				config.appCertificate,
				config.channel,
				config.uid,
				role,
				privilegeExpiryTimestamp
			);

			// Create RTC options
			const options = {
				appid: config.appid,
				channel: config.channel,
				timestamp: new Date(),
				token: _token,
			};

			// Publish the channel to firestore so remote user can access it
			const docRef = firebase.firestore().collection("calls");
			await docRef
				.doc(config.channel)
				.set({ channel: options.channel, timestamp: options.timestamp })
				.then(() => {
					// Set RTC options in the store
					commit(MutationTypes.setCallRoom, { ...options });
				});
		}

		// Set remote user data into the store
		commit(MutationTypes.setRemoteUser, remoteUserPayload);
	},

	// Send notification to remote user
	async [ActionTypes.sendNotification](
		{ commit },
		payload: {
			id: string;
			receiverId: string;
			receiverAvatar: string;
			time: firebase.firestore.Timestamp | string;
		}
	) {
		// Get user payload
		const localUserObject = store.getters.userProfile;

		// FCM Payload
		const fcmPayload = {
			id: payload.id,
			senderId: localUserObject.uid,
			receiverId: payload.receiverId,
			senderAvatar: localUserObject.avatar,
			receiverAvatar: payload.receiverAvatar,
			name: localUserObject.name,
			message: "Incoming call",
			time: payload.time,
		};

		//
		const receiverTokens = await firebase
			.firestore()
			.collection("users")
			.doc(payload.receiverId)
			.collection("fcmTokens")
			.get();

		if (receiverTokens) {
			receiverTokens.forEach((doc: any) => {
				const token = doc.data().token;
				if (token) {
					const toSend = {
						to: token,
						notification: {
							title: `Incoming Call From ${fcmPayload.name}`,
							body: fcmPayload.message,
							mutable_content: true,
							icon: fcmPayload.senderAvatar,
							image: fcmPayload.senderAvatar,
						},
						android: {
							notification: {
								image: fcmPayload.senderAvatar,
							},
						},
						apns: {
							payload: {
								aps: {
									"mutable-content": 1,
								},
							},
							fcm_options: {
								image: fcmPayload.senderAvatar,
							},
						},
						webpush: {
							headers: {
								image: fcmPayload.senderAvatar,
							},
						},
						data: {
							imageUrl: fcmPayload.senderAvatar,
							body: fcmPayload.message,
						},
					};

					fetch("https://fcm.googleapis.com/fcm/send", {
						method: "POST",
						body: JSON.stringify(toSend),
						headers: {
							"Content-Type": "application/json",
							Authorization:
								"Bearer " + process.env.VUE_APP_FIREBASE_MESSAGING_TOKEN,
						},
					})
						.then((res) => res.json())
						.catch((error) => console.error("Error:", error))
						.then((response) => console.log("Success", response));
				}
			});
		}
	},

	// Reset
	async [ActionTypes.reset]({ commit }, payload) {
		const docRef = firebase.firestore().collection("calls");
		await docRef
			.doc(payload)
			.delete()
			.then(() => {
				commit(MutationTypes.resetCallStore, payload);
			});
	},
};
