
import {
	IonContent,
	IonHeader,
	IonPage,
	IonRow,
	IonCol,
	IonGrid,
	IonIcon,
	modalController,
	isPlatform,
	loadingController,
	alertController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
	sync,
	lockClosedOutline,
	accessibilityOutline,
	heartOutline,
	heart,
	watchOutline,
	bluetoothOutline,
	searchOutline,
	openOutline,
	helpBuoyOutline,
	logOutOutline,
	newspaperOutline,
	calendarOutline,
	ellipse,
	ellipseOutline,
	checkmarkCircle,
} from "ionicons/icons";
import Navbar from "@/components/Navbar.vue";
import Bottombar from "@/components/Bottombar.vue";
import ResetModal from "./modals/modalResetPassword.vue";
import SubscriptionModal from "./modals/modalSubscription.vue";
// import AboutUSModal from "./modals/modalAboutUs.vue";
// import SupportModal from "./modals/modalSupport.vue";
import { store } from "../store";
import { ActionTypes as Auth } from "../store/modules/auth";
import { ActionTypes as HealthActions } from "../store/modules/health";
import { ActionTypes as Schedule } from "../store/modules/schedule";
import modalSearch from "./modals/modalSearch.vue";
import { useRouter } from "vue-router";
import localStore from "../utils/localstore";
import { Health } from "@ionic-native/health";
import { resetStore } from "@/utils/utils";
import { auth } from "@/firebase";
import firebase from "firebase/app";

export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		Bottombar,
		IonRow,
		IonCol,
		IonGrid,
		IonIcon,
	},
	data() {
		return {
			health: Health,
			healthConnected: false,
			height: "",
			weight: "",
			gender: "",
			dob: "",
			syncingCalendar: false,
			calendarSyncEnabled: false,
		};
	},
	setup() {
		const router = useRouter();
		const buttons = ["Ok"];
		const isAndroid = isPlatform("android");
		const isIOS = isPlatform("ios");

		return {
			sync,
			lockClosedOutline,
			accessibilityOutline,
			heartOutline,
			heart,
			watchOutline,
			bluetoothOutline,
			searchOutline,
			openOutline,
			helpBuoyOutline,
			logOutOutline,
			router,
			buttons,
			isAndroid,
			isIOS,
			newspaperOutline,
			calendarOutline,
			ellipse,
			ellipseOutline,
			checkmarkCircle,
		};
	},
	computed: {
		isWeb() {
			return store.getters.isWeb;
		},
	},
	async ionViewWillEnter() {
		await store.dispatch(HealthActions.checkPermissions);
		this.healthConnected = store.getters.healthAppConnected;
		this.calendarSyncEnabled = store.getters.calendarSyncEnabled;
	},
	methods: {
		async findMembers() {
			const modal = await modalController.create({
				component: modalSearch,
			});
			return modal.present();
		},
		async openResetModal() {
			// Check Account Provider
			const authProvider =
				firebase.auth().currentUser?.providerData[0]?.providerId;
			if (authProvider === "password") {
				const modal = await modalController.create({
					component: ResetModal,
					cssClass: "modal-custom",
					componentProps: {},
				});
				return modal.present();
			} else {
				const alert = await alertController.create({
					header: "Error",
					message: `Passwords are not provided for social accounts`,
					buttons: ["OK"],
				});
				return alert.present();
			}
		},
		async openSubscriptionModal() {
			const modal = await modalController.create({
				component: SubscriptionModal,
				cssClass: "modal-custom",
				componentProps: {},
			});
			return modal.present();
		},
		async logout() {
			const loading = await loadingController.create({
				message: "Loading...",
			});
			loading.present();
			await auth.signOut().then(async () => {
				await resetStore();
				loading.dismiss();
				this.$router.push("/");
				// window.location.href = "/";
			});
		},
		async connectHealthApp() {
			const unAuthorized = store.getters.unAuthorized;
			this.healthConnected = store.getters.healthAppConnected;

			// Connect to health app only if on mobile OS, not already connected and user has not denied permissions
			if (
				!this.healthConnected &&
				(this.isAndroid || this.isIOS) &&
				!unAuthorized
			) {
				const loading = await loadingController.create({
					message: "Connecting...",
				});
				loading.present();

				await store.dispatch(HealthActions.connectHealthApp);
				await store.dispatch(HealthActions.checkPermissions);
				this.healthConnected = store.getters.healthAppConnected;
				loading.dismiss();

			} else if (unAuthorized) {

				const loading = await loadingController.create({
					message: "Connecting...",
				});
				loading.present();

				await store.dispatch(HealthActions.connectHealthApp);
				await store.dispatch(HealthActions.checkPermissions);
				this.healthConnected = store.getters.healthAppConnected;

				loading.dismiss();

				// Alert user to authorize app
				if (!this.healthConnected)
					alert(
						"Health Data Permissions Denied!\n\nCannot request access again\n\nPlease allow access to health data in your privacy settings."
					);
			}
		},
		// async openSupportModal() {
		//   const modal = await modalController.create({
		//     component: SupportModal,
		//     cssClass: "modal-custom",
		//     componentProps: {},
		//   });
		//   return modal.present();
		// },
		// async openAboutUSModal() {
		//   const modal = await modalController.create({
		//     component: AboutUSModal,
		//     cssClass: "modal-custom",
		//     componentProps: {},
		//   });
		//   return modal.present();
		// },
		async syncCalendar() {
			if (this.calendarSyncEnabled) {
				await store.dispatch(Schedule.disableCalendarSync);
				this.calendarSyncEnabled = store.getters.calendarSyncEnabled;
				console.log("calendar sync enabled", this.calendarSyncEnabled);
				alert("Disabled syncing schedule with your calendar");
			} else {
				this.syncingCalendar = true;
				const res = await store.dispatch(Schedule.syncCalendar);

				if (res === true) {
					this.calendarSyncEnabled = store.getters.calendarSyncEnabled;
					console.log("calendar sync enabled", this.calendarSyncEnabled);
					alert("Enabled syncing with your calendar");
				} else {
					this.calendarSyncEnabled = store.getters.calendarSyncEnabled;
					console.log("calendar sync enabled", this.calendarSyncEnabled);
					alert("An error occurred!\n\n" + res);
				}

				this.syncingCalendar = false;
			}
		},
		isWebOrPWA() {
			return isPlatform("desktop") || isPlatform("mobileweb");
		},
	},
});
