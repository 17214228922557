/* eslint-disable @typescript-eslint/camelcase */
import { ActionTree } from "vuex";
import { MutationTypes } from "./mutations";
import { Actions } from "./interfaces";
import { State } from "./index";
import { RootState, store } from "@/store";
import * as fb from "../../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
// import { getDocs, collection } from "firebase/firestore";
import { generateRandomString } from "@/utils/utils";
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { isPlatform } from "@ionic/vue";

// Actions
export enum ActionTypes {
	getAllUsers = "GET_ALL_USERS",
	newMessage = "NEW_MESSAGE",
	sendMessage = "SEND_MESSAGE",
	updateReceiver = "UPDATE_RECEIVER",
	getMessages = "GET_MESSAGES",
	watchMessages = "WATCH_MESSAGES",
	// deleteMessage = "DELETE_MESSAGE",
	getChatRooms = "GET_CHATROOMS",
	searchChatRooms = "SEARCH_CHATROOMS",
	postLiveStreamComment = "POST_LIVESTREAM_COMMENT",
	watchLiveStreamData = "WATCH_LIVESTREAM_DATA",
	getAllCoaches = "GET_ALL_COACHES",
	getCoach = "GET_COACH",
	resetChatrooms = "RESET_CHATROOMS",
	resetMessages = "RESET_MESSAGES",
	listenForNotifications = "LISTEN_FOR_NOTIFICATIONS",
	reset = "RESET",
	sendNotification = "SEND_MESSAGE_NOTIFICATION",
}

export const actions: ActionTree<State, RootState> & Actions = {
	// GET ALL USERS
	async [ActionTypes.getAllUsers]({ commit }, payload) {
		return new Promise<void>((resolve, reject) => {
			const chatRooms: object[] = [];

			fb.db
				.collection("users")
				.get()
				.then((snapshot) => {
					snapshot.forEach((doc) => {
						const user = {
							id: doc.id,
							avatar: doc.data().avatar,
							name: doc.data().userName ? doc.data().userName : "user",
						};
						chatRooms.push(user);
					});
					commit(MutationTypes.setChatRooms, chatRooms);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	// SEND A MESSAGE
	[ActionTypes.sendMessage](
		{ commit },
		payload: {
			id: string;
			// roomId: string;
			senderId: string;
			receiverId: string;
			receiverAvatar: string;
			senderAvatar: string;
			name: string;
			message: string;
			time: firebase.firestore.Timestamp | string;
		}
	) {
		return new Promise<void>((resolve, reject) => {
			let prevMessages: any;
			const docRef = fb.db.collection("messages").doc(payload.senderId);
			// console.log(docRef)
			// console.log(payload)
			docRef.get().then((doc) => {
				// If doc exists, update messages
				// console.log("DOC", doc)
				if (doc.exists) {
					prevMessages = doc.data()?.messages;
					prevMessages.push(payload)

					return docRef.update({
						messages: prevMessages
					})
						.then(() => {
							console.log("message sent")
							resolve()
						})
						.catch((error) => {
							// The document probably doesn't exist.
							console.log("message sending error", error)
							reject(error)
						});
				} else {
					// doc does not exist, create a new message
					const messages = {
						messages: [payload]
					}

					fb.db.collection("messages").doc(payload.senderId).set(messages)
						.then(() => {
							console.log("new doc -> message sent")
							resolve();
						})
						.catch((error) => {
							console.log("new doc -> message sending error", error)
							reject(error);
						});
				}
			}).catch((error) => {
				console.log("get doc error", error)
				reject(error)
			});

		});
	},

	// UPDATE RECEIVER
	[ActionTypes.updateReceiver](
		{ commit },
		payload: {
			id: string;
			senderId: string;
			receiverId: string;
			receiverAvatar: string;
			senderAvatar: string;
			name: string;
			message: string;
			time: firebase.firestore.Timestamp | string;
		}
	) {
		return new Promise<void>((resolve, reject) => {

			let prevMessages: any;
			const docRef = fb.db.collection("messages").doc(payload.receiverId);

			docRef.get().then((doc) => {
				// If doc exists, update messages
				if (doc.exists) {
					prevMessages = doc.data()?.messages;
					prevMessages.push(payload)

					return docRef.update({
						messages: prevMessages
					})
						.then(() => {
							console.log("update receiver")
							resolve()
						})
						.catch((error) => {
							// The document probably doesn't exist.
							console.log("update receiver error", error)
							reject(error)
						});
				} else {
					// doc does not exist, create a new message
					const messages = {
						messages: [payload]
					}

					fb.db.collection("messages").doc(payload.receiverId).set(messages)
						.then(() => {
							console.log("new doc -> update receiver")
							resolve();
						})
						.catch((error) => {
							console.log("new doc -> update receiver error", error)
							reject(error);
						});
				}
			}).catch((error) => {
				console.log("update receiver doc ref error", error)
				reject(error)
			});

		});
	},

	// GET MESSAGES
	async [ActionTypes.getMessages](
		{ commit },
		payload: {
			senderId: string;
			receiverId: string;
		}
	) {
		return new Promise((resolve, reject) => {
			const docRef = fb.db.collection("messages").doc(payload.senderId);

			docRef
				.get()
				.then((doc) => {
					if (doc.exists) {
						const messages = doc.data()?.messages;
						const updatedMessages = [];

						for (let i = 0; i < messages.length; i++) {
							if (
								messages[i].receiverId == payload.receiverId ||
								messages[i].receiverId == payload.senderId
							)
								updatedMessages.push(messages[i]);
						}
						commit(MutationTypes.setMessages, updatedMessages);
						resolve();
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
						reject("No such document!");
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	// WATCH FOR MESSAGES
	async [ActionTypes.watchMessages]({ commit }, payload) {
		const docRef = fb.db.collection("messages").doc(payload.senderId);

		docRef.onSnapshot(async (doc) => {
			// Update for changes to messages
			if (doc.exists) {
				const messages = doc.data()?.messages;
				const updatedMessages = [];

				for (let i = 0; i < messages.length; i++) {
					if (
						messages[i].receiverId == payload.receiverId ||
						messages[i].senderId == payload.receiverId
					)
						updatedMessages.push(messages[i]);
				}
				commit(MutationTypes.setMessages, updatedMessages);
			} else {
				// doc.data() will be undefined in this case
				console.log("Document does not exist!");
				commit(MutationTypes.setMessages, []);
			}
		});
	},

	// GET CHATROOMS
	async [ActionTypes.getChatRooms]({ commit }, payload) {
		return new Promise((resolve, reject) => {
			fb.db
				.collection("chatrooms")
				.where("uid", "==", payload)
				.get()
				.then(async (querySnapshot) => {
					querySnapshot.forEach(async (doc) => {
						// doc.data() is never undefined for query doc snapshots
						const room = doc.data();
						// commit(MutationTypes.addChatRoom, room);
					});
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	// SEARCH CHATROOMS
	async [ActionTypes.searchChatRooms]({ commit }, payload) {
		return new Promise((resolve, reject) => {

			const searchValue = payload.toLowerCase();
			const chatRooms: object[] = [];

			if (searchValue !== "") {
				// Perform search query
				fb.users
					.where("userName", ">=", searchValue)
					.where("userName", "<=", searchValue + "z")
					.get()
					.then((snapshot) => {
						if (snapshot.docs.length < 1) {
							commit(MutationTypes.setChatRooms, []);
							resolve()
						} else {
							snapshot.forEach((doc) => {
								const user = {
									id: doc.id,
									avatar: doc.data().avatar,
									name: doc.data().userName ? doc.data().userName : "user",
								};
								chatRooms.push(user);
							});
							commit(MutationTypes.setChatRooms, chatRooms);
							resolve();
						}
					})
					.catch((error) => {
						console.log(error);
						reject(error)
					});
			} else {
				reject("Please enter a search keyword.");
			}
		})
	},

	// DELETE A MESSAGE
	// async [ActionTypes.deleteMessage]({ commit }, payload) {
	// 	commit(MutationTypes.deleteMessage, payload);
	// },

	// POST A LIVE STREAM COMMENT
	[ActionTypes.postLiveStreamComment](
		{ commit },
		payload: {
			liveStreamId: string;
			commentId: string;
			senderId: string;
			avatar: string;
			name: string;
			comment: string;
			time: firebase.firestore.Timestamp | string;
		}
	) {
		return new Promise<void>((resolve, reject) => {
			let prevComments: any;

			// Generate a livestream id if empty
			if (payload.liveStreamId == "")
				payload.liveStreamId = generateRandomString(20);

			const docRef = fb.db
				.collection("livestream-comments")
				.doc(payload.liveStreamId);

			docRef
				.get()
				.then((doc) => {
					// If doc exists, update messages
					if (doc.exists) {
						prevComments = doc.data()?.comments;
						prevComments.push(payload);

						return docRef
							.update({
								commentCount: prevComments.length,
								comments: prevComments,
							})
							.then(() => {
								commit(MutationTypes.setLiveStreamId, payload.liveStreamId);
								commit(MutationTypes.setLiveStreamComments, prevComments);
								commit(
									MutationTypes.setLiveStreamCommentCount,
									prevComments.length
								);
								commit(
									MutationTypes.setLiveStreamViewCount,
									doc.data()?.viewers
								);
								commit(MutationTypes.setLiveStreamLikeCount, doc.data()?.likes);

								resolve();
							})
							.catch((error) => {
								// The document probably doesn't exist.
								reject(error);
							});
					} else {
						// doc does not exist, create a new comment
						const newPayload = {
							liveStreamId: payload.liveStreamId,
							startTime: payload.time,
							viewers: 1,
							commentCount: 1,
							likes: 0,
							comments: [payload],
						};

						fb.db
							.collection("livestream-comments")
							.doc(payload.liveStreamId)
							.set(newPayload)
							.then(() => {
								commit(MutationTypes.setLiveStreamId, payload.liveStreamId);
								commit(
									MutationTypes.setLiveStreamComments,
									newPayload.comments
								);
								commit(
									MutationTypes.setLiveStreamCommentCount,
									newPayload.commentCount
								);
								commit(
									MutationTypes.setLiveStreamViewCount,
									newPayload.viewers
								);
								commit(MutationTypes.setLiveStreamLikeCount, newPayload.likes);

								resolve();
							})
							.catch((error) => {
								reject(error);
							});
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	// WATCH LIVE STREAM DATA CHANGES
	async [ActionTypes.watchLiveStreamData]({ commit }, payload) {
		const docRef = fb.db.collection("livestream-comments").doc(payload);

		docRef.onSnapshot(async (doc) => {
			// Update the live stream data
			if (doc.exists) {
				const comments = doc.data()?.comments;
				const commentCount = doc.data()?.commentCount;
				const viewers = doc.data()?.viewers;
				const likes = doc.data()?.likes;

				commit(MutationTypes.setLiveStreamComments, comments);
				commit(MutationTypes.setLiveStreamCommentCount, commentCount);
				commit(MutationTypes.setLiveStreamViewCount, viewers);
				commit(MutationTypes.setLiveStreamLikeCount, likes);
			}
		});
	},

	// GET ALL COACHES
	async [ActionTypes.getAllCoaches]({ commit }, payload) {

		return new Promise<void>((resolve, reject) => {

			const chatRooms: object[] = [];

			fb.db.collection("trainers")
				.get()
				.then(snapshot => {
					snapshot.forEach(doc => {
						const coach = {
							id: doc.id,
							avatar: doc.data().imgURL,
							name: doc.data().name ? doc.data().name : "coach",
						}
						chatRooms.push(coach)
					});
					commit(MutationTypes.setChatRooms, chatRooms)
					resolve();
				})
				.catch((error) => {
					reject(error)
				});
		});
	},

	// GET COACH
	async [ActionTypes.getCoach]({ commit }, payload) {

		return new Promise<void>((resolve, reject) => {

			const chatRooms: object[] = [];

			fb.db.collection("trainers")
				.get()
				.then(snapshot => {
					snapshot.forEach(doc => {

						if (doc.id == payload) {
							const coach = {
								id: doc.id,
								avatar: doc.data().imgURL,
								name: doc.data().name ? doc.data().name : "coach",
							}
							chatRooms.push(coach)
						}
					});
					commit(MutationTypes.setChatRooms, chatRooms)
					resolve();
				})
				.catch((error) => {
					reject(error)
				});
		});
	},

	// RESET CHATROOMS
	async [ActionTypes.resetChatrooms]({ commit }) {
		commit(MutationTypes.resetChatrooms);
	},

	// RESET MESSAGES
	async [ActionTypes.resetMessages]({ commit }) {
		commit(MutationTypes.resetMessages);
	},

	// Listen for notifications on chat
	async [ActionTypes.listenForNotifications]({ commit }) {
		console.log("listening for notifications")

		// Request permission to use push notifications
		// IOS will prompt user and return if they granted permission or not
		// Android will just grant without prompting
		PushNotifications.requestPermissions()
			.then(result => {
				if (result.receive === 'granted') {
					// Register with Apple or Google to receive push via APNS/FCM
					console.log('registered for push notifications')
					PushNotifications.register()
				} else {
					// Show some error
					console.log('An error occurred requesting push notifications permissions')
				}
			})

		// On success, we should be able to receive notifications
		PushNotifications.addListener('registration',
			(token: Token) => {
				console.log('Push registration success, token:' + token.value);
			})

		// Show us the notification payload if the app is open on our device
		PushNotifications.addListener('pushNotificationReceived',
			(notification: PushNotificationSchema) => {
				console.log('Push received: ' + JSON.stringify(notification));
			}
		);

		// Some issue with our setup and push will not work
		PushNotifications.addListener('pushNotificationActionPerformed',
			(notification: ActionPerformed) => {
				console.log('Push action performed:' + JSON.stringify(notification))
			})
	},

	// RESET
	async [ActionTypes.reset]({ commit }) {
		commit(MutationTypes.reset);
	},

	// SEND NOTIFICATIONS
	async [ActionTypes.sendNotification](
		{ commit },
		payload: {
			senderId: string;
			receiverId: string;
			receiverAvatar: string;
			senderAvatar: string;
			name: string;
			message: string;
			time: firebase.firestore.Timestamp | string;
		}
	) {
		// get the target users tokens
		const receiverTokens = await fb.users
			.doc(payload.receiverId)
			.collection("fcmTokens")
			.get();

		if (receiverTokens) {
			receiverTokens.forEach((doc: any) => {
				const token = doc.data().token;
				if (token) {

					console.log(token)

					const toSend = {
						to: token,
						notification: {
							title: "New message from " + store.getters.userProfile.userName,
							body: payload.message,
							mutable_content: true,
							icon: payload.senderAvatar,
							image: payload.senderAvatar
						},
						android: {
							notification: {
								image: payload.senderAvatar,
							},
						},
						apns: {
							payload: {
								aps: {
									"mutable-content": 1,
								},
							},
							fcm_options: {
								image: payload.senderAvatar,
							},
						},
						webpush: {
							headers: {
								image: payload.senderAvatar,
							},
						},
						data: {
							imageUrl: payload.senderAvatar,
							body: payload.message,
						},
					};

					const iosPayload = {
						"message": {
							"token": token,
							"data": {
								"title": "New message from " + store.getters.userProfile.userName,
								"body": payload.message,
								// "image": payload.senderAvatar
							},
							"apns": {
								"headers": {
									"apns-expiration": "1604750400"
								}
							},
							"android": {
								"ttl": "4500s"
							},
							"webpush": {
								"headers": {
									"TTL": "4500"
								}
							}
						}
					}

					const payloadSent = isPlatform("ios") ? iosPayload : toSend

					// console.log("SENDING PUSH")
					fetch("https://fcm.googleapis.com/fcm/send", {
						method: "POST",
						body: JSON.stringify(payloadSent),
						headers: {
							"Content-Type": "application/json",
							Authorization:
								"Bearer " + process.env.VUE_APP_FIREBASE_MESSAGING_TOKEN,
						},
					})
						.then((res) => console.log(res.json()))
						.catch((error) => console.error("Error:", error))
						.then((response) => console.log("Success:", response));
				}
			});
		}
	},
};
