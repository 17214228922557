<template>
	<div>
		<ion-header class="ion-no-border border-bottom">
			<ion-toolbar color="light">
				<ion-title class="text-lg">Manage Subscription</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="close()">X</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content class="ion-padding text-center" style="height:45vh">
			Your current Subscription is valid until: <br><br> {{ subscriptionDate }}
			<br>
			<br>
			<ion-button fill="outline" color="success" size="block" @click="manageSubscription()">Manage your Subscription</ion-button>

		</ion-content>
	</div>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonButton,
} from "@ionic/vue";
import { useRouter } from "vue-router";

import { defineComponent } from "vue";
import { modalController, isPlatform } from "@ionic/vue";
import { ActionTypes as Auth } from "../../store/modules/auth";
import { store } from "@/store";

export default defineComponent({
	name: "Modal",

	setup() {
		const subscribeLink = `${process.env.VUE_APP_CRM_SUBSCRIBE}${store.getters.userProfile.uid}`;
		const router = useRouter();
		// const store = useStore();
		return {
			subscribeLink,router
		};
	},
	data() {
		return {
			isSubscribed: false,
			subscriptionDate: "",
			duration: 0,
			userProfile: "",
		};
	},
	components: {
		IonContent,
		IonHeader,
		IonTitle,
		IonToolbar,
		IonButton,
		// IonButtons,
		// IonLabel,
	},
	async beforeMount() {
		
		this.userProfile = await store.getters.userProfile;
		console.log(store.getters.userProfile.subscription);
		this.subscriptionDate = new Date(
			this.userProfile.subscription.date.seconds * 1000
		);
		
		this.subscriptionDate = this.subscriptionDate.toLocaleDateString()
		this.isSubscribed = await store.getters.userProfile.subscription
			.isSubscribed;
		this.duration = await store.getters.userProfile.subscription.duration;
	},
	methods: {
		async close() {
			await modalController.dismiss();
		},
		manageSubscription() {
			if(isPlatform('ios') || isPlatform('android')){
				this.router.push('/iap')
				modalController.dismiss();
			} else {
				window.open(this.subscribeLink, '_self')
			}
		}
	},
});
</script>
