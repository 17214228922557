
import {
	IonContent,
	IonHeader,
	IonPage,
	IonRow,
	IonCol,
	IonGrid,
	IonCard,
	IonLabel,
	loadingController,
	IonSpinner,
} from "@ionic/vue";

import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { store } from "../store";
import { ActionTypes as Trainer } from "../store/modules/trainers";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import Navbar from "../components/Navbar.vue";
import Bottombar from "../components/Bottombar.vue";
export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		Bottombar,
		IonRow,
		IonCol,
		IonGrid,
		IonCard,
		IonLabel,
		IonSpinner,
	},

	data() {
		return {
			trainerLoading: false,
		};
	},

	async onMounted() {
		if(store.getters.trainers.length === 0){
			console.log('no trainers')
			await store.dispatch(Trainer.fetchTrainers);
		}
		
	},
	computed: {
		trainers() {
			const trainers = store.getters.trainers;
			return trainers;
		},

		client() {
			return store.getters.client;
		},
	},

	setup() {
		const router = useRouter();

		return {
			router,
		};
	},
	methods: {
		openTrainerVideos(trainer: firebase.firestore.DocumentData) {
			// console.log("ABOUT TO DISPATCH TRAINER");
			// console.log(trainer);
			store.dispatch(Trainer.selectCurrentTrainer, trainer);

			this.$router.push({
				path: `/trainervideos/${trainer.id}`,
			});
		},
	},
});
