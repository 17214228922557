import { store } from "@/store";
import dayjs from "dayjs";
import moment from "moment";
import localStore from "./localstore";

import { ActionTypes as Auth } from "../store/modules/auth";
import { ActionTypes as Chat } from "../store/modules/chat";
import { ActionTypes as Content } from "../store/modules/content";
// import { ActionTypes as Global } from "../store/modules/global";
import { ActionTypes as Health } from "../store/modules/health";
// import { ActionTypes as Programs } from "../store/modules/programs";
import { ActionTypes as Schedule } from "../store/modules/schedule";
import { ActionTypes as Social } from "../store/modules/social";
import { ActionTypes as Trainers } from "../store/modules/trainers";
import { ActionTypes as VideoPlayer } from "../store/modules/video_player";
import { ActionTypes as LeaderBoard } from "../store/modules/leaderboard";
import { ActionTypes as Coins } from "../store/modules/coins";
import { ActionTypes as Calls } from "../store/modules/call";
import { ActionTypes as Bookings } from "../store/modules/bookings";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { FCM } from "@capacitor-community/fcm";
import { Calendar, CalendarOptions, NameOrOptions } from "@ionic-native/calendar"

export const googleAuthPlugin = GoogleAuth;

export const toDateTime = (secs: any) => {
	const t: Date = new Date(1970, 0, 1);
	t.setSeconds(secs);
	return dayjs(t).format("YYYY/MM/DD");
};

export const getBlobfromImg = async (src: any) => {
	const data = await fetch(src);
	return data.blob();
};

export const classSortDesc = (firstClass: any, secondClass: any) => {
	const firstDate = moment(firstClass.date.seconds * 1000);
	const secondDate = moment(secondClass.date.seconds * 1000);
	const firstAfterSec = firstDate.isAfter(secondDate);
	const secAfterFirst = secondDate.isAfter(firstDate);
	if (firstAfterSec) {
		return -1;
	} else if (secAfterFirst) {
		return 1;
	} else {
		return 0;
	}
};

// Reset entire store to initial values, clear local storage and navigate to Home page
export const resetStore = async () => {
	const googleUser = await localStore.get('googleAccount');

	localStore.clear();

	await store.dispatch(Auth.reset);
	await store.dispatch(Chat.reset);
	await store.dispatch(Content.reset);
	await store.dispatch(Health.reset);
	await store.dispatch(Schedule.reset);
	await store.dispatch(Social.reset);
	await store.dispatch(VideoPlayer.reset);
	await store.dispatch(Trainers.reset);
	await store.dispatch(LeaderBoard.reset);
	await store.dispatch(Coins.reset);
	await store.dispatch(Calls.reset);
	await store.dispatch(Bookings.reset);
	// store.dispatch(Global.reset);
	// store.dispatch(Programs.reset);

	// googleAuthPlugin.signOut()

	localStore.create()
	await localStore.set('googleAccount', googleUser);
	await FCM.getToken()
		.then((r) => {
			// alert(`Token ${r.token}`);
			const token = r.token;
			// console.log("WOO", r.token);
			localStore.set("fcmToken", token);
			FCM.setAutoInit({ enabled: true });
		})
		.catch((err) => console.log(err));
	console.log('LOCAL STORAGE', await localStore.keys())
};

// Generate a random alphanumeric string of a specified length
export const generateRandomString = (length: number) => {
	let result = '';
	const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charsLength = chars.length;
	for (let i = 0; i < length; i++) {
		result += chars.charAt(Math.floor(Math.random() * charsLength));
	}
	return result;
}

// Sync schedule with external calendar (IOS or android only)
export const syncSchedule = async (calOptions: NameOrOptions | CalendarOptions | undefined) => {
	console.log("calendar sync options", calOptions)

	const userSchedule = store.getters.getUserSchedule
	console.log("calendar - user schedule", userSchedule[0])
	let error = false
	let errorMsg = null

	userSchedule.forEach((el: any) => {
		console.log("calendar schedule", el.className)

		const _date = moment(el.dateMoment).toDate()

		const title = el.className
		const location = ""
		const notes = "Workout"
		const startDate = _date
		const endDate = startDate
		endDate.setHours(23, 59, 59, 0)

		Calendar.findEventWithOptions(title, location, notes, startDate, endDate, calOptions)
			.then((events) => {
				console.log("find event response", events)

				if (events.length < 1) {
					Calendar.createEventWithOptions(title, location, notes, startDate, endDate, calOptions)
						.then((res) => {
							console.log("calendar create event response", res)
						},
							(err) => {
								error = true
								errorMsg = err
								console.log("calendar create event error", err)
							})
				}
			},
				(err) => {
					errorMsg = err
					console.log("calendar sync error", err)
				})
	})

	if (error) return errorMsg
	else return true
}

// Remove scheduled item from external calendar (IOS or android)
export const removeCalendarItem = async (calOptions: NameOrOptions | CalendarOptions | undefined) => {
	console.log("remove calendar item", calOptions)
}

export const activityMap = {
	aerobics: "aerobics",
	archery: "archery",
	badminton: "badminton",
	barre: "barre",
	baseball: "baseball",
	basketball: "basketball",
	biathlon: "biathlon",
	biking: "biking",
	biking_hand: "biking.hand",
	biking_mountain: "biking.mountain",
	biking_road: "biking.road",
	biking_spinning: "biking_spinning",
	biking_stationary: "biking_stationary",
	biking_utility: "biking.utility",
	bowling: "bowling",
	boxing: "boxing",
	calisthenics: "calisthenics",
	circuit_training: "circuit_training",
	cricket: "cricket",
	cooldown: "cooldown",
	core_training: "core_training",
	crossfit: "crossfit",
	curling: "curling",
	dancing: "dancing",
	dancing_social: "dancing.social",
	disc_sports: "disc_sports",
	diving: "diving",
	elevator: "elevator",
	elliptical: "elliptical",
	ergometer: "ergometer",
	escalator: "escalator",
	fencing: "fencing",
	fitness_gaming: "fitness_gaming",
	fishing: "fishing",
	flexibility: "flexibility",
	football_american: "football_american",
	football_australian: "football_australian",
	football_soccer: "football.soccer",
	frisbee_disc: "frisbee_disc",
	functional_strength: "functional_strength",
	gardening: "gardening",
	golf: "golf",
	gymnastics: "gymnastics",
	handball: "handball",
	interval_training_high_intensity: "interval_training.high_intensity",
	hiking: "hiking",
	hockey: "hockey",
	horseback_riding: "horseback_riding",
	housework: "housework",
	hunting: "hunting",
	ice_skating: "ice_skating",
	in_vehicle: "in_vehicle",
	interval_training: "interval_training",
	jump_rope: "jump_rope",
	kayaking: "kayaking",
	kettlebell_training: "kettlebell_training",
	kick_scooter: "kick_scooter",
	kickboxing: "kickboxing",
	kitesurfing: "kitesurfing",
	lacrosse: "lacrosse",
	martial_arts: "martial_arts",
	meditation: "meditation",
	martial_arts_mixed: "martial_arts.mixed",
	on_foot: "on_foot",
	mixed_metabolic_cardio: "mixed_metabolic_cardio",
	other: "other",
	p90x: "p90x",
	paddle_sports: "paddle_sports",
	paragliding: "paragliding",
	pilates: "pilates",
	play: "play",
	pickleball: "pickleball",
	polo: "polo",
	preparation_and_recovery: "preparation_and_recovery",
	racquetball: "racquetball",
	rock_climbing: "rock_climbing",
	rowing: "rowing",
	rowing_machine: "rowing.machine",
	rugby: "rugby",
	running: "running",
	running_jogging: "running.jogging",
	running_sand: "running.sand",
	running_treadmill: "running.treadmill",
	sailing: "sailing",
	scuba_diving: "scuba_diving",
	skateboarding: "skateboarding",
	skating: "skating",
	skating_cross: "skating.cross",
	skating_indoor: "skating.indoor",
	skating_inline: "skating.inline",
	skiing: "skiing",
	skiing_back_country: "skiing.back_country",
	skiing_cross_country: "skiing.cross_country",
	skiing_downhill: "skiing.downhill",
	skiing_kite: "skiing.kite",
	skiing_roller: "skiing.roller",
	sledding: "sledding",
	sleep: "sleep",
	sleep_light: "sleep.light",
	sleep_deep: "sleep.deep",
	sleep_rem: "sleep.rem",
	sleep_inBed: "sleep.inBed",
	sleep_awake: "sleep.awake",
	sleep_outOfBed: "sleep.outOfBed",
	snowboarding: "snowboarding",
	snowmobile: "snowmobile",
	snowshoeing: "snowshoeing",
	snow_sports: "snow_sports",
	softball: "softball",
	squash: "squash",
	stairs: "stairs",
	stair_climbing: "stair_climbing",
	stair_climbing_machine: "stair_climbing.machine",
	standup_paddleboarding: "standup_paddleboarding",
	still: "still",
	strength_training: "strength_training",
	surfing: "surfing",
	swimming: "swimming",
	swimming_pool: "swimming.pool",
	swimming_open_water: "swimming.open_water",
	table_tennis: "table_tennis",
	tai_chi: "tai_chi",
	team_sports: "team_sports",
	tennis: "tennis",
	tilting: "tilting",
	track_and_field: "track_and_field",
	treadmill: "treadmill",
	unknown: "unknown",
	volleyball: "volleyball",
	volleyball_beach: "volleyball.beach",
	volleyball_indoor: "volleyball.indoor",
	wakeboarding: "wakeboarding",
	walking: "walking",
	walking_fitness: "walking.fitness",
	walking_nordic: "walking.nordic",
	walking_treadmill: "walking.treadmill",
	walking_stroller: "walking.stroller",
	water_fitness: "water_fitness",
	water_polo: "water_polo",
	water_sports: "water_sports",
	weightlifting: "weightlifting",
	wheelchair: "wheelchair",
	wheelchair_walkpace: "wheelchair.walkpace",
	wheelchair_runpace: "wheelchair.runpace",
	windsurfing: "windsurfing",
	wrestling: "wrestling",
	yoga: "yoga",
	zumba: "zumba"
}

export const getActivity = (activity: "aerobics" | "archery" | "badminton" | "barre" | "baseball" | "basketball" | "biathlon" | "biking" | "biking_hand" | "biking_mountain" | "biking_road" | "biking_spinning" | "biking_stationary" | "biking_utility" | "bowling" | "boxing" | "calisthenics" | "circuit_training" | "cricket" | "cooldown" | "core_training" | "crossfit" | "curling" | "dancing" | "dancing_social" | "disc_sports" | "diving" | "elevator" | "elliptical" | "ergometer" | "escalator" | "fencing" | "fitness_gaming" | "fishing" | "flexibility" | "football_american" | "football_australian" | "football_soccer" | "frisbee_disc" | "functional_strength"
	| "gardening" | "golf" | "gymnastics" | "handball" | "interval_training_high_intensity" | "hiking" | "hockey" | "horseback_riding" | "housework"
	| "hunting" | "ice_skating" | "in_vehicle" | "interval_training" | "jump_rope" | "kayaking" | "kettlebell_training" | "kick_scooter" | "kickboxing" | "kitesurfing" | "lacrosse" | "martial_arts" | "meditation" | "martial_arts_mixed" | "on_foot" | "mixed_metabolic_cardio" | "other" | "p90x" | "paddle_sports" | "paragliding" | "pilates" | "play" | "pickleball" | "polo" | "preparation_and_recovery" | "racquetball" | "rock_climbing" | "rowing"
	| "rowing_machine" | "rugby" | "running" | "running_jogging" | "running_sand" | "running_treadmill" | "sailing" | "scuba_diving" | "skateboarding" | "skating" | "skating_cross" | "skating_indoor" | "skating_inline" | "skiing" | "skiing_back_country" | "skiing_cross_country" | "skiing_downhill" | "skiing_kite"
	| "skiing_roller" | "sledding" | "sleep" | "sleep_light" | "sleep_deep" | "sleep_rem" | "sleep_inBed" | "sleep_awake" | "sleep_outOfBed" | "snowboarding"
	| "snowmobile" | "snowshoeing" | "snow_sports" | "softball" | "squash" | "stairs" | "stair_climbing" | "stair_climbing_machine" | "standup_paddleboarding"
	| "still" | "strength_training" | "surfing" | "swimming" | "swimming_pool" | "swimming_open_water" | "table_tennis" | "tai_chi" | "team_sports" | "tennis" | "tilting" | "track_and_field" | "treadmill" | "unknown" | "volleyball" | "volleyball_beach" | "volleyball_indoor" | "wakeboarding" | "walking" | "walking_fitness" | "walking_nordic" | "walking_treadmill" | "walking_stroller" | "water_fitness" | "water_polo" | "water_sports" | "weightlifting" | "wheelchair"
	| "wheelchair_walkpace" | "wheelchair_runpace" | "windsurfing" | "wrestling" | "zumba" | "yoga") => {
	return activityMap[activity];
}

export const getCategory = (category: string) => {
	switch (category) {
		case "BARRE":
			return getActivity("barre")
		case "BOOTY BURN":
			return getActivity("pilates")
		case "CYCLE":
			return getActivity("biking_stationary")
		case "MIRROR - HIIT":
			return getActivity("interval_training_high_intensity")
		case "CORE CRUSHER":
			return getActivity("weightlifting")
		default:
			return null;
	}
}