import { MutationTree } from "vuex";
import { State } from "./interfaces";
import { Mutations } from "./types";


export enum MutationTypes {
	setChatRooms = "SET_CHATROOMS",
	setMessages = "SET_MESSAGES",
	addMessage = "ADD_MESSAGE",
	deleteMessage = "DELETE_MESSAGE",
	setLiveStreamId = "SET_LIVESTREAM_ID",
	setLiveStreamComments = "SET_LIVESTREAM_COMMENTS",
	setLiveStreamCommentCount = "SET_LIVESTREAM_COMMENT_COUNT",
	setLiveStreamViewCount = "SET_LIVESTREAM_VIEW_COUNT",
	setLiveStreamLikeCount = "SET_LIVESTREAM_LIKE_COUNT",
	resetChatrooms = "RESET_CHATROOMS",
	resetMessages = "RESET_MESSAGES",
	reset = "RESET"
}

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.setChatRooms](state, payload) {
		state.chatRooms = payload;
	},
	[MutationTypes.setMessages](state, payload) {
		state.messages = payload;
	},
	[MutationTypes.addMessage](state, payload) {
		state.messages.push(payload);
	},
	// [MutationTypes.deleteMessage](state, payload) {
	// 	const filteredMessages = state.messages.filter(msg => msg.id !== payload)
	// 	state.messages = filteredMessages;
	// },
	[MutationTypes.setLiveStreamId](state, payload) {
		state.liveStreamId = payload;
	},
	[MutationTypes.setLiveStreamComments](state, payload) {
		state.liveStreamComments = payload;
	},
	[MutationTypes.setLiveStreamCommentCount](state, payload) {
		state.liveStreamCommentCount = payload;
	},
	[MutationTypes.setLiveStreamViewCount](state, payload) {
		state.liveStreamViewCount = payload;
	},
	[MutationTypes.setLiveStreamLikeCount](state, payload) {
		state.liveStreamLikeCount = payload;
	},
	[MutationTypes.resetChatrooms](state) {
		state.chatRooms = [];
	},
	[MutationTypes.resetMessages](state) {
		state.messages = [];
	},
	[MutationTypes.reset](state) {
		state.chatRooms = [];
		state.messages = [];
		state.liveStreamComments = [];
		state.liveStreamCommentCount = 0;
		state.liveStreamViewCount = 0;
		state.liveStreamLikeCount = 0;
	},
};
