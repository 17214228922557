import { InAppPurchase2, IAPProduct } from "@ionic-native/in-app-purchase-2";
import { isPlatform } from "@ionic/core";

const iap = InAppPurchase2;

const products: IAPProduct[] = [];

let storeNameCheck = "";
if (isPlatform("ios")) {
	storeNameCheck = "Apple";
}
if (isPlatform("android")) {
	storeNameCheck = "Google";
}

export const storeName = storeNameCheck;

export const coinOptions = [
    {
        id: 'coin001',
        type: iap.CONSUMABLE
    },
    {
        id: 'coin002',
        type: iap.CONSUMABLE
    },
    {
        id: 'coin003',
        type: iap.CONSUMABLE
    },
    {
        id: 'coin004',
        type: iap.CONSUMABLE
    }
]

export const subOptions = [
    { id: "sub002", type: iap.PAID_SUBSCRIPTION },
    { id: "sub003", type: iap.PAID_SUBSCRIPTION }
]

export const iapSetup = (iap: any) =>{
    iap.verbosity = iap.DEBUG;
	console.log("Loading IAP and Registering Products");
    const options = [...subOptions, ...coinOptions]
	
    for (const option of options){
        if (!iap.get(option.id)){
            iap.register(option)
        }
    }

	iap.validator =
		"https://validator.fovea.cc/v1/validate?appName=fit.platform.classes&apiKey=4b91ccbd-14e7-4ce9-8e28-310ba4c62756";

	console.log("refreshing store status");
	iap.refresh();
	iap.error(function (error: any) {
		console.log("storeError");
		console.log(error);
	});

    return iap
}

export default iap;