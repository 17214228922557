import { MutationTree } from "vuex";
import { State } from "./index";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { Mutations } from "./types";

export enum MutationTypes {
	setUserSchedule = "SET_USER_SCHEDULE",
	setCalendarSyncEnabled = "SET_CALENDAR_SYNC_ENABLED",
	reset = "RESET",
}

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.setUserSchedule](state, payload) {
		state.userSchedule = payload;
	},
	[MutationTypes.setCalendarSyncEnabled](state, payload) {
		state.calendarSyncEnabled = payload;
	},
	[MutationTypes.reset](state) {
		state.userSchedule = [];
		state.calendarSyncEnabled = false;
	},
};
