<template>
	<div style="height: 100vh">
		<ion-header class="ion-no-border border-bottom">
			<ion-toolbar color="light">
				<ion-title class="text-lg" >Buy Coins</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="close()">X</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content color="light" class="ion-padding text-center" v-if="!loading">
                <ion-list >
                    <ion-radio-group>
                        <ion-item v-for="option in options" :key="option.amount" class = 'coin-radio'>
                            <!-- <ion-label> </ion-label> -->
							<div class = 'spacing'></div>
                            <ion-img src="assets/icons/coin.png" class="coin-icon"></ion-img>
							<div class = 'spacing'></div>
                            <ion-label class = "coin-name"> {{option.amount}} FitCoins</ion-label>
							<div class = 'spacing'></div>
                            <ion-label class="coin-cost"> ${{option.price}} </ion-label>
                            <ion-radio 
                                @click="()=> choice = option"
                                :value="option.amount"
								mode='md'
                            >
                            </ion-radio>
							<div class = 'spacing'></div>
                        </ion-item>
                    
                    </ion-radio-group>
                </ion-list>
			<br/>
			<ion-button color="success" @click="accept()">Confirm Transaction</ion-button>
			<div class="spacer"></div>
		</ion-content>
		<ion-content color="light" v-if="loading">
			<div class="spin">
				<ion-spinner name="crescent"></ion-spinner>
			</div>
		</ion-content>
	</div>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonButton,
	IonButtons,
    IonRadio,
    IonRadioGroup,
	IonSpinner
} from "@ionic/vue";
import { defineComponent } from "vue";
import { modalController, isPlatform , IonImg} from "@ionic/vue";
// import { DatePicker } from "v-calendar";
import {
	star,
	starOutline,
	starHalf,
	thumbsDownOutline,
	thumbsUpOutline,
	trophyOutline,
	timerOutline,
	flameOutline,
	heartOutline,
} from "ionicons/icons";
import { ActionTypes as Coins } from "../../store/modules/coins";
import { store } from "@/store";
import { useRouter } from "vue-router";
import { openToastOptionsPayment } from "../../utils/toast";
// import iap from '../../utils/iap'
import {IAPProduct} from "@ionic-native/in-app-purchase-2";

const options = [
            {
                amount: 300,
                price: 9.99,
				id: 'coins001'
            },
            {
                amount: 600,
                price: 17.99,
				id: 'coins002'
            },
            {
                amount: 1500,
                price: 39.99,
				id: 'coins003'
            },
            {
                amount: 3000,
                price: 69.99, 
				id: 'coins004'
            }
        ]

export default defineComponent({
	name: "CoinPurchaseModal",
	setup() {
		// const store = useStore();
		const router = useRouter();
        

		return {
			star,
			starOutline,
			starHalf,
			thumbsDownOutline,
			thumbsUpOutline,
			trophyOutline,
			timerOutline,
			flameOutline,
			heartOutline,
		};
	},
	data() {
		return {
			choice: {}, 
            options: options,
			loading: false
		};
	},
	computed: {
		client() {
			return store.getters.client;
		},
		userProfile(){
			return store.getters.userProfile;
		}
        
	},

	components: {
		IonContent,
		IonHeader,
		IonTitle,
		IonToolbar,
		IonButton,
		IonButtons,
        IonRadio,
        IonRadioGroup,
        IonImg,
		IonSpinner,
		// DatePicker

	},

	methods: {
        async accept(){
            
            if (!this.choice.amount || this.choice.amount <= 0){
                console.log("exiting")
                return;
            }

            const payload = {
                amount: this.choice.amount,
            }

			this.loading = true
			// Make DB Transaction
            const success = await store.dispatch(Coins.addCoins, {amount: this.choice.amount})
				.then( async ({header, message, success}) =>{
                    console.log('message', message)
					this.loading = false;
					await openToastOptionsPayment(header, message);
				})

            await modalController.dismiss();

			if (success){
				this.onConfirmation();
			}
        },
		async close() {
			await modalController.dismiss();
		},
	},
});
</script>
<style scoped>
.spacer {
	height: 150px;
}
.coin-icon{
    height: 8vh;
    padding-right: 3vw;
    padding-top: 1vh;
    padding-bottom: 1.5vh;
}
.coin-radio{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.spin{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100%;
}

@media (max-device-width: 480px) and (min-device-width: 381px) {
	.coin-icon{
		width: 10vw;
	}
	.spacing{
		width: 5%
	}
	.coin-radio{
		justify-content: space-evenly;
	}
	.coin-cost{
		font-size: 3.45vw;
	}
	.coin-name{
		font-size: 3vw;
	}
}

@media (max-device-width: 380px) and (min-device-width: 320px){
	.coin-icon{
		width: 11vw;
	}
	.spacing{
		width: 5%
	}
	.coin-radio{
		justify-content: space-evenly;
	}
	.coin-cost{
		font-size: 3.35vw;
	}
	.coin-name{
		font-size: 2.9vw;
	}
}

@media (max-device-width: 319px){
	.coin-icon{
		width: 12vw;
	}
	.spacing{
		width: 4%
	}
	.coin-radio{
		justify-content: space-evenly;
	}
	.coin-cost{
		font-size: 3.35vw;
	}
	.coin-name{
		font-size: 2.9vw;
	}
}

</style>
