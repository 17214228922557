<template>
	<div style="height: 100vh">
		<ion-header class="ion-no-border border-bottom">
			<ion-toolbar color="light">
				<ion-title color="primary" class="text-md">{{ title }}</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="close()">X</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content color="light" class="ion-padding text-center" v-if="!loading">
			<ion-grid>
				<ion-row v-if="!scheduling">
					<ion-col>
						<img :src="videoimage" style="height: 20vh" />
						<br />
						<ion-label
							color="primary"
							v-if="eventDate.seconds"
							class="text-sm"
							>{{ eventDate }}</ion-label
						><br />
						<ion-label class="text-md"
							><strong>{{ eventname }}</strong></ion-label
						><br />
						<ion-label color="primary" class="text-sm"
							><strong>{{ trainer }}</strong></ion-label
						>
						<div class="text-md video-desc" v-html="content"></div>
						<p v-if="diff != undefined && diff?.asSeconds() > 0">
							{{ diff.days() }} days, {{ diff.hours() }} hours,
							{{ diff.minutes() }} minutes, {{ diff.seconds() }} seconds,
						</p>

						<br />
						<div>
							<ion-button
								@click="openOutlook()"
								class="text-sm"
								color="primary"
								fill="outline"
								size="small"
								>Add event to Outlook</ion-button
							>
							<ion-button
								@click="openGoogle()"
								class="text-sm"
								color="primary"
								fill="outline"
								size="small"
								>Add event to Google</ion-button
							>
						</div>
						<div v-if="isAuth">
							<ion-button
								v-if="diff?.asSeconds() > 0"
								disabled
								color="primary"
								size="small"
								@click="goToClass(id)"
								>Start Call</ion-button
							>
							<ion-button
								v-else
								color="primary"
								size="small"
								@click="goToClass(id)"
								>Start Class</ion-button
							>
							<ion-button
								color="danger"
								v-if="isScheduled && removeEnabled"
								size="small"
								@click="unscheduleClass()"
								>Cancel Booking</ion-button
							>
							<ion-button
								color="danger"
								v-else-if="!removeEnabled"
								size="small"
								disabled
								>Cancel Booking</ion-button
							>
							<ion-button
								color="secondary"
								v-else
								size="small"
								@click="scheduleClass()"
								>Schedule Class</ion-button
							>
						</div>
						<div v-else>
							<ion-button color="primary" size="block" @click="signup()"
								>Join Now</ion-button
							>
							<ion-button
								color="medium"
								size="small"
								fill="clear"
								@click="login()"
								>Already have an account? Login Here</ion-button
							>
						</div>
					</ion-col>
				</ion-row>
				<ion-row v-if="scheduling">
					<ion-col>
						<p>Schedule your On-demand class</p>
						<DatePicker
							v-bind:is-dark="client.mode == 'dark' ? true : false"
							v-model="utcDate"
							mode="dateTime"
							:min-date="new Date()"
							:minute-increment="10"
						/><br /><br />
						<ion-button size="small" disabled v-if="!canSubmit"
							>Schedule</ion-button
						>
						<ion-button size="small" v-else @click="scheduleVOD()"
							>Schedule</ion-button
						>
						<ion-button
							size="small"
							color="medium"
							@click="this.scheduling = false"
							>Back</ion-button
						>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-content>
		<ion-content v-else> 
			<div class="spin">
				<ion-spinner name="crescent"></ion-spinner>
			</div>
		</ion-content>
	</div>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonLabel,
	IonButton,
	IonAvatar,
	IonButtons,
	alertController,
	loadingController,
	IonSpinner,
} from "@ionic/vue";
import { DatePicker } from "v-calendar";
import { defineComponent } from "vue";
import { modalController, isPlatform } from "@ionic/vue";
import moment, { utc } from "moment";
import { ActionTypes as VideoPlayer } from "../../store/modules/video_player";
import { ActionTypes as ScheduleActions } from "../../store/modules/schedule";
import { store } from "@/store";
import { saveVideo } from "@/utils/video";
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import { ActionTypes as Bookings } from "../../store/modules/bookings";
import { ActionTypes as Coins } from "../../store/modules/coins";
import { openToastOptionsPayment } from "../../utils/toast";

const getDateDiff = (date1, date2) => {
	const diff = new Date(date2.getTime() - date1.getTime());
	let diff2 = undefined;
	// console.log(date2);
	if (moment(date1).isBefore(date2)) {
		diff2 = moment.duration(moment(date2).diff(moment(date1)));
	} else {
		diff2 = moment.duration(0);
	}
	return diff2;
};

export default defineComponent({
	name: "BookingViewModal",
	props: {
		title: { type: String, default: "Super Modal" },
		videoimage: { type: String, default: "demo/avatar-luke.png" },
		eventdate: { type: Object, default: null },
		eventname: { type: String, default: "Super Modal" },
		trainer: { type: String, default: "Super Modal" },
		isScheduled: { type: Boolean, default: false },
		allDat: { type: Object, default: null },
		video: { type: Object, default: null },
	},

	mounted: async function () {
		// console.log(this.video);
		if (this.eventdate.seconds) {
			// console.log("SETTING INTERVAL");
			this.timer = setInterval(this.getDiff, 1000);
		}
	},

	unmounted: function () {
		if (this.timer) {
			clearInterval(this.timer);
			this.timer = undefined;
		}
	},
	ionViewWillEnter() {
		this.removeEnabled = true;
	},

	ionViewWillLeave() {
		if (this.timer) {
			clearInterval(this.timer);
			this.timer = undefined;
		}
	},

	data() {
		return {
			diff: undefined,
			timer: undefined,
			isAuth: false,
			scheduling: false,
			utcDate: new Date(),
			canSubmit: false,
			removeEnabled: true,
			loading: false
		};
	},

	computed: {
		eventDate() {
			const utcTime = utc(new Date(this.eventdate.seconds * 1000));
			const local = utcTime.local();
			const localF = local.format("YYYY-MM-DD HH:mm:ss");
			return localF;
		},
		client() {
			return store.getters.client;
		},
	},

	watch: {
		utcDate(newDate, oldDate) {
			this.canSubmit = true;
			// console.log(newDate);
		},
	},

	setup() {
		return {
			alertController,
		};
	},

	async created() {
		this.isAuth = await store.getters.auth;
	},

	components: {
		IonContent,
		IonHeader,
		IonTitle,
		IonToolbar,
		IonLabel,
		IonButton,
		IonButtons,
		DatePicker,
		IonSpinner
	},
	methods: {
		async close() {
			console.log("closing");
			await modalController.dismiss();
		},
		getDiff() {
			this.diff = getDateDiff(
				new Date(),
				new Date(this.eventdate.seconds * 1000)
			);
		},

		cancelBooking(){

			const vid = this.video;
			console.log("VID DATA", vid);
			let alert = null
			store
				.dispatch(Bookings.cancelBooking, vid)
				.then(async () => {
					this.removeEnabled = false;
					alert = await alertController.create({
						header: "Removed",
						message: `${vid.className} has been removed from your schedule`,
						buttons: ["OK"],
					});
					await alert.present();
				})
				.catch(async (error) => {
					alert = await alertController.create({
						header: "Error",
						message: error,
						buttons: ["OK"],
					});
					await alert.present();
				});
				this.loading = false
				this.close()
		},

		async showCancellationAlert(percent){
			let message = null
			if(percent === 0 ){
			message = 	'Are you sure you want to cancel this booking?'
			} else {
				message = `Cancelling this booking will result in a ${percent}% cancellation fee, are you sure you want to continue?`
			}


			const alert = await alertController.create({
					header: "Confirmation",
					message: message,
					buttons: [
						{
							text: "Yes",
							handler: async () => {
								let amount = null
								console.log(this.video)
								if(percent === 50){
									amount = Math.floor(this.video.amount/2)
								}else if (percent === 0){
									amount = this.video.amount
								}

								if(percent !== 100){
								// do we refunc?
								const coach = this.video.trainerData.id
								const type = "Refund"
								const originalTransaction = this.video.transactionID
								console.log(amount)

								const payload = {coach, amount, type, originalTransaction}

								await store.dispatch(Coins.userRefund, payload)
								.then(async ({ header, message, success, transactionID }) => {
									openToastOptionsPayment(header, message)
									this.cancelBooking()
								})
							} else {
								this.cancelBooking()
							}

								// store.dispatch(Coins.userRefund, )
							},
						},
						{
							text: "No",
						},
					],
					
				});
				await alert.present();
		},

		

		unscheduleClass() {
			this.loading = true
			if (this.diff.asHours() > 24) {
				console.log("No Penalty");
					
				this.showCancellationAlert(0)
			} else if (this.diff.asHours() < 24 && this.diff.asHours() > 12) {
				console.log("50% Fee");
				this.showCancellationAlert(50)
				
			} else {
				console.log("100% Fee");
				this.showCancellationAlert(100)
				
			}
			// this.close()

			// if(vid.transactionID){
				
			// }else {
			// 	const payload = {
			// 	userID: store.getters.userProfile.uid,
			// 	classID: vid.classID,
			// };

			// store
			// 	.dispatch(ScheduleActions.removeScheduleItem, payload)
			// 	.then(async () => {
			// 		this.removeEnabled = false;
			// 		alert = await alertController.create({
			// 			header: "Removed",
			// 			message: `${vid.name} has been removed from your schedule`,
			// 			buttons: ["OK"],
			// 		});
			// 		await alert.present();
			// 	})
			// 	.catch(async (error) => {
			// 		alert = await alertController.create({
			// 			header: "Error",
			// 			message: error,
			// 			buttons: ["OK"],
			// 		});
			// 		await alert.present();
			// 	});
			// }
		},
		openOutlook() {
			// Get event data
			// Body (Description)
			const body = this.allDat.className;
			// Location (online?)
			const location = "Platform Studios App";
			// Path for action to be taken
			const path = "/calendar/action/compose";
			// Actual action
			const rru = "addevent";
			// Start time ISOString specifically for outlook
			const startDateTime = moment(
				new Date(this.eventdate.seconds * 1000)
			).format();

			console.log(startDateTime);
			// End time (ideally do endTime = startTime + sessionDuration)
			const endDateTime = moment(this.allDat.dateISO)
				.add(this.allDat.duration, "minutes")
				.format();
			// Subject (Title)
			const subject = this.allDat.className;
			// Build outlook url

			const event = {
				title: this.allDat.className,
				start: startDateTime,
				end: endDateTime,
				description: body,
				location: location,
			};

			console.log(outlook(event));

			// Open Outlook in new tab
			window.open(outlook(event), "_blank");
		},
		openGoogle() {
			// Get event data
			// Body (Description)
			const details = this.allDat.className;
			// Location (online?)
			const location = "Platform Studios App";
			// // Path for action to be taken
			// const path = "/calendar/action/compose";
			// Actual action
			// const rru = "addevent";
			// Start time ISOString specifically for outlook
			const startDateTime = moment(
				new Date(this.eventdate.seconds * 1000)
			).format("YYYYMMDDTHHmmSSZ");

			console.log(startDateTime);
			// End time (ideally do endTime = startTime + sessionDuration)
			const endDateTime = moment(this.allDat.dateISO)
				.add(this.allDat.duration, "minutes")
				.format("YYYYMMDDTHHmmSSZ");
			console.log(endDateTime);
			// Subject (Title)
			const text = this.allDat.className;
			// Build outlook url
			const googleUri = encodeURI(
				`https://calendar.google.com/calendar/u/0/r/eventedit?` +
					`details=${details}` +
					`&dates=${startDateTime}/${endDateTime}` +
					`&ctz=+0200` +
					`&location=${location}` +
					`&text=${text}`
			);
			// URI Check
			// console.log(outlookUri);
			// Open Outlook in new tab
			window.open(googleUri, "_blank");
		},
		scheduleVOD() {
			const vid = this.video;
			const payload = {
				userID: store.getters.userProfile.uid,
				classID: vid.classID,
				date: { seconds: Math.round(this.utcDate / 1000), nanoseconds: 0 },
				className: vid.name,
			};
			let alert = null;
			if (moment(this.utcDate).isBefore(moment())) {
				(async () => {
					alert = await alertController.create({
						header: "Error",
						message: "Cannot Schedule Class - the requested time has passed",
						buttons: ["OK"],
					});
					await alert.present();
				})();
			} else {
				store
					.dispatch(ScheduleActions.addToUserSchedule, payload)
					.then(async () => {
						alert = await alertController.create({
							header: "Booked",
							message: `${vid.name} has been added to your schedule`,
							buttons: ["OK"],
						});
						await alert.present();
					})
					.catch(async (error) => {
						alert = await alertController.create({
							header: "Error",
							message: error,
							buttons: ["OK"],
						});
						await alert.present();
					});
			}
		},

		scheduleClass() {
			const vid = this.video;
			if (vid.isLive) {
				const payload = {
					userID: store.getters.userProfile.uid,
					classID: vid.classID,
					date: vid.date,
					className: vid.name,
				};
				let alert = null;
				store
					.dispatch(ScheduleActions.addToUserSchedule, payload)
					.then(async () => {
						alert = await alertController.create({
							header: "Booked",
							message: `${vid.name} has been added to your schedule`,
							buttons: ["OK"],
						});
						await alert.present();
					})
					.catch(async (error) => {
						alert = await alertController.create({
							header: "Error",
							message: error,
							buttons: ["OK"],
						});
						await alert.present();
					});
			} else {
				this.scheduling = true;
			}
		},

		async goToClass(classID) {
			store.dispatch(VideoPlayer.selectCurrentVideo, this.video);
			await modalController.dismiss();
			this.$router.push(`/videoPlayer/${this.id}`);
		},
		async downloadVideo(vidID) {
			const loading = await loadingController.create({
				message: "Downloading...",
			});
			// console.log(isPlatform("hybrid"));
			if (!isPlatform("hybrid")) {
				alert("not mobile");
				return;
			}
			await loading.present();
			await saveVideo(vidID, `${vidID}.mp4`);
			loading.dismiss();
			// console.log(vidID);
		},
		async login() {
			await modalController.dismiss();
			this.$router.push("/login");
		},
		async signup() {
			await modalController.dismiss();
			this.$router.push("/join");
		},
	},
});
</script>
<style scoped>
.spin {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100%;
}
</style>
