import { ActionTree } from "vuex";
import { MutationTypes } from "./mutations";
import { State } from "./index";
import { RootState, store } from "@/store";
import * as fb from "../../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import moment, { utc } from "moment";

import { AugmentedActionContext } from "./types";
import { Actions } from "./interfaces";
//
import { ActionTypes as Auth } from "../auth";
import { Calendar } from "@ionic-native/calendar"
import { syncSchedule } from "@/utils/utils"
import { isPlatform } from "@ionic/vue";
import videoPlayerModule from "../video_player";

// Actions
export enum ActionTypes {
	addToUserSchedule = "ADD_TO_SCHEDULE",
	fetchUserSchedule = "FETCH_FROM_SCHEDULE",
	removeScheduleItem = "REMOVE_FROM_SCHEDULE",
	syncCalendar = "SYNC_CALENDAR",
	disableCalendarSync = "DISABLE_CALENDAR_SYNC",
	reset = "RESET",
}

export const actions: ActionTree<State, RootState> & Actions = {
	[ActionTypes.addToUserSchedule](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		const user = payload.userID;
		fb.users
			.doc(user)
			.collection("schedule")
			.doc(payload.classID)
			.set({
				added: true,
				addedAt: firebase.firestore.FieldValue.serverTimestamp(),
				date: new firebase.firestore.Timestamp(
					payload.date.seconds,
					payload.date.nanoseconds
				),
				dateString: new Date(payload.date.seconds * 1000).toUTCString(),
				timeString: moment(payload.date.seconds *1000).format('HH:mm'),
				classID: payload.classID,
				className: payload.className,
				uid: payload.userID,
				isLive: payload.isLive
			})
			.then(() => {
				// ADD EXPERIENCE POINTS FOR SCHEDULING A CLASS
				store
					.dispatch(Auth.addXP, { name: "ScheduleClass", value: 20 })
					.catch((error) => {
						console.log("Error adding XP!", error.message);
					});
				// Sync schedule with external calendar
				if ((isPlatform("android") || isPlatform("ios")) && store.getters.calendarSyncEnabled) {
					console.log("syncing calendar")
					store.dispatch(ActionTypes.syncCalendar)
				}
			});
	},

	async [ActionTypes.fetchUserSchedule](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		const user = payload.user;

		const startTime = utc(payload.start).local().format();

		const endTime = utc(payload.end).local().format();

		//actually pull the schedule
		await fb.users
			.doc(user)
			.collection("schedule")
			.where(
				"date",
				">=",
				firebase.firestore.Timestamp.fromDate(new Date(startTime))
			)
			.where(
				"date",
				"<=",
				firebase.firestore.Timestamp.fromDate(new Date(endTime))
			)
			.onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
				const data = querySnapshot.docs;
				const output = [] as firebase.firestore.DocumentData[];
				data.forEach(
					(documentSnapshot: firebase.firestore.DocumentSnapshot) => {
						const docData = documentSnapshot.data();

						if (docData) {
							output.push({
								...docData,
								id: documentSnapshot.id,
								dateMoment: moment(docData.date.seconds * 1000),
							});


						}

					}
				);
				// console.log("OUTPUT", output)
				commit(MutationTypes.setUserSchedule, output);
			});
	},

	async [ActionTypes.removeScheduleItem](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		await fb.users
			.doc(payload.userID)
			.collection("schedule")
			.doc(payload.classID)
			.delete();
	},

	// Sync workout schedule with external calendar (IOS and Android)
	async [ActionTypes.syncCalendar]({ commit }: AugmentedActionContext) {

		const options = {
			calendarColor: store.getters.client.primary_color,
			calendarName: store.getters.client.name,
		}

		return new Promise((resolve, reject) => {
			Calendar.createCalendar(options).then(
				async (msg) => {
					console.log('calendar created', msg)
					const req = Calendar.requestReadWritePermission()
					console.log('calendar permissions res', req)
					// const calOptions = Calendar.getCreateCalendarOptions()
					const res = await syncSchedule(store.getters.client.name)
					console.log('calendar sync schedule res', res)
					res === true ? commit(MutationTypes.setCalendarSyncEnabled, true) : commit(MutationTypes.setCalendarSyncEnabled, false)
					resolve(res)
				},
				(err) => {
					console.log('calendar create error', err)
					commit(MutationTypes.setCalendarSyncEnabled, true)
					reject(err)
				}
			)
		})
	},

	// Disable calendar sync
	async [ActionTypes.disableCalendarSync]({ commit }: AugmentedActionContext) {
		commit(MutationTypes.setCalendarSyncEnabled, false);
	},

	// RESET
	async [ActionTypes.reset]({ commit }) {
		commit(MutationTypes.reset);
	},
};
