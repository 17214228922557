<template>
	<ion-content class="text-right" v-if="isShowing">
		<ion-item-divider class="text-center">
			<ion-label class="font-sm" color="primary">{{
				userProfile.userName
			}}</ion-label>
		</ion-item-divider>
		<ion-item>
			<ion-button @click="profile()" fill="clear" color="medium"
				>My Profile</ion-button
			>
		</ion-item>
		<ion-item v-if="client?.coaches_enabled">
			<ion-button @click="inbox()" fill="clear" color="medium"
				>Inbox</ion-button
			>
		</ion-item>
		<ion-item>
			<ion-button @click="settings()" fill="clear" color="medium"
				>Settings</ion-button
			>
		</ion-item>
		<ion-item>
			<ion-button @click="logout()" fill="clear" color="medium"
				>Logout</ion-button
			>
		</ion-item>
	</ion-content>
</template>

<script>
import { IonContent, popoverController, loadingController } from "@ionic/vue";
import { defineComponent } from "vue";
import { store } from "../store";
import { resetStore } from "@/utils/utils";
import { auth } from "@/firebase";
import localStore from "../utils/localstore";
import { ActionTypes as Auth } from "../../src/store/modules/auth";

export default defineComponent({
	name: "UserDropDown",
	components: { IonContent },
	data() {
		return {
			isShowing: true,
		};
	},
	methods: {
		async logout() {
			const loading = await loadingController.create({
				message: "Loading...",
			});
			loading.present();
			await auth.signOut().then(async () => {
				await resetStore();
				popoverController.dismiss();
				loading.dismiss();
				this.$router.push("/");
			});
		},
		async settings() {
			await popoverController.dismiss();
			this.$router.push("/settings");
		},
		async profile() {
			await popoverController.dismiss();
			this.$router.push("/profile");
		},
		async inbox() {
			await popoverController.dismiss();
			this.$router.push("/chat/users/null");
		},
	},
	computed: {
		userProfile() {
			const userProfileData = store.getters.userProfile;
			// console.log("Profile View", userProfileData);
			return userProfileData;
		},
		client() {
			return store.getters.client;
		},
	},
});
</script>
