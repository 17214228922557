import { InjectionKey } from "vue";

import {
	createStore,
	Store as VuexStore,
	useStore as baseUseStore,
} from "vuex";

import createPersistedState from "vuex-persistedstate";

import auth, { State as AuthState, Store as AuthStore } from "./modules/auth";

import content, {
	State as ContentState,
	Store as ContentStore,
} from "./modules/content";

import trainers, {
	State as TrainerState,
	Store as TrainerStore,
} from "./modules/trainers";

import programs, {
	State as ProgramState,
	Store as ProgramStore,
} from "./modules/programs";

import videos, {
	State as VideoState,
	Store as VideoStore,
} from "./modules/video_player";

import schedule, {
	State as ScheduleState,
	Store as ScheduleStore,
} from "./modules/schedule";

import global, {
	State as GlobalState,
	Store as GlobalStore,
} from "./modules/global";

import social, {
	State as SocialState,
	Store as SocialStore,
} from "./modules/social";

import health, {
	State as HealthState,
	Store as HealthStore,
} from "./modules/health";

import leaderboard, {
	State as LeaderboardState,
	Store as LeaderboardStore,
} from "./modules/leaderboard";

import chat, { State as ChatState, Store as ChatStore } from "./modules/chat";

import coin, { State as CoinState, Store as CoinStore } from "./modules/coins";

import bookings, {
	State as BookingState,
	Store as BookingStore,
} from "./modules/bookings";

import call, { State as CallState, Store as CallStore } from "./modules/call";

// import other, {
//   State as OtherState,
//   Store as OtherStore,
// } from "./modules/other_module";

// define store state types
export interface RootState {
	auth: AuthState;
	trainers: TrainerState;
	content: ContentState;
	programs: ProgramState;
	videos: VideoState;
	schedule: ScheduleState;
	global: GlobalState;
	social: SocialState;
	health: HealthState;
	leaderboard: LeaderboardState;
	chat: ChatState;
	coin: CoinState;
	bookings: BookingState;
	call: CallState;
	/* other: OtherState; */
}

export type RootStore = AuthStore<
	Pick<RootState, "auth"> &
		TrainerStore<Pick<RootState, "trainers">> &
		ContentStore<Pick<RootState, "content">> &
		ProgramStore<Pick<RootState, "programs">> &
		VideoStore<Pick<RootState, "videos">> &
		ScheduleStore<Pick<RootState, "schedule">> &
		GlobalStore<Pick<RootState, "global">> &
		SocialStore<Pick<RootState, "social">> &
		HealthStore<Pick<RootState, "health">> &
		LeaderboardStore<Pick<RootState, "leaderboard">> &
		ChatStore<Pick<RootState, "chat">> &
		CoinStore<Pick<RootState, "coin">> &
		BookingStore<Pick<RootState, "bookings">> &
		CallStore<Pick<RootState, "call">>
>;
// & OtherStore<Pick<RootState, "other">> &

// define injection key
export const key: InjectionKey<VuexStore<RootStore>> = Symbol();
export const store = createStore<RootState>({
	modules: {
		auth,
		content,
		trainers,
		programs,
		videos,
		schedule,
		social,
		global,
		health,
		leaderboard,
		chat,
		coin,
		bookings,
		call,
		// other
	},
	plugins: [createPersistedState()],
});
// o usar `import useStore from '@store'`
// export default function useStore(): RootStore {
//   return baseUseStore(key);
// }
