
import {
	IonContent,
	IonPage,
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	isPlatform,
	IonInput,
	IonLabel,
	IonSpinner,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { heart, eye, chatbox, paperPlane, arrowBack } from "ionicons/icons";
import { store } from "../store";
import { useRouter } from "vue-router";
import localStore from "../utils/localstore";
import { ActionTypes as Chat } from "../store/modules/chat";
import { generateRandomString } from "@/utils/utils";
import * as fb from "../firebase";

export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonPage,
		IonGrid,
		IonRow,
		IonCol,
		IonIcon,
		IonInput,
		IonLabel,
		IonSpinner,
	},
	data() {
		return {
			heart,
			eye,
			chatbox,
			paperPlane,
			arrowBack,
			postingComment: false,
			loadingComments: false,
			uid: "",
			avatar: "",
			name: "",
			liveStreamId: "",
			comments: [{}],
			views: 0,
			commentCount: 0,
			likes: 0,
			comment: "",
			commentsVisible: true
		};
	},
	setup() {
		const router = useRouter();
		const isAndroid = isPlatform("android");
		const isIOS = isPlatform("ios");

		return {
			router,
			isAndroid,
			isIOS,
		};
	},
	async beforeMount() {
		this.liveStreamId = store.getters.liveStreamId;
		this.commentCount = store.getters.liveStreamCommentCount;
		this.likes = store.getters.liveStreamLikeCount;
		this.views = store.getters.liveStreamViewCount;
		this.comments = this.getComments(store.getters.liveStreamComments);

		const user = await localStore.get("user");
		this.uid = user.uid;

		this.avatar = user.avatar;
		this.name = user.userName;

		store.dispatch(Chat.watchLiveStreamData, this.liveStreamId);
	},
	computed: {
		isWeb() {
			return store.getters.isWeb;
		},
	},
	watch: {
		commentCount() {
			this.comments = this.getComments(store.getters.liveStreamComments);
		},
	},
	methods: {
		async postComment() {
			if (this.comment.length > 0) {
				this.postingComment = true;
				this.liveStreamId = store.getters.liveStreamId;

				const payload = {
					liveStreamId: this.liveStreamId,
					commentId: generateRandomString(20),
					senderId: this.uid,
					avatar: this.avatar,
					name: this.name,
					comment: this.comment,
					time: new Date(),
				};
				await store.dispatch(Chat.postLiveStreamComment, payload);

				this.comment = "";

				this.views = store.getters.liveStreamViewCount;
				this.commentCount = store.getters.liveStreamCommentCount;
				this.likes = store.getters.liveStreamLikeCount;

				this.postingComment = false;
			}
		},
		getComments(comments: object[]) {
			const commentCount = comments.length;
			const filteredComments: object[] = [];

			let i = commentCount - 1;
			let counter = 0;
			while (counter < 4) {
				if (i >= 0) {
					filteredComments.push(comments[i]);
					i--;
					counter++;
				} else break;
			}
			return filteredComments;
		},
		toggleComments() {
			this.commentsVisible = !this.commentsVisible;
		}
	},
	beforeUnmount() {
		const unsubscribe = fb.db
			.collection("livestream-comments")
			.onSnapshot(() => {
				// Respond to data
				console.log("unsubscribed to realtime updates");
			});
		// Stop listening to changes
		unsubscribe();
	},
});
