import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./index";
import { RootState } from "@/store";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";

//Define names of actions
export enum ActionTypes {
	setPlatform = "SET_PLATFORM",
}

type AugmentedActionContext = {
	commit<K extends keyof Mutations>(
		key: K,
		payload: Parameters<Mutations[K]>[1]
	): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

// Define our actions method signatures
export interface Actions {
	[ActionTypes.setPlatform](
		{ commit }: AugmentedActionContext,
		payload: boolean
	): void;
}

// Implement Actions in line with method signatures
export const actions: ActionTree<State, RootState> & Actions = {
	[ActionTypes.setPlatform]({ commit }, payload) {
		console.log(payload);
		commit(MutationTypes.setPlatform, payload);
	},
};
