
import axios from "axios";

import {
	IonContent,
	IonHeader,
	IonPage,
	IonSelect,
	IonSelectOption,
	IonRadio,
	IonRadioGroup,
	IonToolbar,
	IonIcon,
	IonRow,
	IonCol,
	IonGrid,
	IonBackButton,
	IonButtons,
	alertController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import Bottombar from "../components/Bottombar.vue";
import Navbar from "../components/Navbar.vue";
import { Storage } from "@ionic/storage";
import { store } from "../store";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import localStore from "../utils/localstore";
import { users } from '../firebase'

// const stor = new Storage();
// stor.create();

export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonSelect,
		IonSelectOption,
		IonRadio,
		IonRadioGroup,
		Navbar,
		Bottombar,
		IonRow,
		IonCol,
		IonGrid,
		IonBackButton,
		IonButtons,
	},
	setup() {
		let isWeb = false;
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			isWeb = false;
		} else {
			isWeb = true;
		}
		return {
			isWeb,
		};
	},
	data() {
		return {
			// userEmail: "",
			userMsg: "",
			topic: "",
		};
	},
	methods: {
		// async sendMsg() {
		// 	// Get user email address.
		// 	const userDetails = await localStore.get("user");
		// 	// Get sender email
		// 	const from = "cwt.silvere@gmail.com"; // userDetails.email;
		// 	// Get client recipient email.
		// 	const to = "silvere@ambersoftware.net"; // store.getters.client.contactemail;
		// 	// Get subject
		// 	const subject = "Test-Mailer";
		// 	// Get user message.
		// 	const text = this.userMsg.replace("\n", "<br>");
		// 	// Set html
		// 	const html = `<p>${text}</p><strong>Test Mailer</strong>`;

		// 	// Fire Email Function
		// 	const sendEmail = firebase.functions().httpsCallable("sendEmail");

		// 	return new Promise((resolve, reject) => {
		// 		sendEmail({
		// 			from: from,
		// 			to: to,
		// 			subject: subject,
		// 			text: text,
		// 			html: html,
		// 		})
		// 			.then(({ data }) => {
		// 				// console.log("Email Sent!", data);
		// 				resolve("Email Successfully Sent!");
		// 			})
		// 			.catch((err) => {
		// 				console.log("Error", err);
		// 				reject(err);
		// 			});
		// 	});
		// 	//
		// },

		async sendEmail() {
			// Get user email address
			// const userDetails = await localStore.get("user");
			// const userEmail = await store.getters.auth.userProfile;
			const uid = await store.getters.userProfile.uid;
			console.log("UID", uid)

			const user = await users.doc(uid).get();
			const data = user.data();
			// const userEmail = user.data()?.email;

			// console.log(userEmail)
			// Get client email address
			const client = store.getters.client;
			const clientEmail = client.contactemail;
			const now = (new Date()).toString();

			if (this.topic === "") {
				const alert = await alertController.create({
					header: "",
					message: "Please select a topic.",
					buttons: ["OK"],
				});
				alert.present();
			} else if (this.userMsg.length < 3 || this.userMsg === "\n") {
				const alert = await alertController.create({
					header: "",
					message: "Please type in a message.",
					buttons: ["OK"],
				});
				alert.present();
			} else {
				const text = this.userMsg.replace("\n", "<br>");
				const htmlText = `<p>Sent By ${data?.userName}, ${data?.email} on ${now}</p><p>${text}</p>`;

				// Create email payload
				const payload = {
					to: clientEmail,
					message: {
						subject: `Contact/Support Request from ${client?.name} App for ${this.topic}`,
						text: "",
						html: htmlText,
					},
				};

				// Add email payload to mail collection in Firebase
				firebase
					.firestore()
					.collection("mail")
					.add(payload)
					.then(async () => {
						this.userMsg = ""
						const alert = await alertController.create({
							header: "",
							message: "Your message has been successfully sent!",
							buttons: ["OK"],
						});
						alert.present();
					})
					.catch(async (error) => {
						console.log("Error!", error);
						const alert = await alertController.create({
							header: "Error",
							message: error,
							buttons: ["OK"],
						});
						alert.present();
					});
			}
		},
	},
	computed: {
		client() {
			return store.getters.client;
		},
	},
});
