<template>
	<div style="height: 100vh">
		<ion-header class="ion-no-border border-bottom">
			<ion-toolbar color="light">
				<ion-title color="primary" class="text-md">{{ title }}</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="close()">X</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content color="light" class="ion-padding text-center">
			<ion-grid>
				<ion-row v-if="!scheduling">
					<ion-col>
						<img :src="videoimage" style="height: 20vh" />
						<br />
						<ion-label
							color="primary"
							v-if="eventDate.seconds"
							class="text-sm"
							>{{ eventDate }}</ion-label
						><br />
						<ion-label class="text-md"
							><strong>{{ eventname }}</strong></ion-label
						><br />
						<ion-label color="primary" class="text-sm"
							><strong>{{ trainer }}</strong></ion-label
						>
						<div class="text-md video-desc" v-html="content"></div>
						<p
							v-if="
								isLive == true && diff != undefined && diff?.asSeconds() > 0
							"
						>
							{{ diff.days() }} days, {{ diff.hours() }} hours,
							{{ diff.minutes() }} minutes, {{ diff.seconds() }} seconds,
						</p>

						<br />
						<div v-if="isAuth">
							<ion-button
								v-if="isLive == true && diff?.asSeconds() > 0"
								disabled
								color="primary"
								size="small"
								@click="goToClass(id)"
								>Start Class</ion-button
							>
							<ion-button
								v-else
								color="primary"
								size="small"
								@click="goToClass(id)"
								>Start Class</ion-button
							>
							<ion-button
								color="danger"
								v-if="isScheduled && removeEnabled"
								size="small"
								@click="unscheduleClass()"
								>Remove from Schedule</ion-button
							>
							<ion-button
								color="danger"
								v-else-if="!removeEnabled"
								size="small"
								disabled
								>Remove from Schedule</ion-button
							>
							<ion-button
								color="secondary"
								v-else
								size="small"
								@click="scheduleClass()"
								>Schedule Class</ion-button
							>
						</div>
						<div v-else>
							<ion-button color="primary" size="block" @click="signup()"
								>Join Now</ion-button
							>
							<ion-button
								color="medium"
								size="small"
								fill="clear"
								@click="login()"
								>Already have an account? Login Here</ion-button
							>
						</div>
					</ion-col>
				</ion-row>
				<ion-row v-if="scheduling">
					<ion-col class="scheduled-content">
						<p>Schedule your On-demand class</p>
						<DatePicker
							v-bind:is-dark="client.mode == 'dark' ? true : false"
							v-model="utcDate"
							mode="dateTime"
							:min-date="new Date()"
							:minute-increment="10"
							@click.stop="() => setSchedulingEnabled()"
						/><br /><br />
						<ion-button size="small" disabled v-if="!scheduledButtonEnabled"
							>Schedule</ion-button
						>
						<ion-button size="small" v-else @click="scheduleVOD()"
							>Schedule</ion-button
						>
						<ion-button
							size="small"
							color="medium"
							@click="() => scheduling = false"
							>Back</ion-button
						>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-content>
	</div>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonLabel,
	IonButton,
	IonAvatar,
	IonButtons,
	alertController,
	loadingController,
} from "@ionic/vue";
import { DatePicker } from "v-calendar";
import { defineComponent } from "vue";
import { modalController, isPlatform } from "@ionic/vue";
import moment, { utc } from "moment";
import { ActionTypes as VideoPlayer } from "../../store/modules/video_player";
import { ActionTypes as ScheduleActions } from "../../store/modules/schedule";
import {ActionTypes as Bookings } from "../../store/modules/bookings"
import { store } from "@/store";
import { saveVideo } from "@/utils/video";

const getDateDiff = (date1, date2) => {
	const diff = new Date(date2.getTime() - date1.getTime());
	let diff2 = undefined;
	if (moment(date1).isBefore(date2)) {
		diff2 = moment.duration(moment(date2).diff(moment(date1)));
	} else {
		diff2 = moment.duration(0);
	}
	return diff2;
};

export default defineComponent({
	name: "Modal",
	props: {
		title: { type: String, default: "Super Modal" },
		videoimage: { type: String, default: "demo/avatar-luke.png" },
		content: { type: String, default: "Super Modal" },
		eventdate: { type: Object, default: null },
		eventname: { type: String, default: "Super Modal" },
		trainer: { type: String, default: "Super Modal" },
		isLive: { type: Boolean, default: false },
		id: { type: String, default: "No Video Found" },
		video: { type: Object, default: null },
		isScheduled: { type: Boolean, default: false },
	},

	mounted: async function () {
		if (this.eventdate.seconds) {
			this.timer = setInterval(this.getDiff, 1000);
		}
	},

	unmounted: function () {
		if (this.timer) {
			clearInterval(this.timer);
			this.timer = undefined;
		}
	},
	ionViewWillEnter() {
		this.removeEnabled = true;
	},

	ionViewWillLeave() {
		if (this.timer) {
			clearInterval(this.timer);
			this.timer = undefined;
		}
	},

	data() {
		return {
			diff: undefined,
			timer: undefined,
			isAuth: false,
			scheduling: false,
			utcDate: new Date(),
			removeEnabled: true,
			scheduledButtonEnabled: false,
		};
	},

	computed: {
		eventDate() {
			if (this.isLive) {
				const utcTime = utc(new Date(this.eventdate.seconds * 1000));
				const local = utcTime.local();
				const localF = local.format("YYYY-MM-DD HH:mm:ss");
				return localF;
			} else {
				return "";
			}
		},
		client() {
			return store.getters.client;
		},
	},

	setup() {
		return {
			alertController,
		};
	},

	async created() {
		this.isAuth = await store.getters.auth;
	},

	components: {
		IonContent,
		IonHeader,
		IonTitle,
		IonToolbar,
		IonLabel,
		IonButton,
		IonButtons,
		DatePicker,
	},
	methods: {
		setSchedulingEnabled(){
			this.scheduledButtonEnabled = this.utcDate ? true : false;
		},

		async close() {
			await modalController.dismiss();
		},
		getDiff() {
			this.diff = getDateDiff(
				new Date(),
				new Date(this.eventdate.seconds * 1000)
			);
		},

		unscheduleClass() {
			
			const vid = this.video;
				let alert = null;
			if(vid.transactionID){
				store
				.dispatch(Bookings.cancelBooking, vid)
				.then(async () => {
					this.removeEnabled = false;
					alert = await alertController.create({
						header: "Removed",
						message: `${vid.className} has been removed from your schedule`,
						buttons: ["OK"],
					});
					await alert.present();
				})
				.catch(async (error) => {
					alert = await alertController.create({
						header: "Error",
						message: error,
						buttons: ["OK"],
					});
					await alert.present();
				});
			}else {
				const payload = {
				userID: store.getters.userProfile.uid,
				classID: vid.classID,
			};
		
			store
				.dispatch(ScheduleActions.removeScheduleItem, payload)
				.then(async () => {
					this.removeEnabled = false;
					alert = await alertController.create({
						header: "Removed",
						message: `${vid.name} has been removed from your schedule`,
						buttons: ["OK"],
					});
					await alert.present();
				})
				.catch(async (error) => {
					alert = await alertController.create({
						header: "Error",
						message: error,
						buttons: ["OK"],
					});
					await alert.present();
				});
			}
			
		},

		scheduleVOD() {
			const vid = this.video;
			const payload = {
				userID: store.getters.userProfile.uid,
				classID: vid.classID,
				date: { seconds: Math.round(this.utcDate / 1000), nanoseconds: 0 },
				className: vid.name,
				isLive: vid.isLive
			};
			let alert = null;
			if (moment(this.utcDate).isBefore(moment())) {
				(async () => {
					alert = await alertController.create({
						header: "Error",
						message: "Cannot Schedule Class - the requested time has passed",
						buttons: ["OK"],
					});
					await alert.present();
				})();
			} else {
				store
					.dispatch(ScheduleActions.addToUserSchedule, payload)
					.then(async () => {
						this.scheduling = false;
						this.close();
						alert = await alertController.create({
							header: "Booked",
							message: `${vid.name} has been added to your schedule`,
							buttons: ["OK"],
						});
						await alert.present();
					})
					.catch(async (error) => {
						this.scheduling = false;
						this.close();
						alert = await alertController.create({
							header: "Error",
							message: error,
							buttons: ["OK"],
						});
						await alert.present();
					});
			}
		},

		scheduleClass() {
			const vid = this.video;
			if (vid.isLive) {
				const payload = {
					userID: store.getters.userProfile.uid,
					classID: vid.classID,
					date: vid.date,
					className: vid.name,
					isLive: vid.isLive
				};
				let alert = null;
				store
					.dispatch(ScheduleActions.addToUserSchedule, payload)
					.then(async () => {
						alert = await alertController.create({
							header: "Booked",
							message: `${vid.name} has been added to your schedule`,
							buttons: ["OK"],
						});
						await alert.present();
					})
					.catch(async (error) => {
						alert = await alertController.create({
							header: "Error",
							message: error,
							buttons: ["OK"],
						});
						await alert.present();
					});
			} else {
				this.scheduling = true;
			}
		},

		async goToClass(classID) {
			store.dispatch(VideoPlayer.selectCurrentVideo, this.video);
			await modalController.dismiss();
			this.$router.push(`/videoPlayer/${this.id}`);
		},
		async downloadVideo(vidID) {
			const loading = await loadingController.create({
				message: "Downloading...",
			});
			if (!isPlatform("hybrid")) {
				alert("not mobile");
				return;
			}
			await loading.present();
			await saveVideo(vidID, `${vidID}.mp4`);
			loading.dismiss();
		},
		async login() {
			await modalController.dismiss();
			this.$router.push("/login");
		},
		async signup() {
			await modalController.dismiss();
			this.$router.push("/join");
		},
	},
});
</script>
<style scoped>
.scheduled-content {
	padding-bottom: 50px;
}
</style>
