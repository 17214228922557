
import {
	IonContent,
	IonHeader,
	IonPage,
	IonIcon,
	IonImg,
	IonButton,
	IonCol,
	IonRow,
	IonGrid,
	IonCard,
	IonAvatar,
	IonSlide,
	IonSlides,
	IonSpinner,
	modalController,
	isPlatform
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import {
	calendar,
	personCircle,
	playCircleOutline,
	albumsOutline,
	barbellOutline,
	starSharp,
	menuOutline,
	trophyOutline,
} from "ionicons/icons";
import { store } from "../store";
import firebase from "firebase/app";
import "firebase/firestore";
import { ActionTypes as Content } from "../store/modules/content";
import { ActionTypes as Trainer } from "../store/modules/trainers";
import Navbar from "../components/Navbar.vue";
import Modal from "./modals/modalVideo.vue";

export default defineComponent({
	name: "Home",
	components: {
		IonIcon,
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		IonImg,
		IonButton,
		IonCol,
		IonRow,
		IonGrid,
		IonCard,
		IonSlide,
		IonSlides,
		IonSpinner,
	},

	setup() {
		const router = useRouter();
		let isMobile
		// Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.
		const slideOpts = {
			initialSlide: 0,
			speed: 400,
		};
		if (isPlatform("ios") || isPlatform("android")) {
			isMobile = true
		} else {
			isMobile = false
		}
		return {
			playCircleOutline,
			albumsOutline,
			barbellOutline,
			calendar,
			personCircle,
			starSharp,
			trophyOutline,
			menuOutline,
			slideOpts,
			router,
			isMobile,
			IonAvatar,
		};
	},

	// async beforeMount() {
	// 	// Get Focus Type
	// 	this.focusType = store.getters.client["focus_type"] || "classes";

	// 	// Check whether focus is on coaches or classes
	// 	if (this.focusType == "coaches") {
	// 		// Get trainers from store
	// 		this.trainerLoading = true;
	// 		await store.dispatch(Content.setCategories);
	// 		await store.dispatch(Content.setClasses);
	// 		await store.dispatch(Trainer.fetchTrainers);

	// 		this.trainerLoading = false;
	// 	} else if (this.focusType == "classes") {
	// 		this.classesLoading = true;
	// 		const categories = await store.getters.getCategories;
	// 		if (categories.length == 0) {
	// 			// await loading.present();
	// 		}

	// 		await store.dispatch(Content.setCategories);
	// 		await store.dispatch(Content.setClasses);

	// 		this.classesLoading = false;
	// 	}
	// },

	async ionViewWillEnter() {
		// Get Focus Type
		this.focusType = store.getters.client["focus_type"] || "classes";

		// Check whether focus is on coaches or classes
		if (this.focusType == "coaches") {
			// Get trainers from store
			this.trainerLoading = true;
			await store.dispatch(Content.setCategories);
			await store.dispatch(Content.setClasses);
			await store.dispatch(Trainer.fetchTrainers);

			this.trainerLoading = false;
		} else if (this.focusType == "classes") {
			this.classesLoading = true;
			const categories = await store.getters.getCategories;
			if (categories.length == 0) {
				// await loading.present();
			}

			await store.dispatch(Content.setCategories);
			await store.dispatch(Content.setClasses);
			await store.dispatch(Trainer.fetchTrainers);

			this.classesLoading = false;
		}
	},

	data() {
		return {
			classesLoading: false,
			trainerLoading: false,
			focusType: "",
		};
	},

	computed: {
		content() {
			const categories = store.getters.getCategories;
			const classes = store.getters.getClasses;
			const liveClasses = store.getters.getLiveClasses.slice(0, 3);

			console.log(liveClasses);
			return {
				liveClasses: liveClasses,
				classes: classes,
				categories: categories,
			};
		},
		trainers() {
			const trainers = store.getters.trainers;
			return trainers;
		},

		client() {
			return store.getters.client;
		},
		userSchedule() {
			return store.getters.getUserSchedule;
		},
		// isMobile(): boolean {
		// 	const width =
		// 		window.innerWidth ||
		// 		document.body.clientWidth ||
		// 		document.documentElement.clientWidth;
		// 	const height =
		// 		window.innerHeight ||
		// 		document.body.clientHeight ||
		// 		document.documentElement.clientHeight;

		// 	return (width < 480 && height < 890) || (width < 890 && height < 480);
		// },
	},

	methods: {
		// Open Trainer Videos
		openTrainerVideos(trainer: firebase.firestore.DocumentData) {
			store.dispatch(Trainer.selectCurrentTrainer, trainer);

			this.$router.replace({
				path: `/trainervideos/${trainer.id}`,
			});
		},
		pullClasses(categoryName: string) {
			//navigate to videos vue
			this.$router.replace({
				path: `/videos/${categoryName}`,
			});

			//this.items = store.getters.getClasses;
		},
		//TODO Implement in the store
		goToClass(classID: string) {
			this.$router.replace(`/videoPlayer/${classID}`);
		},
		isRegistered(classID: string) {
			const isScheduled = this.userSchedule.filter(
				(scheduleItem: firebase.firestore.DocumentData) => {
					return scheduleItem.classID === classID;
				}
			);

			if (isScheduled.length === 0) {
				return false;
			}

			return true;
		},
		async openModal(classObject: any) {
			// console.log(classObject);
			const modal = await modalController.create({
				component: Modal,
				cssClass: "modal-custom",
				componentProps: {
					title: "Join " + classObject?.name,
					content: classObject?.description || "No Description",
					videoimage: classObject?.imgURL,
					eventdate: classObject.date || {},
					eventname: classObject?.name,
					trainer: classObject?.trainerData.name,
					isLive: classObject?.isLive,
					video: classObject,
					isScheduled: this.isRegistered(classObject.classID),
				},
			});
			return modal.present();
		},
	},
});
