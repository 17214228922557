<template>
	<div style="height: 100vh">
		<ion-header class="ion-no-border border-bottom">
			<ion-toolbar color="light">
				<ion-title class="text-lg">Tip Coach </ion-title>
				<ion-buttons slot="end">
					<ion-button @click="close()">X</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content color="light" class="ion-padding text-center" v-if="!loading">
			<ion-avatar style="width:100px;height:100px;margin:0 auto;border">
								<img :src="trainer.imgURL">
							</ion-avatar>
			<span class="text-primary">How much would you like to tip {{trainer.name}}?</span><br><br>
            <div class="coinsContainer">
                <div class="coinContainer" @click="tip=5">
                    <ion-img src="assets/icons/coin.png" class="coin-icon" ></ion-img>
                    5 Fitcoins
                </div>
                <div class="coinContainer" @click="tip=15">
                    <ion-img src="assets/icons/coin.png" class="coin-icon"></ion-img>
                    15 FitCoins
                </div>
                <div class="coinContainer" @click="tip=30">
                    <ion-img src="assets/icons/coin.png" class="coin-icon" ></ion-img>
                    30 FitCoins
                </div>
            </div>
            <span> Or Select Your Own Amount </span>
            <ion-input 
                @IonChange="tip=parseInt($event.target.value)"
                type = 'text'
                placeholder="0"
            >

            </ion-input>
			<br>
			<ion-item>Amount to Pay: {{tip}} FitCoins</ion-item>
			<br/>
			<ion-button color="success" @click="accept()">Confirm Transaction</ion-button>
			<div class="spacer"></div>
		</ion-content>
		<ion-content color="light" v-if="loading">
			<div class="spin">
				<ion-spinner name="crescent"></ion-spinner>
			</div>
		</ion-content>
	</div>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonButton,
	IonButtons,
	IonSpinner,
    IonInput
} from "@ionic/vue";
import { defineComponent } from "vue";
import { modalController, isPlatform } from "@ionic/vue";
// import { DatePicker } from "v-calendar";
import {
	star,
	starOutline,
	starHalf,
	thumbsDownOutline,
	thumbsUpOutline,
	trophyOutline,
	timerOutline,
	flameOutline,
	heartOutline,
} from "ionicons/icons";
import { ActionTypes as Coins } from "../../store/modules/coins";
import { store } from "@/store";
import { useRouter } from "vue-router";
import { openToastOptionsPayment } from "../../utils/toast";

export default defineComponent({
	name: "tipCoachModal",
	props: { 
		
		trainer: { type: Object, default: null },
        amount: { type: Number, default: 0 },
        // type: { type: String, defualt: "transfer" },
		onConfirmation: {type: Function, defualt: () => this.$router.push('/chat')}
		
	},

	setup() {
		// const store = useStore();
		const router = useRouter();
		return {
			star,
			starOutline,
			starHalf,
			thumbsDownOutline,
			thumbsUpOutline,
			trophyOutline,
			timerOutline,
			flameOutline,
			heartOutline,
		};
	},
	data() {
		return {
			content: "",
			utcDate: new Date(),
			loading: false,
            tip: this.amount
		};
	},
	computed: {
		client() {
			return store.getters.client;
		},
	},

	components: {
		IonContent,
		IonHeader,
		IonTitle,
		IonToolbar,
		IonButton,
		IonButtons,
		IonSpinner,
        IonInput
		// DatePicker

	},
	beforeMount(){
		console.log("Int eh modal")
	},
	methods: {
        async accept(){
            const payload = {
                coach: this.trainer.id,
                amount: this.tip,
                type: "Tip"
            }

            console.log(this.tip, "tip", typeof(this.tip))

			this.loading = true;

            const success = await store.dispatch(Coins.transferCoins, payload)
				.then( async ({header, message, success}) =>{
					await openToastOptionsPayment(header, message);
					this.loading = false;
					return success;
				})

            await modalController.dismiss();

			// if (success) {
			// 	this.onConfirmation();
			// }
		},
		async close() {
			await modalController.dismiss();
		},
	},
});
</script>
<style scoped>
.spacer {
	height: 150px;
}
.spin{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.coinsContainer{
    display:flex;
    flex-direction: row;
	justify-content: center;
	align-items: center;
    width: 100%;
	height: 20%;
    /* padding-top: -2%; */
    /* border: 1px solid red; */
}
.coin-icon{
    /* height:6%; */
    width: 20%;
    /* border: 1px solid grey */
}
.coinContainer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    /* border: 1px solid yellow */
}
</style>
 