import { ActionTree } from "vuex";
import { MutationTypes } from "./mutations";
import { State } from "./index";
import { RootState } from "@/store";
import * as fb from "../../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { store } from "@/store";
import { filterByCategories, filterByDurations } from "@/utils/filters";
import {
	CategoryObject,
	ClassData,
	TrainerData,
} from "@/interfaces/contentStore";
import { AugmentedActionContext } from "./types";
import { Actions } from "./interfaces";
//
import { ActionTypes as Auth } from "../auth";

//Define names of actions
export enum ActionTypes {
	setCategories = "SET_CATEGORIES_ACTION",
	setClasses = "SET_CLASSES_ACTION",
	getFilteredClasses = "GET_FILTERED_CLASSES_ACTION",
	addClassBookmark = "GET_CLASS_BOOKMARK",
	fetchBookmarkedClasses = "FETCH_BOOKMARKED_CLASSES",
	removeClassBookmark = "REMOVE_BOOKMARK",
	reset = "RESET",
}

// Implement Actions in line with method signatures
export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.setCategories]({ commit }) {
		// console.log("HERE");
		const client = await fb.clientCollection
			.doc(process.env.VUE_APP_CLIENT_KEY)
			.get();

		const categories = [] as any;
		const categoryObjects = [] as CategoryObject[];
		// console.log(client.data());
		const clientData = client.data();

		if (clientData) {
			const clientCategories = clientData.categories;

			if (clientCategories) {
				for (let i = 0; i < clientCategories.length; i++) {
					const categoryResponse = await clientCategories[i].get();
					const category = categoryResponse.data();

					if (category) {
						delete category.classes;
					}

					// const categoryRef = categoryResponse?.ref?.path;
					const categoryId = categoryResponse?.ref?.id;

					categoryObjects.push({ ...category, categoryId });

					// console.log("CATEGORIES", categoryObjects);
				}
			}
		}

		// console.log("line of interest");
		// console.log(categoryObjects);

		commit(MutationTypes.setCategories, categoryObjects);
	},

	async [ActionTypes.setClasses]({ commit }) {
		const categories = store.getters.getCategories || [];

		// Mapper categories => categoriesReferences
		const categoryInformation = {
			categoryReferences: [] as any[],
			categoryNames: [] as string[],
			categoryIds: [] as string[],
		};

		categories.forEach((category: any) => {
			categoryInformation.categoryReferences.push(
				fb.categoryCollection.doc(category.categoryId) // storing the category id
			);
			categoryInformation.categoryNames.push(category.name);
			categoryInformation.categoryIds.push(category.categoryId);
		});

		commit(MutationTypes.setCategoryNames, categoryInformation.categoryNames);
		// console.log(categoryInformation);

		// Call all trainers, map to => trainerReferences = { trainerRef: trainerDocData}
		// { trainer: ...trainerObject, classes [...classes]  }
		const trainers = await fb.trainers
			.get()
			.then((trainersDocs) => trainersDocs.docs)
			.catch((err) => err);

		// console.log("Trainers", trainers);

		const trainerInformation = {
			trainerData: [] as any[],
			trainerReferences: [] as any[],
			trainerIds: [] as any[],
		};

		//const trainerData = [] as any

		trainers.forEach((trainer: any) => {
			trainerInformation.trainerData.push(trainer.data());
			trainerInformation.trainerReferences.push(trainer.ref.path);
			trainerInformation.trainerIds.push(trainer.ref.id);
		});

		// console.log(trainerInformation);

		// where command in classesCollection -> class.category, array-contains-any, categoriesReferences
		const classes = await fb.classCollection
			// .orderBy("date", "desc")
			.where("category", "in", categoryInformation.categoryReferences)
			// .orderBy("category")
			.get()
			.then((classDoc: firebase.firestore.QuerySnapshot) => {
				return classDoc.docs.map((classDoc) => {
					// Check trainerRef against trainerDocData

					const classInfo: firebase.firestore.DocumentData = classDoc.data();
					// const ref  = fb.db.doc(classInfo.category.id)
					// console.log(classInfo)
					const indexOfCategory = categoryInformation.categoryIds.indexOf(
						classInfo.category.id
					);
					const indexOfTrainer = trainerInformation.trainerIds.indexOf(
						classInfo.trainer.id
					);

					delete classInfo.trainer;
					delete classInfo.category;

					// const currentCategory = categoryInformation.categoryNames[indexOfCategory]

					// const currentTrainerCategories = trainerInformation.trainerCategories[indexOfTrainer];

					// if (!currentTrainerCategories.includes(currentCategory)){
					// 	trainerInformation.trainerCategories[indexOfTrainer].push(currentCategory)
					// }

					const trainerData: TrainerData = {
						...trainerInformation.trainerData[indexOfTrainer],
						id: trainerInformation.trainerIds[indexOfTrainer] as string,
					};

					const classData: ClassData = {
						categoryId: categoryInformation.categoryNames[
							indexOfCategory
						] as string,
						date: classInfo.date as firebase.firestore.Timestamp,
						description: classInfo.description as string,
						duration: classInfo.duration as number,
						imgURL: classInfo.imgURL as string,
						isFeatured: classInfo.isFeatured as boolean,
						isLive: classInfo.isLive as boolean,
						mirror: classInfo.mirror as boolean,
						name: classInfo.name as string,
						video: classInfo.video as object,
						classID: classDoc.id as string,
						categoryName: categoryInformation.categoryNames[
							indexOfCategory
						] as string,
						trainerData: trainerData as TrainerData,
						videoRating: classInfo.videoRating as number,
					};

					return classData;
				});
			})
			.catch((err) => err);

		// console.log(classes);

		commit(
			MutationTypes.setClasses,
			classes.sort((class1: ClassData, class2: ClassData) => {
				return class1.date.nanoseconds - class2.date.nanoseconds;
			})
		);
	},

	[ActionTypes.getFilteredClasses](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		// { categoryName, duration, intensity}
		// console.log("CONTENT PAYLOAD", payload)
		const { categories, durations } = payload;

		const classes = store.getters.getClasses;

		let filteredClasses = filterByDurations(durations, classes);

		filteredClasses = filterByCategories(categories, filteredClasses);

		//TO be implemeented at a later stage
		//TODO: filteredClasses = filterByIntensity(intensity, classes)

		return filteredClasses;
	},

	[ActionTypes.fetchBookmarkedClasses](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		fb.users
			.doc(payload.userID)
			.collection("bookmarkedClasses")
			.get()
			.then((querySnapshot: firebase.firestore.QuerySnapshot) => {
				let bookmarkedClasses = [] as firebase.firestore.DocumentData[];

				bookmarkedClasses = querySnapshot.docs.map(
					(classDoc: firebase.firestore.DocumentSnapshot) => {
						return { ...classDoc.data() };
					}
				);

				// console.log("BOOKMARKED CLASSES", bookmarkedClasses);
				commit(MutationTypes.setBookmarkedClasses, bookmarkedClasses);
			});
	},

	[ActionTypes.addClassBookmark](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		fb.users
			.doc(payload.userID)
			.collection("bookmarkedClasses")
			.doc(payload.classID)
			.set({
				bookmarkedAt: firebase.firestore.FieldValue.serverTimestamp(),
				classID: payload.classID,
			})
			.then(() => {
				store.dispatch(ActionTypes.fetchBookmarkedClasses, payload);
				// ADD EXPERIENCE POINTS FOR BOOKMARKING A CLASS
				store
					.dispatch(Auth.addXP, { name: "BookmarkClass", value: 5 })
					.catch((error) => {
						console.log("Error adding XP!", error.message);
					});
			});
	},

	[ActionTypes.removeClassBookmark](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		fb.users
			.doc(payload.userID)
			.collection("bookmarkedClasses")
			.doc(payload.classID)
			.delete()
			.then(() => {
				store.dispatch(ActionTypes.fetchBookmarkedClasses, payload);
			});
	},

	// RESET
	async [ActionTypes.reset]({ commit }) {
		commit(MutationTypes.reset);
	},
};
