<template>
	<div style="height: 100vh">
		<ion-header class="ion-no-border border-bottom">
			<ion-toolbar color="light">
				<ion-title class="text-lg">Book One on One</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="close()">X</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content color="light" class="ion-padding text-center" v-if="!loading">
			<ion-avatar style="width:100px;height:100px;margin:0 auto;border">
				<img :src="trainer.imgURL" />
			</ion-avatar>
			<span class="text-primary">{{ trainer.name }}</span
			><br /><br />
			<ion-item>
				<ion-label>Select Type of Workout</ion-label>
				<ion-select
					placeholder="Select One"
					@ionChange="selectCategory()"
					v-model="selectedCategory"
				>
					<ion-select-option
						v-for="(categoryName, index) in categoryNames"
						:key="index"
						v-bind:value="categoryName"
						>{{ categoryName }}</ion-select-option
					>
				</ion-select>
			</ion-item>
			<br />
			<ion-item>
				<ion-label>Duration of Session</ion-label>
				<ion-select placeholder="Select One" v-model="selectedDuration">
					<ion-select-option :value="30">30 Minutes</ion-select-option>
					<ion-select-option :value="60">1 Hour</ion-select-option>
					<ion-select-option :value="90">1.5 Hours</ion-select-option>
				</ion-select>
			</ion-item>
			<br />
			<ion-item>
				<ion-label>Session Date</ion-label>
				<ion-datetime
					@IonChange="printDate()"
					v-model="bookingDate"
					:min="getDate()"
					:max="getMaxDate()"
					placeholder="Select Date"
				></ion-datetime>
			</ion-item>
			<br />
			<ion-item>
				<ion-label>Session Time</ion-label>
				<ion-datetime
					display-format="HH:mm"
					:hourValues="hourVals"
					:minuteValues="minuteVals"
					picker-format="HH:mm"
					@IonChange="printTime()"
					placeholder="Select Time"
					v-model="bookingTime"
				></ion-datetime>
				<!-- <ion-datetime :value="getDate()" @IonChange="printDate()" v-model="bookingTime" placeholder="Select Date"></ion-datetime> -->
			</ion-item>
			<br />
			<ion-item
				>Amount to Pay:
				{{ trainer?.defaultPrices?.video_call || amount }} FitCoins</ion-item
			>
			<br />
			<ion-button color="success" @click="accept()">Confirm Booking</ion-button>
			<div class="spacer"></div>
		</ion-content>
		<ion-content color="light" v-else>
			<div class="spin">
				<ion-spinner name="crescent"></ion-spinner>
			</div>
		</ion-content>

		<!-- date time in bottom sheet with custom buttons -->
	</div>
</template>

<script lang="js">
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonButton,
	IonButtons,
	IonSpinner,
	IonModal,
	IonDatetime,
	IonLabel,
	IonGrid,
	IonCol,
	IonRow,
	IonSelect,
	IonSelectOption
} from "@ionic/vue";
import { modalController, isPlatform } from "@ionic/vue";
import { DatePicker } from "v-calendar";
import { defineComponent, ref } from "vue";
// import TimeBooker  from "../../components/BookTime.vue"
import {
	star,
	starOutline,
	starHalf,
	thumbsDownOutline,
	thumbsUpOutline,
	trophyOutline,
	timerOutline,
	flameOutline,
	heartOutline,
} from "ionicons/icons";
import { ActionTypes as Coins } from "../../store/modules/coins";
import {ActionTypes as Bookings } from "../../store/modules/bookings";
import { store } from "@/store";
import { openToastOptionsPayment } from "../../utils/toast";
import moment from 'moment'
export default defineComponent({
	name: "VideoBookingModal",
	props: {
		trainer: { type: Object, default: null },
		amount: { type: Number, default: 20 },
	},

	setup() {
		// const store = useStore();
		const hourVals = [5, 6, 7, 8,9,10,11,12,13,14,15]
		const minuteVals = [0, 30]
		return {
			hourVals,
			minuteVals,
			modalController,
			star,
			starOutline,
			starHalf,
			thumbsDownOutline,
			thumbsUpOutline,
			trophyOutline,
			timerOutline,
			flameOutline,
			heartOutline,
		};
	},
	data() {
		return {
			content: "",
			utcDate: new Date(),
			bookingDate: null,
			bookingTime: null,
			selectedCategory: "",
			selectedDuration: 0,
			loading: false

		};
	},
	computed: {
		client() {
			return store.getters.client;
		},
		categoryNames(){
			return store.getters.getCategoryNames
		}
	},

	components: {
		IonContent,
		IonHeader,
		IonTitle,
		IonToolbar,
		IonButton,
		IonButtons,
		IonSpinner,
		IonDatetime,
		IonLabel,
		IonSelect,
	IonSelectOption
		// IonGrid,
		// IonCol,
		// IonRow
		// IonButtons,
		// IonLabel,
	},
	async beforeMount() {
		this.content = await store.getters.client.terms;
		this.id = await store.getters.client.id;

		// console.log(store.getters.getCategories);
	},
	methods: {
		// DEBUG METHODS
		// printDate(){
		// 	console.log(this.bookingDate)
		// },
		// printTime(){
		// 	console.log(this.bookingTime)
		// },
		// selectCategory(){
		// 	console.log("here")
		// 	// this.selectedCategory = event.detail.value
		// 	console.log(this.selectedCategory);
		// },

		//_________________________________________________
		getDate(){
			const today = moment(new Date()).toISOString();
			console.log(today)
			return today
		},

		getMaxDate(){
			const today = moment(new Date()).add(1, 'year').endOf('month').toISOString();
			console.log(today)
			return today
		},

		dateEntered(){
			if (this.bookingDate){
				return true
			}

			openToastOptionsPayment("Invalid Date", "Please Select a Date")

			return false
		},

		validDateTime(timeMoment) {
			if(!timeMoment.isBefore(moment(new Date()))){
				return true
			}

			openToastOptionsPayment("Invalid Selection", "Your chosen time has already passed, please try again")

			return false

		},

		timeEntered(){
			if (this.bookingTime){
				return true
			}

			openToastOptionsPayment("Invalid Time", "Please Select a Time")

			return false
		},

		categoryEntered(){
			if (this.selectedCategory !== ""){
				return true
			}

			openToastOptionsPayment("Invalid Category", "Please Select a Category")

			return false
		},

		durationEntered(){
			if (this.selectedDuration !== 0){
				return true
			}

			openToastOptionsPayment("Invalid Duration", "Please Select a Duration")

			return false
		},

		async accept() {
			this.loading = true;
			// console.log("SELECTED CATEGORY,", this.selectedCategory)
			const payload = {
				coach: this.trainer.id,
				amount: this.trainer.defaultPrices?.voice_call || this.amount,
				type: "Video_Call",
			};
			const validCategory = this.categoryEntered()

			if(validCategory){
				const validDuration = this.durationEntered()
				if(validDuration){
						const validDate = this.dateEntered()

			if(validDate){
				const validTime = this.timeEntered()
				// console.log(validDate, validTime)
				if(validDate && validTime) {
				const dateMoment = moment(this.bookingDate).format('YYYY-MM-DD')
				const timeMoment = moment(this.bookingTime).format('HH:mm')



				const bookingDateTime = moment(dateMoment + " " + timeMoment)

				if(this.validDateTime(bookingDateTime)){
					await store
				.dispatch(Coins.transferCoins, payload)
				.then(async ({ header, message, success, transactionID }) => {
					// console.log("toast");
					// console.log("transactionID",transactionID)
					if(success){
					const bookingInfo = {
						coach: this.trainer.id,
						user: store.getters.userProfile.uid,
						transactionID: transactionID,
						client: this.client.id,
						date: bookingDateTime.toISOString(),
						type: "one-on-one",
						trainerData: this.trainer,
						duration: this.selectedDuration,
						coachName: this.trainer.name,
						amount: payload.amount,

					}


					store.dispatch(Bookings.makeBooking, bookingInfo).then(async () => {
						this.loading = false
						await openToastOptionsPayment("Success", `Booking successfully set for ${dateMoment} at ${timeMoment}`);
					})
					} else {
						this.loading = false;
						await openToastOptionsPayment(header, message)
					}


				});

				this.loading = false
				await modalController.dismiss();
				}else {
					this.loading = false
				}


			// .catch(error => console.log('error: ', error));
			
			// console.log("transferred", payload);
				}
			}
				}

			}




			
		},
		async close() {
			await modalController.dismiss();
		},
	},
});
</script>
<style scoped>
.spacer {
	height: 10vh;
}

.spin {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100%;
}
</style>
