<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<ion-grid>
				<div class="main-container">
					<!-- Call Interface Container -->
					<div class="video-call-outer">
						<div class="video-call-wrapper">
							<!-- Top Bar -->
							<ion-row class="top-bar">
								<ion-col size="2">
									<ion-icon
										class="buttons"
										size="large"
										color="light"
										:icon="arrowBack"
										@click="leaveVideoCall()"
									></ion-icon>
								</ion-col>
								<ion-col size="8">
									<p class="ion-text-center" id="remote-caller-name">
										{{ remoteUser.name || "@RemoteCaller" }}
									</p>
									<!-- Timer -->
									<!-- <p class="ion-text-center text-light">00:00</p> -->
								</ion-col>
								<ion-col size="2" class="ion-text-right">
									<ion-icon
										class="buttons"
										size="large"
										color="light"
										:icon="chatbox"
									></ion-icon>
								</ion-col>
							</ion-row>
							<!-- Remote Player -->
							<div class="remote-player-container">
								<!-- <img :src="defaultAvatar" class="caller-img" /> -->
								<div id="remote-player"></div>
							</div>
							<!-- Local Player -->
							<div
								:class="
									isWeb
										? 'local-player-container'
										: 'local-player-container-mobile'
								"
							>
								<div id="local-player"></div>
							</div>
							<!-- Bottom Bar -->
							<ion-row class="bottom-bar">
								<ion-icon
									size="large"
									:icon="mic"
									color="light"
									@click="toggleMic()"
									v-if="micToggle"
									class="circle-icon buttons"
								></ion-icon>
								<ion-icon
									size="large"
									:icon="micOff"
									color="light"
									@click="toggleMic()"
									v-else
									class="circle-icon buttons"
								></ion-icon>
								<div
									class="join-btn buttons"
									v-if="!localJoined"
									@click="joinVideoCall()"
								>
									<ion-icon :icon="call" class=""></ion-icon>
									Join
								</div>
								<div class="end-btn buttons" v-else @click="endVideoCall()">
									<ion-icon :icon="call" class="" id="end-call-icon"></ion-icon>
									End
								</div>
								<ion-icon
									size="large"
									:icon="videocam"
									color="light"
									@click="toggleVideocam()"
									v-if="videocamToggle"
									class="circle-icon buttons"
								></ion-icon>
								<ion-icon
									size="large"
									:icon="videocamOff"
									color="light"
									@click="toggleVideocam()"
									v-else
									class="circle-icon buttons"
								></ion-icon>
							</ion-row>
						</div>
					</div>
				</div>
			</ion-grid>
		</ion-content>
	</ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonContent, IonPage, isPlatform, IonIcon } from "@ionic/vue";
import {
	arrowBack,
	chatbox,
	call,
	mic,
	micOff,
	videocam,
	videocamOff,
} from "ionicons/icons";
import { useRouter } from "vue-router";
import { store } from "../store";
import localStore from "../utils/localstore";
import { ActionTypes as Call } from "../store/modules/call";

import AgoraRTC from "agora-rtc-sdk-ng";
// import { RtcTokenBuilder, RtcRole } from "agora-access-token";

// Create Agora RTC Client
const AgoraRTCClient = AgoraRTC.createClient({
	mode: "rtc",
	codec: "vp8",
});

export default defineComponent({
	name: "VideoCall",
	components: {
		IonContent,
		IonPage,
		IonIcon,
	},

	setup() {
		const router = useRouter();
		const isAndroid = isPlatform("android");
		const isIOS = isPlatform("ios");
		// const mobileScreen = window.innerWidth < 500;

		return {
			router,
			isAndroid,
			isIOS,
			// mobileScreen,
			arrowBack,
			chatbox,
			call,
			mic,
			micOff,
			videocam,
			videocamOff,
		};
	},

	data() {
		return {
			defaultAvatar: "/assets/noavatar.jpg",
			uid: "",
			// Agora RTC Channel Options
			options: {
				appid: "",
				channel: "",
				token: "",
				uid: "",
			},
			// Local Tracks (Audio & Video)
			localTracks: {
				audioTrack: null,
				videoTrack: null,
			},
			micToggle: true,
			videocamToggle: true,
			localJoined: false,
			remoteJoined: false,
			// Remote user data
			remoteUser: {
				uid: "",
				name: "",
				imgUrl: "",
			},
		};
	},

	async beforeMount() {
		// Get uid
		this.uid = store.getters.userProfile.uid;
		this.options.uid = this.uid;

		// Get remote user and and call params
		const _callRoom = await store.getters.getCallRoom;
		console.log("CallRoom", _callRoom);
		this.options.appid = _callRoom.appid;
		this.options.channel = _callRoom.channel;
		this.options.token = _callRoom.token;

		const _remoteUser = await store.getters.getRemoteUser;
		console.log("RemoteUser", _remoteUser);
		this.remoteUser = _remoteUser;

		// Join call room
		// this.joinVideoCall();

		// Send notification to remote user
		// this.sendNotification();
	},

	methods: {
		async joinVideoCall() {
			// Join RTC channel
			await AgoraRTCClient.join(
				this.options.appid,
				this.options.channel,
				this.options.token,
				this.options.uid
			).then(() => {
				this.localJoined = true;
				// Send notification to remote user
				this.sendNotification();
			});

			// Get audio tracks from mic
			this.localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
			// Get video tracks from cam
			this.localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack();

			// Initialize Mic state
			this.localTracks.audioTrack.setEnabled(this.micToggle);
			// Initialize Vdeocam state
			this.localTracks.videoTrack.setEnabled(this.videocamToggle);

			// Play local video track
			const localPlayerContainer = document.getElementById("local-player");
			localPlayerContainer.style.width = "100%";
			localPlayerContainer.style.height = "100%";
			this.localTracks.videoTrack.play(localPlayerContainer);

			// Publish local tracks to the RTC channel
			await AgoraRTCClient.publish([
				this.localTracks.audioTrack,
				this.localTracks.videoTrack,
			]);

			// Event listener "user-published" -> when a user joins the channel
			AgoraRTCClient.on("user-published", async (user, mediaType) => {
				// Subscribe to remote user
				await AgoraRTCClient.subscribe(user, mediaType).then(() => {
					//
					this.remoteJoined = true;
				});

				if (mediaType === "video") {
					// Get remote video tracks
					const remoteVideoTrack = user.videoTrack;
					// Target template elelemt as container for playing remote video track
					const remotePlayerContainer =
						document.getElementById("remote-player");
					remotePlayerContainer.style.maxWidth = "100%";
					remotePlayerContainer.style.height = "100%";
					remotePlayerContainer.style.zIndex = "-1";
					// Play remote tracks
					remoteVideoTrack.play(remotePlayerContainer);
				}
				if (mediaType === "audio") {
					// Get remote audio track object
					const remoteAudioTrack = user.audioTrack;
					// Play remote audio track
					remoteAudioTrack.play();
				}

				//
				AgoraRTCClient.on("user-unpublished", (user) => {
					this.remoteJoined = false;
					// this.endVideoCall();
				});
			});
		},

		async sendNotification() {
			const fcmPayload = {
				id: this.options.channel,
				receiverId: this.remoteUser.uid,
				receiverAvatar: this.remoteUser.imgUrl,
				time: new Date(),
			};
			await store.dispatch(Call.sendNotification, fcmPayload);
		},

		toggleMic() {
			this.micToggle = !this.micToggle;
			if (this.localTracks.audioTrack)
				this.localTracks.audioTrack.setEnabled(this.micToggle);
		},

		toggleVideocam() {
			this.videocamToggle = !this.videocamToggle;
			if (this.localTracks.videoTrack)
				this.localTracks.videoTrack.setEnabled(this.videocamToggle);
		},

		async endVideoCall() {
			// Destroy local audio track
			this.localTracks.audioTrack.close();
			// Destroy local video track
			this.localTracks.videoTrack.close();
			// Leave channel
			await AgoraRTCClient.leave().then(() => {
				this.localTracks.audioTrack = null;
				this.localTracks.videoTrack = null;
				this.localJoined = false;
			});
		},

		leaveVideoCall() {
			if (this.localJoined) this.endVideoCall();
			// TO DO: IMPLEMENT STORE RESET PROPOERLY
			// store.dispatch(Call.reset, this.options.channel);
			this.$router.go(-1);
		},
	},

	computed: {
		isWeb() {
			return store.getters.isWeb;
		},
		userProfile() {
			const userProfileData = store.getters.userProfile;
			return userProfileData;
		},
	},
});
</script>

<style scoped>
ion-grid {
	padding: 0px;
}
.main-container {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
}
.top-bar {
	position: absolute;
	width: 100%;
	top: 0px;
	z-index: 1;
	padding-top: 10px;
}
#remote-caller-name {
	color: #555;
	font-size: 20px;
	margin-top: 5px;
}
.bottom-bar {
	position: absolute;
	width: 100%;
	height: 50px;
	z-index: 1;
	bottom: 10px;
	/* border: 1px solid #555; */
	justify-content: center;
	align-items: center;
}
.video-call-outer {
	width: 100vh;
	height: 100%;
}
.video-call-wrapper {
	position: relative;
	height: 100%;
	max-width: 1000px;
}
.remote-player-container {
	height: 100%;
	max-width: 100%;
	border: 1px solid #555;
	background-image: url("/assets/noavatar.jpg");
	background-size: cover;
	background-position: center;
}
.local-player-container {
	position: absolute;
	bottom: 65px;
	right: 20px;
	height: 150px;
	width: 150px;
	border: 1px solid #999;
	background-image: url("/assets/noavatar.jpg");
	background-size: cover;
	background-position: center;
}
.local-player-container-mobile {
	position: absolute;
	bottom: 65px;
	right: 15px;
	height: 150px;
	width: 110px;
	border: 1px solid #999;
	background-image: url("/assets/noavatar.jpg");
	background-size: cover;
	background-position: center;
}
.buttons {
	margin-left: 10px;
	margin-right: 10px;
}
.buttons:hover {
	cursor: pointer;
}
.join-btn {
	border-radius: 30px;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 8px;
	padding-bottom: 8px;
	background-color: green;
	text-align: center;
	align-items: center;
	justify-content: center;
}
.end-btn {
	border-radius: 30px;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 8px;
	padding-bottom: 8px;
	background-color: red;
	text-align: center;
	align-items: center;
	justify-content: center;
}
#end-call-icon {
	transform: rotate(180deg);
}
</style>
