import {toastController,} from "@ionic/vue";

export const openToastOptionsPayment = async (header: string, message: string) => {
    const toast = await toastController
        .create({
            header,
            message,
            position: 'top',
            duration: 3000,
            color: "light"
            // buttons: [
            //     {
            //         text: 'Done',
            //         role: 'cancel',
            //     }
            // ]
        });
    await toast.present();
    // const { role } = await toast.onDidDismiss();
//console.log('onDidDismiss resolved with role', role);
}