<template>
	<ion-page>
		<ion-header class="ion-no-border">
			<navbar />
		</ion-header>
		<ion-content :fullscreen="true">
			<Ranking/>
		</ion-content>
		<!--  -->
		<bottombar />
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonPage,
} from "@ionic/vue";
import { defineComponent, onBeforeMount } from "vue";
import Navbar from "@/components/Navbar.vue";
import Bottombar from "@/components/Bottombar.vue";
import {
	cameraOutline,
	searchOutline,
	personAddOutline,
	downloadOutline,
	trophy,
	shield,
} from "ionicons/icons";
import Ranking from '../components/Ranking.vue';

export default defineComponent({
	name: "LeaderBoard",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		Bottombar,
		Ranking
	},

	setup() {
		return {
			cameraOutline,
			searchOutline,
			personAddOutline,
			downloadOutline,
			trophy,
			shield,
		};
	},
});
</script>

<style scoped>
ion-card {
	box-shadow: 0px 0px 2px 4px rgba(255, 255, 255, 0.05);
}
.camerabtn {
	position: relative;
	top: -30px;
	right: -60px;
}
.settingsbtn {
	position: absolute;
	top: 0;
	right: 0;
	width: 50px;
}
.trophy {
	text-align: center;
	width: 40vw;
	margin: 0 auto;
}
</style>
