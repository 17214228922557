
import {
	IonContent,
	IonHeader,
	IonPage,
	IonCol,
	IonRow,
	IonGrid,
	IonButton,
	IonAvatar,
	IonToolbar,
	IonBackButton,
	IonButtons,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { store } from "../store";
import * as fb from "../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import Bottombar from "../components/Bottombar.vue";
import Navbar from "../components/Navbar.vue";
export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Bottombar,
		Navbar,
		IonCol,
		IonRow,
		IonGrid,
		IonBackButton,
		IonButtons,
	},
	setup() {
		let isWeb = false;
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			isWeb = false;
		} else {
			isWeb = true;
		}
		return {
			isWeb,
			IonButton,
			IonAvatar,
			IonToolbar,
		};
	},
	data() {
		return {
			termsAndConditions: undefined,
		};
	},
	beforeMount() {
		// Re-format Ts & Cs HTML
		const terms = store.getters.client.terms;
		this.termsAndConditions = terms
			.replaceAll("strong>", "h3>")
			.replaceAll("</div>", "</div><br />");
	},
	computed: {
		client() {
			// console.log(store.getters.client)
			return store.getters.client;
		},
	},
});
