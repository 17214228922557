import { ActionTree } from "vuex";
import { MutationTypes } from "./mutations";
import { Actions } from "./interfaces";
import { State } from "./index";
import { RootState } from "@/store";
import * as fb from "../../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import axios from "axios";
import { store } from "@/store";
import { ActionTypes as VideoPlayer } from "../video_player/actions";
import { ActionTypes as Content } from "../content/actions";
import { ActionTypes as Auth } from "../auth/actions";
import localStore from "@/utils/localstore";
import { GooglePlus } from "@ionic-native/google-plus";
import { isPlatform } from "@ionic/core";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
// import { Facebook, FacebookLoginResponse } from "@ionic-native/facebook";
import {
	SignInWithApple,
	AppleSignInResponse,
	AppleSignInErrorResponse,
	ASAuthorizationAppleIDRequest,
} from "@ionic-native/sign-in-with-apple";
import { toastController } from "@ionic/vue";
import { googleAuthPlugin } from "@/utils/utils";
import { checkUserExists } from "@/utils/authutils";

// Actions
// place the action definition as a string
// ==> { setX = "MODULE__SET_ X" }
export enum ActionTypes {
	setUserData = "SET_USER_DATA",
	loginUser = "LOGIN_USER_WITH_CREDENTIALS",
	updatePassword = "UPDATE_PASSWORD",
	updateUserProfile = "UPDATE_USER_PROFILE",
	signupUser = "SIGNUP_USER",
	socialAuth = "SOCIAL_AUTH",
	fetchClientData = "FETCH_CLIENT_DATA",
	userProfile = "GET_USER_PROFILE",
	updateSubscription = "UPDATE_SUBSCRIPTION",
	updateAboutUS = "UPDATE_ABOUTUS",
	updateSupport = "UPDATE_SUPPORT",
	setRecommendedChoices = "SET_RECOMMEND_ACTION",
	resetRecommendedChoices = "RESET_RECOMMENDED_ACTION",
	resetPassword = "RESET_PASSWORD",
	onboardUser = "ONBOARD_USER",
	addUserToken = "ADD_TOKEN",
	addXP = "ADD_EXPERIENCE_POINTS",
	sendXPNotification = "SEND_NOTIFICATION_WHEN_XP_ARE_EARNED",
	reset = "RESET",
	fetchUserXP = "FETCH_USER_EXPERIENCE_POINTS",
	updateFetchingProfile = "FETCHING_PROFILE_STATE",
	updateAuthState = "UPDATE_AUTH_STATE",
	googleAuth = "GOOGLE_AUTH",
}

// [C.3] declare actions
// [ActionTypes.setX]({ commit }, payload) {
//   commit(MutationTypes.X, payload);
// },

//TODO: Refactor the auth functions below to use a common login and sign up after doing what they need to for firebase auth

export const actions: ActionTree<State, RootState> & Actions = {
	[ActionTypes.updateAuthState]({ commit }, payload) {
		commit(MutationTypes.setAuthState, payload);
	},

	[ActionTypes.updateFetchingProfile]({ commit }, payload) {
		commit(MutationTypes.setFetchingProfile, payload);
	},

	// ADD EXPERIENCE POINTS TO XP SUB-COLLECTION
	async [ActionTypes.addXP]({ commit }, data) {
		// Get uid
		const uid = fb.auth.currentUser?.uid;
		// Add timestamp
		const payload = {
			...data,
			timestamp: firebase.firestore.Timestamp.now(),
		};
		// Make Firebase call to add XP points
		new Promise((resolve, reject) => {
			const docRef = fb.db.collection("users").doc(uid).collection("XP");
			docRef
				.add(payload)
				.then((res) => {
					store.dispatch(ActionTypes.sendXPNotification, { uid, data });
					resolve(res);
				})
				.catch((error) => reject(error));
		});
	},

	// SEND NOTIFICATION WHEN EXPERIENCE POINTS ARE EARNED
	async [ActionTypes.sendXPNotification](
		{ commit },
		payload: { uid: any; data: { name: string; value: number } }
	) {
		// Do a check for customizing the messages based on achievement
		let object: string;

		switch (payload.data.name) {
			case "SignUp":
				object = "signing up";
				break;

			case "BookmarkClass":
				object = "bookmarking a class";
				break;

			case "ScheduleClass":
				object = "scheduling a class";
				break;

			case "WatchClass":
				object = "watching a class";
				break;

			case "FinishClass":
				object = "finishing a class";
				break;

			case "RateClass":
				object = "rating a class";
				break;

			case "RateTrainer":
				object = "rating a trainer";
				break;

			default:
				object = "";
				break;
		}

		// const toast = await toastController.create({
		// 	header: "Achievement Earned",
		// 	message: `You've earned ${payload.data.value} XP for ${object}`,
		// 	position: "top",
		// 	duration: 2500,
		// 	color: "light",
		// });
		// return toast.present();

		// FCM Payload
		const fcmPayload = {
			id: "",
			senderId: payload.uid,
			receiverId: payload.uid,
			message: `You've earned ${payload.data.value} XP for ${object}`,
			time: new Date(),
		};

		//
		const receiverTokens = await firebase
			.firestore()
			.collection("users")
			.doc(fcmPayload.receiverId)
			.collection("fcmTokens")
			.get();

		if (receiverTokens) {
			receiverTokens.forEach((doc: any) => {
				const token = doc.data().token;

				if (token) {
					const toSend = {
						to: token,
						notification: {
							title: "Points Earned!",
							body: fcmPayload.message,
							mutable_content: true,
							icon: "",
							image: "",
						},
						android: {
							notification: {
								image: "",
							},
						},
						apns: {
							payload: {
								aps: {
									"mutable-content": 1,
								},
							},
							fcm_options: {
								image: "",
							},
						},
						webpush: {
							headers: {
								image: "",
							},
						},
						data: {
							imageUrl: "",
							body: fcmPayload.message,
						},
					};

					fetch("https://fcm.googleapis.com/fcm/send", {
						method: "POST",
						body: JSON.stringify(toSend),
						headers: {
							"Content-Type": "application/json",
							Authorization:
								"Bearer " + process.env.VUE_APP_FIREBASE_MESSAGING_TOKEN,
						},
					})
						.then((res) => res.json())
						.catch((error) => console.error("Error:", error))
						.then((response) => console.log("Success", response));
				}
			});
		}
	},

	// FETCH USER XP POINTS
	async [ActionTypes.fetchUserXP]({ commit }) {
		// Get uid
		const uid = fb.auth.currentUser?.uid;

		const userXP: firebase.firestore.DocumentData[] = [];

		const docRef = fb.db.collection("users").doc(uid).collection("XP");

		docRef.get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				userXP.push(doc.data());
			});

			commit(MutationTypes.getUserXP, {
				...userXP,
			});
		});
	},

	// SET USER DATA
	async [ActionTypes.setUserData]({ commit }, payload) {
		commit(MutationTypes.userProfile, payload);
	},
	// UPDATE USER PASSWORD
	async [ActionTypes.updatePassword]({ commit }, payload) {
		return new Promise((resolve, reject) => {
			fb.auth.currentUser
				?.updatePassword(payload)
				.then(() => resolve())
				.catch((err) => reject(err));
		});
	},
	// UPDATE USER PROFILE
	async [ActionTypes.updateUserProfile]({ commit }, payload) {
		const uid = payload.uid;

		// Remove uid from payload
		delete payload["uid"];

		console.log("UPDATE USER PROFILE PAYLOAD", JSON.stringify(payload));

		await new Promise((resolve, reject) => {
			fb.db
				.collection("users")
				.doc(uid)
				.update(payload)
				.then((res) => resolve(res))
				.catch((error) => reject(error));
		});
	},
	// UPDATE ABOUT US
	async [ActionTypes.updateAboutUS]({ commit }, payload) {
		const updateAboutUS = firebase.functions().httpsCallable("updateAboutUS");
		return new Promise((resolve, reject) => {
			updateAboutUS({ payload })
				.then(({ data }) => {
					commit(MutationTypes.setCurrentClient, data.data);
					resolve();
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	// UPDATE SUPPORT
	async [ActionTypes.updateSupport]({ commit }, payload) {
		const updateSupport = firebase.functions().httpsCallable("updateSupport");
		return new Promise((resolve, reject) => {
			updateSupport({ payload })
				.then(({ data }) => {
					commit(MutationTypes.setCurrentClient, data.data);
					resolve();
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	// UPDATE USER SUBSCRIPTION
	async [ActionTypes.updateSubscription]({ commit }, payload) {
		const userSubscribe = firebase.functions().httpsCallable("userSubscribe");
		return new Promise((resolve, reject) => {
			userSubscribe({ trial: payload })
				.then(({ data }) => {
					commit(MutationTypes.userProfile, data.data);
					resolve();
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	// USER LOGIN
	async [ActionTypes.loginUser]({ commit }, payload) {
		store.dispatch(ActionTypes.setUserData, undefined);
		store.dispatch(ActionTypes.updateFetchingProfile, true);
		return new Promise((resolve, reject) => {
			fb.auth
				.signInWithEmailAndPassword(payload.email, payload.password)
				.then((response: firebase.auth.UserCredential) => {
					// Store refreshtoken in the local storage
					payload.store.set("refreshtoken", response.user?.refreshToken);
					//Get User Object
					fb.users
						.doc(response.user?.uid)
						.get()
						.then(async (res) => {
							//Store user object from firebase in local storage
							//JSON.stringify as objects in the local storage have to be a string
							//When calling store.get('user'), make sure to JSON.parse the response
							const updatedUser = {
								...res.data(),
								uid: response.user?.uid,
							};

							store.dispatch(VideoPlayer.fetchWatchHistory, {
								userID: response.user?.uid,
							});
							store.dispatch(Content.fetchBookmarkedClasses, {
								userID: response.user?.uid,
							});

							const token = await localStore.get("fcmToken");
							if (token) {
								// console.log("DISPATCHING");
								store.dispatch(Auth.addUserToken, {
									userID: response.user?.uid,
									token: token,
								});
							}

							store
								.dispatch(ActionTypes.userProfile, response.user?.uid)
								.then(() =>
									store.dispatch(ActionTypes.updateFetchingProfile, false)
								);

							// console.log("user", updatedUser);
							payload.store.set("user", updatedUser);
							commit(MutationTypes.userProfile, updatedUser);
							resolve();
						})
						.catch((err) => reject(err));
				})
				.catch((err) => reject(err));
		});
	},
	// NEW USER SIGNUP
	async [ActionTypes.signupUser]({ commit }, payload) {
		store.dispatch(ActionTypes.setUserData, undefined);
		store.dispatch(ActionTypes.updateFetchingProfile, true);

		return new Promise((resolve, reject) => {
			firebase
				.auth()
				.createUserWithEmailAndPassword(payload.email, payload.password)
				.then(async (response: firebase.auth.UserCredential) => {
					// console.log("create user response", response);
					const id = response.user?.uid;

					// DISABLED UNTIL WE ARE ABLE TO CUSTOMIZE MESSAGES PER CLIENT
					// SEND EMAIL VERIFICATION LINK
					// response.user?.sendEmailVerification().then(() => {
					// console.log("Email Verification Link Sent!");
					// });

					fb.users
						.doc(id)
						.get()
						.then(async (res) => {
							const data = {
								clientId: process.env.VUE_APP_CLIENT_KEY,
								firebaseId: id,
								name: payload.name,
								email: payload.email,
								password: btoa(payload.password + "|OurLittleSecret"), //We should actually add the secret to the ENV along with updating the ENV on the CRM app.
							};
							const updatedUser = {
								...res.data(),
								uid: id,
								name: payload.name,
							};
							// ADD EXPERIENCE POINTS FOR SIGNING UP
							store
								.dispatch(ActionTypes.addXP, {
									name: "SignUp",
									value: 100,
								})
								.catch((error) => {
									console.log("Error adding XP!", error.message);
								});
							store
								.dispatch(ActionTypes.userProfile, id)
								.then(() =>
									store.dispatch(ActionTypes.updateFetchingProfile, false)
								);
							store.dispatch(VideoPlayer.fetchWatchHistory, {
								userID: id,
							});
							store.dispatch(Content.fetchBookmarkedClasses, {
								userID: id,
							});

							const token = await localStore.get("fcmToken");
							if (token) {
								// console.log("DISPATCHING");
								store.dispatch(Auth.addUserToken, { userID: id, token: token });
							}

							commit(MutationTypes.userProfile, updatedUser);
							payload.store.set("user", updatedUser);
							payload.store.set("refreshtoken", response.user?.refreshToken);

							await axios
								.post(process.env.VUE_APP_CRM_ENDPOINT, data)
								.then(async (res) => {
									console.log("sign up crm response");
								})
								.catch((err) => {
									console.log("sign up crm error");
									// throw err;
									reject(err);
								});
							if (updatedUser.uid) {
								while (!(await checkUserExists(updatedUser?.uid))) {
									await new Promise((resolve) => setTimeout(resolve, 750));
								}
								fb.users.doc(updatedUser.uid).get()
									.then(async (doc) => {
										// Update user data in firestore
										await fb.users
											.doc(doc.id)
											.update({
												...updatedUser,
											})
											.then(() => {
												console.log("UPDATED");
												resolve("user updated");
											})
											.catch((err) => {
												console.log(err);
												reject(err);
											});
									});
							} else {
								reject("No user ID");
							}

							// resolve(res);
						})
						.catch((err) => {
							console.log(err);
							// throw err;
							reject(err);
						});
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
	},

	async [ActionTypes.googleAuth]({ commit }, payload) {
		store.dispatch(ActionTypes.setUserData, undefined);
		store.dispatch(ActionTypes.updateFetchingProfile, true);
		let firebaseAuthCredential;
		const googleUser = await localStore.get('googleAccount');
		console.log('GOOOGLE USER', googleUser);

		console.log("SIQ BEFORE GOOOGLE USER");
		if(googleUser) {
			let refresh: any;
			console.log('HEREEEE');
			if(isPlatform('desktop') || isPlatform('mobileweb')) refresh = await googleAuthPlugin.refresh()
			console.log('refresh', refresh)
			console.log("SIQ GOOOGLE USER");
			return new Promise((resolve, reject) => {
				if(isPlatform('android') && !isPlatform('mobileweb')){
				firebaseAuthCredential = firebase.auth.GoogleAuthProvider.credential(
					googleUser.authentication.idToken
				);
				} else {
					console.log("IN ELSE")
					firebaseAuthCredential = firebase.auth.GoogleAuthProvider.credential(
						refresh.idToken
					);
				}

				console.log('SIQ FIREBAE AUTH CRED', firebaseAuthCredential)

				fb.auth
					.signInWithCredential(firebaseAuthCredential)
					.then(async (result) => {
						//now we have te user object
						console.log(result);

						const firebaseUser = result.user;

						localStore.set("refeshtoken", firebaseUser?.refreshToken);

						fb.users
					.doc(firebaseUser?.uid)
					.get()
					.then(async (res) => {
						const user = res.data();
						await store.dispatch(VideoPlayer.fetchWatchHistory, {
							userID: user?.uid,
						});
						await store.dispatch(Content.fetchBookmarkedClasses, {
							userID: user?.uid,
						});

						const token = await localStore.get("fcmToken");
						if (token) {
							store.dispatch(Auth.addUserToken, {
								userID: user?.uid,
								token: token,
							});
						}

						await store
							.dispatch(ActionTypes.userProfile, user?.uid)
							.then(() =>
								store.dispatch(ActionTypes.updateFetchingProfile, false)
							);

						if (user) {
							commit(MutationTypes.userProfile, user);
							resolve();
						} else {
							reject("No User Found");
						}
					})
					.catch((err) => {
						console.log(err);
						reject(err);
					});	
					})


			})
		}

		// googleAuthPlugin.refresh();
		console.log("SIQ HERE BEFORE SIGN IN")
			const {res, err} = await googleAuthPlugin
			.signIn()
			.then(response => {
				console.log("SIQ SIGN IN SUCCESS")
				localStore.set('googleAccount', response);
				return {res: response, err: false}
			})
			.catch((error) => {
				console.log('SIQ ERROR', error);
				return {res: error, err: true}
			});


		if(err){
			return new Promise((resolve, reject) => reject(res))
		}

		return new Promise((resolve, reject) => {
				// perform the sign in

		console.log("SIQ RESULT", JSON.stringify(res));

		//authenticate with firebase
		if(res){
			firebaseAuthCredential = firebase.auth.GoogleAuthProvider.credential(
				res.authentication.idToken
			);

			fb.auth
				.signInWithCredential(firebaseAuthCredential)
				.then(async (result) => {
					//now we have te user object
					console.log('result');
					console.log(result);

					const isNewUser = result.additionalUserInfo?.isNewUser;
					const firebaseUser = result.user;
					const googleProfile: any = result.additionalUserInfo?.profile;

					localStore.set("refeshtoken", firebaseUser?.refreshToken);

					//Handle Sign Up
					if (isNewUser) {
						fb.users
							.doc(firebaseUser?.uid)
							.get()
							.then(async (result) => {
								const firebaseData = result.data();
								const generatedName = (googleProfile?.name[0].toLowerCase() + googleProfile?.name.substring(1)).replace(/\s/g, '')
								const updatedUser = {
									...firebaseData,
									avatar: googleProfile?.picture,
									email: googleProfile?.email,
									name: googleProfile?.name,
									client: process.env.VUE_APP_CLIENT_KEY,
									uid: firebaseUser?.uid,
									userName:firebaseUser?.displayName ? firebaseUser?.displayName : generatedName
								};

								// IMPLEMENT THE OBJECT FOR USER DATA ON CRM
								const crmData = {
									clientId: process.env.VUE_APP_CLIENT_KEY,
									firebaseId: updatedUser.uid,
									name: updatedUser.name,
									email: updatedUser.email,
									password: "", //We should actually add the secret to the ENV along with updating the ENV on the CRM app.
								};

								// ADD EXPERIENCE POINTS FOR SIGNING UP
								store
									.dispatch(ActionTypes.addXP, {
										name: "SignUp",
										value: 100,
									})
									.catch((error) => {
										console.log("Error adding XP!", error.message);
									});

								// Get user profile
								await store
									.dispatch(ActionTypes.userProfile, updatedUser.uid)
									.then(() =>
										store.dispatch(ActionTypes.updateFetchingProfile, false)
									);
								// Get user watch history
								store.dispatch(VideoPlayer.fetchWatchHistory, {
									userID: updatedUser.uid,
								});
								// Get bookmarked classes
								store.dispatch(Content.fetchBookmarkedClasses, {
									userID: updatedUser.uid,
								});
								// Save firebase cloud messaging token
								const token = await localStore.get("fcmToken");
								if (token) {
									store.dispatch(Auth.addUserToken, {
										userID: updatedUser.uid,
										token: token,
									});
								}
								// Update user profile in state
								commit(MutationTypes.userProfile, updatedUser);
								localStore.set("user", updatedUser);

								await axios
									.post(process.env.VUE_APP_CRM_ENDPOINT, crmData)
									.then(async (res) => {
										console.log("sign up crm response");
									})
									.catch((err) => {
										console.log("sign up crm error");
										// throw err;
										reject(err);
									});

								if (updatedUser.uid) {
									while (!(await checkUserExists(updatedUser?.uid))) {
										await new Promise((resolve) => setTimeout(resolve, 750));
									}
									fb.users.doc(updatedUser.uid).get()
									.then(async (doc) => {
										// Update user data in firestore
										await fb.users
											.doc(doc.id)
											.update({
												...updatedUser,
											})
											.then(() => {
												console.log("UPDATED");
												resolve();
											})
											.catch((err) => {
												console.log(err);
												reject(err);
											});
									});
								} else {
									reject("No user ID");
								}
							})
							.catch((err) => {
								console.log(err);
								reject(err);
							}); // for the auth get call
					} else {
						fb.users
							.doc(firebaseUser?.uid)
							.get()
							.then(async (res) => {
								const user = res.data();
								await store.dispatch(VideoPlayer.fetchWatchHistory, {
									userID: user?.uid,
								});
								await store.dispatch(Content.fetchBookmarkedClasses, {
									userID: user?.uid,
								});

								const token = await localStore.get("fcmToken");
								if (token) {
									store.dispatch(Auth.addUserToken, {
										userID: user?.uid,
										token: token,
									});
								}

								await store
									.dispatch(ActionTypes.userProfile, user?.uid)
									.then(() =>
										store.dispatch(ActionTypes.updateFetchingProfile, false)
									);

								if (user) {
									commit(MutationTypes.userProfile, user);
									resolve();
								} else {
									reject("No User Found");
								}
							})
							.catch((err) => {
								console.log(err);
								reject(err);
							});
					}
				});
		}else {
			reject('NO AUTH TOKEN')
		}


		});

		// console.log("RESULT", JSON.stringify(result));
	},

	// SOCIAL AUTHENTICATION
	async [ActionTypes.socialAuth]({ commit }, payload) {
		let provider: any;

		switch (payload.provider) {
			case "facebook":
				provider = new firebase.auth.FacebookAuthProvider();
				provider.setCustomParameters({
					display: "popup",
				});
				// console.log(provider);
				provider.addScope("name");
				provider.addScope("email");
				break;
			case "apple":
				provider = new firebase.auth.OAuthProvider("apple.com");
				provider.addScope("email");
				provider.addScope("name");
				break;
			default:
				break;
		}

		store.dispatch(ActionTypes.setUserData, undefined);
		store.dispatch(ActionTypes.updateFetchingProfile, true);

		// const isSafari = navigator.vendor == "Apple Computer, Inc."
		// console.log("is safari", isSafari, "vendor", navigator.vendor)

		if (
			(isPlatform("ios") || isPlatform("android")) &&
			payload.provider == "facebook" &&
			!isPlatform("mobileweb")
		) {
			// Facebook login on mobile

			// if (isPlatform("android")) {
			// 	Facebook.setApplicationId("4407563792657014");
			// 	Facebook.setApplicationName("Platform Fit");
			// }

			return new Promise((resolve, reject) => {
				reject("NO Facebook Setup");
				// Facebook.login(['public_profile', 'email'])
				// 	.then((res: FacebookLoginResponse) => {
				// 		console.log('Logged into Facebook on IOS!', res);
				// 		// Facebook.logEvent(Facebook.EVENTS.EVENT_PARAM_SUCCESS);
				// 		// const response = {
				// 		// 	"status":"connected",
				// 		// 	"authResponse":{
				// 		// 		"data_access_expiration_time":"1645585602","accessToken":"EAAZBopZBovqnYBAM7ZCkMaCvmKDQS7DlOTA2PZCfCprMX5YbGHUjIdkAX7U2GMDQWOZBtdOx36djVoZAB7YZC06BQGSF69Kaz1Vv7n7G6Dr1okJY4NsN5D1ZC8oVmbjbEBYC1aSKtVBgBZCgdgr0awewGsDA8hoYKJua2m9aQwZCHa0MCxjApUPjCOCyELZCKycosdhowYekCVVIB6KXJyT3y6FXlbGbjZA2EdWwYa784EmyZAnzyN5ma75xZA",
				// 		// 		"userID":"10209077265286152",
				// 		// 		"expiresIn":"5183997"
				// 		// 	}
				// 		// }
				// 		// const profile = Facebook.getCurrentProfile();
				// 		// console.log("Get profile response", profile);
				// 		// resolve();

				// 		const facebookCredential = firebase.auth.FacebookAuthProvider
				// 			.credential(res.authResponse.accessToken);

				// 		firebase
				// 			.auth()
				// 			.signInWithCredential(facebookCredential)
				// 			.then(async (result) => {

				// 				// console.log('provider', provider, 'result', result)

				// 				const additionalUserInfo = result.additionalUserInfo;
				// 				// This gives you a Google Access Token. You can use it to access the Google API.
				// 				const credential = result.credential;
				// 				// const token = credential.accessToken;

				// 				// The signed-in user info.
				// 				const user = result.user;
				// 				// Store refreshtoken in the local storage
				// 				payload.store.set("refreshtoken", user?.refreshToken);

				// 				if (additionalUserInfo?.isNewUser) {
				// 					// Sign up

				// 					// console.log('new user')
				// 					const id = result.user?.uid;
				// 					const profile = additionalUserInfo?.profile;

				// 					await fb.users
				// 						.doc(id)
				// 						.get()
				// 						.then(async (res) => {
				// 							const data = {
				// 								...profile,
				// 								clientId: process.env.VUE_APP_CLIENT_KEY,
				// 								firebaseId: id,
				// 								name: result.user?.displayName,
				// 								password: "",
				// 							};

				// 							const updatedUser = {
				// 								...res.data(),
				// 								uid: id,
				// 								name: result.user?.displayName,
				// 							};
				// 							// ADD EXPERIENCE POINTS FOR SIGNING UP
				// 							store
				// 								.dispatch(ActionTypes.addXP, {
				// 									name: "SignUp",
				// 									value: 100,
				// 								})
				// 								.catch((error) => {
				// 									console.log("Error adding XP!", error.message);
				// 								});
				// 							// Get user profile
				// 							store
				// 								.dispatch(ActionTypes.userProfile, id)
				// 								.then(() =>
				// 									store.dispatch(ActionTypes.updateFetchingProfile, false)
				// 								);
				// 							// Get user watch history
				// 							store.dispatch(VideoPlayer.fetchWatchHistory, {
				// 								userID: id,
				// 							});
				// 							// Get bookmarked classes
				// 							store.dispatch(Content.fetchBookmarkedClasses, {
				// 								userID: id,
				// 							});
				// 							// Save firebase cloud messaging token
				// 							const token = await localStore.get("fcmToken");
				// 							if (token) {
				// 								store.dispatch(Auth.addUserToken, {
				// 									userID: id,
				// 									token: token,
				// 								});
				// 							}
				// 							// Update user profile in state
				// 							commit(MutationTypes.userProfile, updatedUser);

				// 							payload.store.set("user", updatedUser);
				// 							payload.store.set("refreshtoken", result.user?.refreshToken);

				// 							// Create user subscription in CRM
				// 							await axios
				// 								.post(process.env.VUE_APP_CRM_ENDPOINT, data)
				// 								.then((res) => {
				// 									console.log("signup crm response", res.data);
				// 								})
				// 								.catch((err) => {
				// 									console.log("signup crm error", err);
				// 								});

				// 						})
				// 						.catch((err) => {
				// 							console.log("social signup get user error", err);
				// 							reject(err)
				// 						});

				// 					// listen for changes on the users collection and update the user data
				// 					fb.users.doc(id)
				// 						.onSnapshot(async (doc) => {
				// 							// Update user data in firestore
				// 							await fb.users.doc(id).update({
				// 								client: process.env.VUE_APP_CLIENT_KEY,
				// 								...profile,
				// 							});
				// 						});

				// 					resolve();

				// 				} else {
				// 					// Sign in

				// 					// console.log('existing user')

				// 					fb.users
				// 						.doc(user?.uid)
				// 						.get()
				// 						.then(async (res) => {

				// 							const updatedUser = {
				// 								...res.data(),
				// 								uid: user?.uid,
				// 							};

				// 							await store.dispatch(VideoPlayer.fetchWatchHistory, {
				// 								userID: user?.uid,
				// 							});
				// 							await store.dispatch(Content.fetchBookmarkedClasses, {
				// 								userID: user?.uid,
				// 							});

				// 							const token = await localStore.get("fcmToken");
				// 							if (token) {
				// 								store.dispatch(Auth.addUserToken, {
				// 									userID: user?.uid,
				// 									token: token,
				// 								});
				// 							}

				// 							await store
				// 								.dispatch(ActionTypes.userProfile, user?.uid)
				// 								.then(() =>
				// 									store.dispatch(ActionTypes.updateFetchingProfile, false)
				// 								);

				// 							payload.store.set("user", updatedUser);
				// 							commit(MutationTypes.userProfile, updatedUser);
				// 							resolve();
				// 						})
				// 						.catch((err) => reject(err));

				// 				}
				// 			})
				// 			.catch(async (error) => {
				// 				// Handle Errors here.
				// 				const errorCode = error.code;
				// 				const errorMessage = error.message;
				// 				// The email of the user's account used.
				// 				const email = error.email;
				// 				// The AuthCredential type that was used.
				// 				const credential = error.credential;
				// 				const errObj = { errorCode, errorMessage, email, credential };
				// 				console.log("social auth error", errObj);
				// 				reject(error);
				// 			});
				// 	})
				// 	.catch(e => {
				// 		console.log('Error logging into Facebook on IOS', e);
				// 		reject(e);
				// 	});
			});
		} else if (
			isPlatform("ios") &&
			payload.provider == "apple" &&
			!isPlatform("mobileweb")
		) {
			// Apple login on mobile

			return new Promise((resolve, reject) => {
				SignInWithApple.signin({
					requestedScopes: [
						ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
						ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail,
					],
				})
					.then((res: AppleSignInResponse) => {
						// https://developer.apple.com/documentation/signinwithapplerestapi/verifying_a_user
						console.log('Send token to apple for verification: ' + res.identityToken);
						console.log("Apple login on IOS response", res);

						const credential = provider.credential({
							idToken: res.identityToken,
						});

						firebase
							.auth()
							.signInWithCredential(credential)
							.then(async (result) => {
								console.log(
									"apple provider",
									provider,
									"signInWithCredential result",
									result
								);



								const additionalUserInfo = result.additionalUserInfo;
								// This gives you a Google Access Token. You can use it to access the Google API.
								const credential = result.credential;
								// const token = credential.accessToken;

								// The signed-in user info.
								const user = result.user;
								// Store refreshtoken in the local storage
								payload.store.set("refreshtoken", user?.refreshToken);

								if (additionalUserInfo?.isNewUser) {
									// Sign up

									// console.log('new user')

									const id = result.user?.uid;
									const profile: any = additionalUserInfo?.profile;
									const email: string = profile.email
									const userName = email.substring(0, email.indexOf('@')).replace(/[\W_]+/g, "");

									await fb.users
										.doc(id)
										.get()
										.then(async (res) => {
											console.log("apple login result", res);

											const data = {
												clientId: process.env.VUE_APP_CLIENT_KEY,
												firebaseId: id,
												name: userName,
												password: "",
												email: profile.email
											};

											const updatedUser = {
												...res.data(),
												uid: id,
												name: userName,
												userName: userName,
												client: process.env.VUE_APP_CLIENT_KEY,
												email: profile?.email
											};
											// ADD EXPERIENCE POINTS FOR SIGNING UP
											store
												.dispatch(ActionTypes.addXP, {
													name: "SignUp",
													value: 100,
												})
												.catch((error) => {
													console.log("Error adding XP!", error.message);
												});
											// Get user profile
											store
												.dispatch(ActionTypes.userProfile, id)
												.then(() =>
													store.dispatch(
														ActionTypes.updateFetchingProfile,
														false
													)
												);
											// Get user watch history
											store.dispatch(VideoPlayer.fetchWatchHistory, {
												userID: id,
											});
											// Get bookmarked classes
											store.dispatch(Content.fetchBookmarkedClasses, {
												userID: id,
											});
											// Save firebase cloud messaging token
											const token = await localStore.get("fcmToken");
											if (token) {
												store.dispatch(Auth.addUserToken, {
													userID: id,
													token: token,
												});
											}
											// Update user profile in state
											commit(MutationTypes.userProfile, updatedUser);

											payload.store.set("user", updatedUser);
											payload.store.set(
												"refreshtoken",
												result.user?.refreshToken
											);

											// Create user subscription in CRM
											if (updatedUser.uid) {
												while (!(await checkUserExists(updatedUser?.uid))) {
													await new Promise((resolve) => setTimeout(resolve, 750));
												}
												fb.users.doc(updatedUser.uid).get()
													.then(async (doc) => {
														// Update user data in firestore
														await fb.users
															.doc(doc.id)
															.update({
																...updatedUser,
															})
															.then(() => {
																console.log("UPDATED");
																resolve();
															})
															.catch((err) => {
																console.log(err);
																reject(err);
															});
													});
											} else {
												reject('No User Found')
											}


										})
										.catch((err) => {
											console.log("social signup get user error (apple)", err);
											reject(err);
										});



									console.log("errthing guuuud (apple login)");

									resolve();
								} else {
									// Sign in

									// console.log('existing user')

									fb.users
										.doc(user?.uid)
										.get()
										.then(async (res) => {
											const updatedUser = {
												...res.data(),
												uid: user?.uid,
											};

											await store.dispatch(VideoPlayer.fetchWatchHistory, {
												userID: user?.uid,
											});
											await store.dispatch(Content.fetchBookmarkedClasses, {
												userID: user?.uid,
											});

											const token = await localStore.get("fcmToken");
											if (token) {
												store.dispatch(Auth.addUserToken, {
													userID: user?.uid,
													token: token,
												});
											}

											await store
												.dispatch(ActionTypes.userProfile, user?.uid)
												.then(() =>
													store.dispatch(
														ActionTypes.updateFetchingProfile,
														false
													)
												);

											payload.store.set("user", updatedUser);
											commit(MutationTypes.userProfile, updatedUser);
											resolve();
										})
										.catch((err) => reject(err));
								}
							})
							.catch(async (error) => {
								// Handle Errors here.
								const errorCode = error.code;
								const errorMessage = error.message;
								// The email of the user's account used.
								const email = error.email;
								// The AuthCredential type that was used.
								const credential = error.credential;
								const errObj = { errorCode, errorMessage, email, credential };
								console.log("social auth error (apple)", errObj);
								reject(error);
							});
					})
					.catch((error: AppleSignInErrorResponse) => {
						alert(error.code + " " + error.localizedDescription);
						console.error("Apple login on IOS error", error);
						reject(error);
					});
			});
		} else {
			// Other devices
			// Requires popups to be enabled in browser
			return new Promise((resolve, reject) => {
				firebase
					.auth()
					.signInWithPopup(provider)
					.then(async (result) => {
						console.log('provider', provider, 'result', result)

						const additionalUserInfo = result.additionalUserInfo;
						// This gives you a Google Access Token. You can use it to access the Google API.
						const credential = result.credential;
						// const token = credential.accessToken;

						// The signed-in user info.
						const user = result.user;
						// Store refreshtoken in the local storage
						payload.store.set("refreshtoken", user?.refreshToken);
						console.log("APPLE RESULT", result);

						console.log(result)

						if (additionalUserInfo?.isNewUser) {
							// Sign up

							// console.log('new user')
							const id = result.user?.uid;
							const profile: any = additionalUserInfo?.profile;
							const email: string = profile.email
							const userName = email.substring(0, email.indexOf('@')).replace(/[\W_]+/g, "");
							console.log('userName', userName)

							await fb.users
								.doc(id)
								.get()
								.then(async (res) => {
									const data = {
										clientId: process.env.VUE_APP_CLIENT_KEY,
										firebaseId: id,
										name: userName,
										password: "",
										email: profile.email
									};

									const updatedUser = {
										...res.data(),
										uid: id,
										name: userName,
										userName: userName,
										client: process.env.VUE_APP_CLIENT_KEY,
										email: profile.email
									};

									// ADD EXPERIENCE POINTS FOR SIGNING UP
									store
										.dispatch(ActionTypes.addXP, {
											name: "SignUp",
											value: 100,
										})
										.catch((error) => {
											console.log("Error adding XP!", error.message);
										});
									// Get user profile
									store
										.dispatch(ActionTypes.userProfile, id)
										.then(() =>
											store.dispatch(ActionTypes.updateFetchingProfile, false)
										);
									// Get user watch history
									store.dispatch(VideoPlayer.fetchWatchHistory, {
										userID: id,
									});
									// Get bookmarked classes
									store.dispatch(Content.fetchBookmarkedClasses, {
										userID: id,
									});
									// Save firebase cloud messaging token
									const token = await localStore.get("fcmToken");
									if (token) {
										store.dispatch(Auth.addUserToken, {
											userID: id,
											token: token,
										});
									}
									// Update user profile in state
									commit(MutationTypes.userProfile, updatedUser);

									payload.store.set("user", updatedUser);
									payload.store.set("refreshtoken", result.user?.refreshToken);

									// Create user subscription in CRM
									await axios
										.post(process.env.VUE_APP_CRM_ENDPOINT, data)
										.then((res) => {
											console.log("signup crm response", res.data);
										})
										.catch((err) => {
											console.log("signup crm error", err);
										});

									if (updatedUser.uid) {
										while (!(await checkUserExists(updatedUser?.uid))) {
											await new Promise((resolve) => setTimeout(resolve, 750));
										}
										fb.users.doc(updatedUser.uid).get()
											.then(async (doc) => {
												// Update user data in firestore
												await fb.users
													.doc(doc.id)
													.update({
														...updatedUser,
													})
													.then(() => {
														console.log("UPDATED");
														resolve();
													})
													.catch((err) => {
														console.log(err);
														reject(err);
													});
											});
									} else {
										reject('No User Found')
									}
								})
								.catch((err) => {
									console.log("social signup get user error", err);
									reject(err);
								});



							resolve();
						} else {
							// Sign in

							// console.log('existing user')

							fb.users
								.doc(user?.uid)
								.get()
								.then(async (res) => {
									const updatedUser = {
										...res.data(),
										uid: user?.uid,
									};

									await store.dispatch(VideoPlayer.fetchWatchHistory, {
										userID: user?.uid,
									});
									await store.dispatch(Content.fetchBookmarkedClasses, {
										userID: user?.uid,
									});

									const token = await localStore.get("fcmToken");
									if (token) {
										store.dispatch(Auth.addUserToken, {
											userID: user?.uid,
											token: token,
										});
									}

									await store
										.dispatch(ActionTypes.userProfile, user?.uid)
										.then(() =>
											store.dispatch(ActionTypes.updateFetchingProfile, false)
										);

									payload.store.set("user", updatedUser);
									commit(MutationTypes.userProfile, updatedUser);
									resolve();
								})
								.catch((err) => reject(err));
						}
					})
					.catch(async (error) => {
						// Handle Errors here.
						const errorCode = error.code;
						const errorMessage = error.message;
						// The email of the user's account used.
						const email = error.email;
						// The AuthCredential type that was used.
						const credential = error.credential;
						const errObj = { errorCode, errorMessage, email, credential };
						console.log("social auth error", errObj);
						reject(error);
					});
			});
		}
	},
	// GET CLIENT DATA
	async [ActionTypes.fetchClientData]({ commit }, payload) {
		// console.log("clientPayload", payload)
		//clear the error
		commit(MutationTypes.resetClientError, undefined);

		fb.clientCollection
			.doc(payload)
			.get()
			.then((documentSnapshot: firebase.firestore.DocumentSnapshot) => {
				const data = documentSnapshot.data();

				if (data) {
					delete data.categories;
				}

				// console.log("CLIENT",data);
				commit(MutationTypes.setCurrentClient, {
					...data,
					id: documentSnapshot.id,
				});
			})
			.catch((error: firebase.FirebaseError) => {
				commit(MutationTypes.setClientError, error); //commit the error to store
			});
	},
	// GET USER PROFILE
	async [ActionTypes.userProfile]({ commit }, payload) {
		// {uid, ...userData} {uid, userData :{ ... }}

		// const userDocumentSnapShot = await fb.users.doc(payload).get();
		// commit(MutationTypes.userProfile, {
		// 	...userDocumentSnapShot.data(),
		// 	uid: userDocumentSnapShot.id,
		// });

		// Get user data and listen for real-time updates in the doc
		const userDocumentSnapShot = await fb.users
			.doc(payload)
			.onSnapshot((doc) => {
				commit(MutationTypes.userProfile, {
					...doc.data(),
					uid: doc.id,
				});
			});
	},
	// SET RECOMMENDATIONS
	async [ActionTypes.setRecommendedChoices]({ commit }, payload) {
		// console.log("PAYLOAD", payload)
		// console.log("PAYLOAD DATA", payload.data)
		const classPreferences = {
			category: payload.categories.reduce(function (
				accumulator: any,
				currentValue: any
			) {
				return accumulator.concat({
					name: currentValue.name,
					categoryId: currentValue.categoryId,
				});
			},
				[]),
			duration: payload.durations,
		};

		return fb.users
			.doc(payload.uid)
			.update({ classPreferences: classPreferences })
			.then(async () => {
				await store.dispatch(ActionTypes.userProfile, payload.uid);
			});
	},
	// RESET USER PASSWORD
	async [ActionTypes.resetPassword]({ commit }, payload) {
		return new Promise((resolve, reject) => {
			// Look up for user email and check email_verified for social auth provider
			firebase
				.firestore()
				.collection("users")
				.where("email", "==", payload)
				.get()
				.then((querySnapshot) => {
					if (querySnapshot.size > 0) {
						querySnapshot.forEach((item) => {
							if (
								item.data().email_verified === "true" ||
								item.data().verified_email === true ||
								item.data().granted_scopes
							) {
								reject({
									message: "Passwords are not provided for social accounts",
								});
							} else {
								firebase
									.auth()
									.sendPasswordResetEmail(payload)
									.then(() => {
										// Password reset email sent
										resolve();
									})
									.catch((err) => {
										reject(err);
									});
							}
						});
					} else {
						reject({ message: "User not found" });
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	// ONBOARD USER
	async [ActionTypes.onboardUser]({ commit }, payload) {
		return new Promise((resolve, reject) => {
			fb.db
				.collection("users")
				.doc(payload.uid)
				.update({
					userName: payload.userName,
					location: payload.location,
				})
				.then(() => {
					resolve();
				})
				.catch((error) => reject(error));
		});
	},
	// RESET RECOMMENDATIONS
	async [ActionTypes.resetRecommendedChoices]({ commit }, payload) {
		const classPreferences = {
			category: null,
			duration: null,
		};
		await fb.users
			.doc(payload.uid)
			.update({ classPreferences: classPreferences })
			.then(async () => {
				await store.dispatch(ActionTypes.userProfile, payload.uid);
			});
	},
	// ADD USER TOKEN
	async [ActionTypes.addUserToken]({ commit }, payload) {
		const ref = fb.users.doc(payload.userID).collection("fcmTokens");

		ref.get().then((querySnapshot: firebase.firestore.QuerySnapshot) => {
			if (querySnapshot.docs.length > 0) {
				//check that we do not have the token already
				let found = false;

				querySnapshot.docs.every(
					(document: firebase.firestore.DocumentSnapshot) => {
						const data = document.data();

						if (data?.token && data.token === payload.token) {
							found = true;
							return false;
						}
					}
				);

				if (!found) {
					ref.add({ token: payload.token });
				}
			} else {
				ref.add({ token: payload.token });
			}
		});
	},

	// RESET
	async [ActionTypes.reset]({ commit }) {
		commit(MutationTypes.reset);
	},
};
