import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions, ActionTypes } from "./actions";
import { Store } from "./types";
import { State } from "./interfaces";
import { Module } from "vuex";
import { RootState } from "@/store";


const state: State = {
	chatRooms: [],
	messages: [],
	liveStreamId: "",
	liveStreamComments: [],
	liveStreamViewCount: 0,
	liveStreamLikeCount: 0,
	liveStreamCommentCount: 0,
};

// define and export the Chat module
const chatModule: Module<State, RootState> = {
	state,
	mutations,
	actions,
	getters,
};

export { State, ActionTypes, Store };
export default chatModule;
