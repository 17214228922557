
import {
	IonApp,
	IonRouterOutlet,
	loadingController,
	toastController,
	isPlatform,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { store } from "./store";
import { ActionTypes as Auth } from "./store/modules/auth";
import { ActionTypes as Global } from "./store/modules/global";
import { ActionTypes as Content } from "./store/modules/content";
import { ActionTypes as VideoPlayer } from "./store/modules/video_player";
import { ActionTypes as Trainer} from './store/modules/trainers';
import { mapGetters } from "vuex";
import { auth } from "./firebase";
import localStore from "./utils/localstore";
import { resetStore } from "./utils/utils";
import { useFavicon } from "@vueuse/core";
import { FCM } from "@capacitor-community/fcm";
import {
	PushNotifications,
	PushNotificationSchema,
} from "@capacitor/push-notifications";


export default defineComponent({
	name: "App",
	components: {
		IonApp,
		IonRouterOutlet,
	},
	data() {
		return {
			currentTheme: "" as string,
		};
	},

	setup() {
		const router = useRouter();
		// const store = useStore();
	},

	computed: {
		...mapGetters(["client"]),
	},
	watch: {
		client(newClient, oldClient) {
			const data = newClient;

			let theme = this.currentTheme;
			const storeTheme = newClient.mode;

			document.documentElement.style.setProperty(
				"--ion-color-primary",
				newClient.primary_color
			);
			document.documentElement.style.setProperty(
				"--ion-color-secondary",
				newClient.secondary_color
			);
			document.documentElement.style.setProperty(
				"--ion-color-tertiary",
				newClient.tertiary_color
			);
			document.documentElement.style.setProperty(
				"--ion-text-color",
				newClient.text_color
			);

			document.title = newClient.name;
			useFavicon(data.favIconLink);

			if (theme && theme === storeTheme) {
				// theme exists so we jsut use it
				document.body.classList.add(theme);
			}

			if (!theme) {
				//no theme saved so we pull it from teh client object
				//and save it
				theme = store.getters.client.mode == "dark" ? "dark" : "light";
				document.body.classList.add(theme);
				localStore.set("theme", theme);
			}
			if (theme && storeTheme !== theme) {
				//so we have a theme but it is not the same as the
				// live one
				document.body.classList.remove(theme);
				theme = storeTheme;
				// console.log("HERE")
				document.body.classList.add(theme);
				localStore.set("theme", theme);
			}

			store
				.dispatch(Content.setCategories)
				.then(() => store.dispatch(Content.setClasses))
				.then(() => store.dispatch(Trainer.fetchTrainers));
		},
	},

	mounted: async function () {
		let isWeb = false;
		let token = await localStore.get("fcmToken");
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			isWeb = false;
		} else {
			isWeb = true;
		}

		if (
			(isPlatform("android") || isPlatform("ios")) &&
			!isPlatform("mobileweb") &&
			!isPlatform("pwa")
		) {
			console.log("ABOUT TO DO PUSH THINGS")
			await PushNotifications.requestPermissions();
			await PushNotifications.register();

			//TODO: clean up this isntance later
			if (!token) {
				FCM.getToken()
					.then((r) => {
						// alert(`Token ${r.token}`);
						token = r.token;
						// console.log("WOO", r.token);
						localStore.set("fcmToken", token);
						FCM.setAutoInit({ enabled: true });
					})
					.catch((err) => console.log(err));
			}
			// console.log("WOO", token);

			PushNotifications.addListener(
				"pushNotificationReceived",
				async (notification: PushNotificationSchema) => {
					const toast = await toastController.create({
						header: notification.title,
						message: notification.body,
						position: "top",
						duration: 2000,
						color: "light",
					});
					return toast.present();
				}
			);
		}

		store.dispatch(Global.setPlatform, isWeb);

		let theme = await localStore.get("theme");
		let version = await localStore.get("version");
		await store.dispatch(Auth.fetchClientData, process.env.VUE_APP_CLIENT_KEY);
		const storeTheme = store.getters.client.mode;
		console.log(version);
		if (theme && theme === storeTheme) {
			// theme exists so we jsut use it
			document.body.classList.add(theme);
			this.currentTheme = theme;
		}

		if (!theme) {
			//no theme saved so we pull it from teh client object
			//and save it
			theme = store.getters.client.mode == "dark" ? "dark" : "light";
			document.body.classList.add(theme);
			localStore.set("theme", theme);
			this.currentTheme = theme;
		}
		if (theme && storeTheme !== theme) {
			//so we have a theme but it is not the same as the
			// live one
			document.body.classList.remove(theme);
			theme = storeTheme;
			document.body.classList.add(theme);
			localStore.set("theme", theme);
			this.currentTheme = theme;
		}

		if (version) {
			if (version != process.env.VUE_APP_VERSION) {
				console.log("You are running an old version, resetting store");

				const loading = await loadingController.create({
					message: "Loading...",
				});
				loading.present();

				await auth.signOut().then(() => {
					resetStore();
					this.$router.push("/");
				});
				loading.dismiss();
			}
		} else {
			version = process.env.VUE_APP_VERSION;
			console.log(version);
			localStore.set("version", version);
		}

		const user = await localStore.get("user");
		if (user) {
			const uid = user.uid;
			// console.log("UID", uid);
			await store.dispatch(Auth.userProfile, uid);
			store.dispatch(VideoPlayer.fetchWatchHistory, { userID: uid });
			store.dispatch(Content.fetchBookmarkedClasses, { userID: uid });
		}

		const data = store.getters.client;

		document.documentElement.style.setProperty(
			"--ion-color-primary",
			store.getters.client.primary_color
		);
		document.documentElement.style.setProperty(
			"--ion-color-secondary",
			store.getters.client.secondary_color
		);
		document.documentElement.style.setProperty(
			"--ion-color-tertiary",
			store.getters.client.tertiary_color
		);
		document.documentElement.style.setProperty(
			"--ion-text-color",
			store.getters.client.text_color
		);

		document.title = this.client.name;
		useFavicon(data.favIconLink);
	},
});
