import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions, ActionTypes } from "./actions";
import { Store } from "./types";
import { State } from "./interfaces";
import { Module } from "vuex";
import { RootState } from "@/store";

// DECLARE STATE OBJECT
const state: State = {
	leaderboardDay: [],
	leaderboardWeek: [],
	leaderboardMonth: [],
	leaderboardAllTime: [],
};

// DEFINE LEADERBOARD MODULE
const leaderboard: Module<State, RootState> = {
	state,
	mutations,
	actions,
	getters,
};

export { State, ActionTypes, Store };

// EXPORT LEADERBOARD MODULE
export default leaderboard;
