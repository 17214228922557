
import {
	IonTabBar,
	IonTabButton,
	IonLabel,
	IonButton,
	IonToolbar,
	IonAvatar,
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
	personCircle,
	playCircleOutline,
	starSharp,
	menuOutline,
	trophyOutline,
} from "ionicons/icons";
import { RouteRecordName, useRouter } from "vue-router";
import { store } from "../store";
export default defineComponent({
	name: "Bottombar",
	components: {
		IonToolbar,
		IonButton,
	},
	// props:{
	// 	page: {type: String, default: ""}
	// },
	data() {
		return {
			bottomNav: ["FEATURED", "CLASSES", "PROFILE"],
			bottomIcons: [starSharp, playCircleOutline, personCircle],
			pageName: "" as RouteRecordName | undefined | null,
		};
	},

	beforeMount: function () {
		this.pageName = this.$route.name;
		console.log("Checking Page Name", this.pageName)
	},

	setup() {
		return {
			personCircle,
			playCircleOutline,
			starSharp,
			trophyOutline,
			menuOutline,
			IonAvatar,
		};
	},

	methods: {
		// Go to page
		goToPage(pagePath: string) {
			// console.log("Go to", pagePath);
			this.$router.push(`/${pagePath}`);
		},
	},

	computed: {
		isWeb() {
			return store.getters.isWeb;
		},
	},
});
