<template>
	<ion-page>
		<ion-header class="ion-no-border">
			<navbar v-if="isWeb" />
		</ion-header>

		<ion-content :fullscreen="true">
			<div class="page">
				<div color="light" class="onboarding-card" v-if="step1">
					<div class="text-center">
						<div color="dark" style="padding: 20px">
							<img style="" :src="client.logo" />
						</div>
						<h1 style="margin-top: 20px; margin-bottom: 10px">
							Complete your Profile!
						</h1>
						You're almost there! You just need to complete your profile
						<ion-avatar style="width: 100px; height: 100px; margin: 20px auto">
							<img ref="UserAvatar" :src="UserPhoto" />
							<ion-button
								color="medium"
								shape="round"
								size="small"
								class="camerabtn"
								@click="takePhoto()"
							>
								<ion-icon size="small" :icon="cameraOutline"></ion-icon>
							</ion-button>
						</ion-avatar>
						<br />
						<ion-item>
							<ion-label position="stacked"
								>This is your name within the app
							</ion-label>
							<ion-input
								type="text"
								cleat-on-edit="true"
								color="dark"
								placeholder="Username (required)"
								autocomplete="off"
								required
								v-model="payload.userName"
								v-bind:value="payload.userName"
								v-on:ionInput="payload.userName = $event.target.value"
							></ion-input>
						</ion-item>
						<p class="text-danger">{{ invalidUserName }}</p>
						<br />
						<ion-item>
							<ion-label position="stacked"
								>Use this to tell others where you are</ion-label
							>
							<ion-input
								color="dark"
								placeholder="Location"
								autocomplete="off"
								type="text"
								v-model="payload.location"
								v-bind:value="payload.location"
								v-on:ionInput="payload.location = $event.target.value"
							></ion-input>
						</ion-item>
						<br />
						<ion-button
							v-if="isWeb"
							color="primary"
							size="block"
							@click="getstarted()"
							>Choose Plan</ion-button
						>
						<ion-button
							v-else
							color="primary"
							size="block"
							@click="getstarted()"
							>Get Connected</ion-button
						>
					</div>
				</div>

				<ion-card
					color="light"
					v-if="isMobile && step2"
					class="onboarding-card"
				>
					<ion-card-content class="text-center">
						<div class="healthlogo">
							<img
								style="width: 40vw"
								v-if="platform.includes('ios')"
								src="/assets/applehealthlogo.png"
							/>
							<img
								style="width: 40vw"
								v-if="platform.includes('android')"
								src="/assets/googlehealthlogo.png"
							/>
						</div>
						<h1 style="margin-top: 10vh; margin-bottom: 10px">
							Sync <span v-if="platform.includes('ios')">Apple Health</span
							><span v-if="platform.includes('android')">Google Fit</span>!
						</h1>

						Sync your workouts to your
						<span v-if="platform.includes('ios')">Apple Health</span
						><span v-if="platform.includes('android')">Google Fit</span> App,
						where they will appear as part of your daily actvity.
						<div class="btns">
							<ion-button @click="connectHealthApp()" size="block"
								>Connect Health App</ion-button
							>
							<ion-button @click="subscription()" size="small" fill="outline"
								>Choose Plan</ion-button
							>
						</div>
					</ion-card-content>
				</ion-card>
				<!-- Complete your Profile -->

				<ion-grid>
					<ion-row>
						<ion-col> </ion-col>
					</ion-row>
				</ion-grid>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { cameraOutline } from "ionicons/icons";
import { getPlatforms, isPlatform, loadingController } from "@ionic/vue";
import { key, store } from "../store";
import * as fb from "../firebase";
import { getBlobfromImg } from "../utils/utils";
import { Health } from "@ionic-native/health";
// import { usePhotoGallery, UserPhoto } from "@/composables/usePhotoGallery";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonRow,
	IonCol,
	IonGrid,
	alertController,
} from "@ionic/vue";
import { defineComponent, onBeforeMount, ref } from "vue";
import {
	Camera,
	CameraResultType,
	CameraSource,
	Photo,
} from "@capacitor/camera";
// import { Filesystem, Directory } from "@capacitor/filesystem";
// import { Storage } from "@ionic/storage";
import { ActionTypes as Auth } from "../store/modules/auth";
import localStore from "../utils/localstore";
import { ActionTypes as HealthActions } from "../store/modules/health";

export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		IonRow,
		IonCol,
		IonGrid,
	},
	setup() {
		return { cameraOutline };
	},
	data() {
		const platform = getPlatforms();

		return {
			platform,
			UserPhoto: "/assets/noavatar.jpg",
			step1: true,
			step2: false,
			payload: {
				userName: "",
				location: "",
			},
			isMobile: false,
		};
	},
	mounted() {
		const userProfile = store.getters.userProfile;
		this.payload.userName = userProfile.userName;
		this.payload.location = userProfile.location;
		this.isMobile = isPlatform("android") || isPlatform("ios");
	},
	methods: {
		resetFields() {
			this.payload.userName = "";
			this.payload.location = "";
			this.UserPhoto = "/assets/noavatar.jpg";
		},
		async takePhoto() {
			const cameraPhoto = await Camera.getPhoto({
				resultType: CameraResultType.Uri,
				source: CameraSource.Prompt,
				webUseInput: true,
				quality: 80,
				allowEditing: false,
			})
				.then((image) => {
					// console.log("THIS IS THE IMAGE",image)
					return image;
				})
				.catch((err) => console.log("ERROR IN GETTING IMAFGE", err));
			const fileName = new Date().getTime() + ".jpeg";
			const savedFileImage = {
				filepath: fileName,
				webviewPath: cameraPhoto.webPath,
			};
			if (savedFileImage.webviewPath)
				this.UserPhoto = savedFileImage.webviewPath;
			//
			this.UserPhoto = savedFileImage.webviewPath;
			this.$refs.UserAvatar.src = savedFileImage.webviewPath;
			this.$refs["UserAvatar"].src = savedFileImage.webviewPath;

			this.updateAvatar();
		},

		// Update Avatar
		async updateAvatar() {
			const loading = await loadingController.create({
				message: "Please wait...",
			});

			await loading.present();

			// Get User ID
			// const user = await localStore.get("user");
			// const uid = user.uid;
			const uid = fb.auth.currentUser.uid;

			const updatedAvatar = { avatar: "" };

			if (this.UserPhoto && this.UserPhoto != this.userProfile.avatar) {
				const blob = await getBlobfromImg(this.UserPhoto);
				const storageRef = fb.storage.ref();
				const fileRef = storageRef.child("profile_images/" + uid + ".jpg");
				const fileSnapshot = await fileRef.put(blob);
				const url = await fileSnapshot.ref.getDownloadURL();
				updatedAvatar["avatar"] = url;
			} else if (!this.userPhoto && this.userProfile.avatar != "") {
				const storageRef = fb.storage.ref();
				const fileRef = storageRef.child("profile_images/" + uid + ".jpg");
				await fileRef.delete();
				updatedAvatar["avatar"] = "";
			}

			// Update Avatar in Firebase
			const userDocumentSnapShot = await fb.users
				.doc(uid)
				.update(updatedAvatar);

			// // Update Store
			const updateStore = await store
				.dispatch(Auth.userProfile, uid)
				.then(() => true)
				.catch((error) => {
					console.log(error);
				});

			loading.dismiss();
			console.log("Updated", updatedAvatar);
		},

		validUsername() {
			if (
				this.payload.userName &&
				this.payload.userName !== "" &&
				this.payload.userName.length >= 3
			) {
				return true;
			}

			return false;
		},
		async getstarted() {
			// Get User ID
			const uid = store.getters.userProfile?.uid;

			// Loading Controller
			const loading = await loadingController.create({
				message: "Please wait...",
			});

			// Updating username & location
			if (!this.validUsername()) {
				const alert = await alertController.create({
					header: "Invalid Username",
					message: "Username needs to be at least 3 characters long",
					buttons: ["OK"],
				});
				alert.present();
			}

			if (this.validUsername()) {
				await loading.present();
				this.payload.userName = this.payload.userName.toLowerCase();

				// prevent a null field
				if (!this.payload.location) {
					this.payload.location = "";
				}

				// Update fields in Firebase
				const userDocumentSnapShot = await fb.users
					.doc(uid)
					.update(this.payload);
				// Update Store
				const updateStore = await store
					.dispatch(Auth.userProfile, uid)
					.then(() => true)
					.catch((error) => {
						console.log(error);
					});

				loading.dismiss();

				if (isPlatform("ios") || isPlatform("android")) {
					this.step1 = false;
					this.step2 = true;
				} else {
					this.resetFields();
					this.$router.replace("/subscribe");
				}
			}
		},
		async connectHealthApp() {
			if (isPlatform("android") || isPlatform("ios")) {
				const loading = await loadingController.create({
					message: "Connecting...",
				});
				loading.present();

				await store.dispatch(HealthActions.connectHealthApp);
				await store.dispatch(HealthActions.checkPermissions);

				loading.dismiss();
				this.subscription();
			}
		},
		async subscription() {
			this.resetFields();
			this.$router.replace("/subscribe");
		},
	},
	computed: {
		userProfile() {
			const userProfileData = store.getters.userProfile;
			console.log("Profile View", userProfileData);
			return userProfileData;
		},
		isWeb() {
			return store.getters.isWeb;
		},
		client() {
			return store.getters.client;
		},
	},
});
</script>

<style scoped>
/* ion-button {
  --background-hover: #000;
  --background-hover-opacity: 1;
  float:right;
} */

ion-button {
	margin-bottom: 50px;
}

.onboarding-card {
	box-shadow: 0px 0px 2px 4px rgba(255, 255, 255, 0.05);
}

ion-card {
	box-shadow: 0px 0px 2px 4px rgba(255, 255, 255, 0.05);
}

.healthlogo {
	margin-top: 15vh;
}

.camerabtn {
	position: relative;
	top: -20px;
	right: -20px;
}
</style>
