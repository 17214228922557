import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions, ActionTypes } from "./actions";
import { Module } from "vuex";
import { RootState } from "@/store";
import { Store } from "./types";
import { State } from "./interfaces";

const state: State = {
	userHealthData: {
		height: null,
		weight: null,
		gender: "",
		heartRate: null,
		dob: "",
		activity: "",
		activityStart: "",
		activityEnd: "",
		caloriesBurnt: null,
		permissionsGranted: false,
		healthAppConnected: false,
		unAuthorized: false
	},
};

//define and export the module
const healthModule: Module<State, RootState> = {
	state,
	mutations,
	actions,
	getters,
};

export { State, ActionTypes, Store };
export default healthModule;
