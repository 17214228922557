import { MutationTree } from "vuex";
import { State } from "./index";
import { Mutations } from "./types";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";

// (B) - mutations
// [B.1] define mutation types
// ==> { X = "SET_ X" }
export enum MutationTypes {
    reset = "RESET"
}

// Define Actual Mutaion
export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.reset](state) {
        state.balance = 0;
    },
};
