import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions, ActionTypes } from "./actions";
import { Module } from "vuex";
import { RootState } from "@/store";
import { ClassData } from "@/interfaces/contentStore";
import { ClassProgress, UserWatching } from "@/interfaces/videoPlayerStore";
import { State } from "./interfaces";
import { Store } from "./types";

//State initial values
const state: State = {
	currentVideo: {} as ClassData,
	currentVideoRecord: {},
	usersWatching: [] as UserWatching[],
	userClassProgress: [] as ClassProgress[],
};

//define and export the module
const videoPlayerModule: Module<State, RootState> = {
	state,
	mutations,
	actions,
	getters,
};

export { State, ActionTypes, Store };
export default videoPlayerModule;
