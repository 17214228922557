<template>
	<ion-content
		:color="client?.mode != 'dark' ? 'dark' : 'light'"
		class="ion-padding text-center"
	>
		<ion-grid>
			<ion-row>
				<ion-col>
					<h2>{{ title }}</h2>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col> <br /><br /> </ion-col>
			</ion-row>
			<ion-row class="mobile-only">
				<ion-col> <br /><br /> </ion-col>
			</ion-row>
			<ion-row>
				<ion-col>
					How would you rate {{ currentTrainer.name }}?<br /><br />
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col>
					<ion-icon
						button
						@click="createStars(1)"
						color="primary"
						:icon="stars[0]"
					></ion-icon
					><br />1
				</ion-col>

				<ion-col>
					<ion-icon
						button
						@click="createStars(2)"
						color="primary"
						:icon="stars[1]"
					></ion-icon
					><br />2
				</ion-col>
				<ion-col>
					<ion-icon
						button
						@click="createStars(3)"
						color="primary"
						:icon="stars[2]"
					></ion-icon
					><br />3
				</ion-col>
				<ion-col>
					<ion-icon
						button
						@click="createStars(4)"
						color="primary"
						:icon="stars[3]"
					></ion-icon
					><br />4
				</ion-col>
				<ion-col>
					<ion-icon
						button
						@click="createStars(5)"
						color="primary"
						:icon="stars[4]"
					></ion-icon
					><br />5
				</ion-col>
			</ion-row>

			<ion-row class="centerLabel">
				<ion-textarea
					v-model="review"
					class="ratingTextBox"
					aria-placeholder="Leave a review..."
				>
				</ion-textarea>
			</ion-row>

			<ion-row>
				<ion-col>
					<ion-button
						@click="updateRating()"
						:color="client?.mode != 'dark' ? 'light' : 'dark'"
						size="block"
						v-if="!loading"
						>Done</ion-button
					>

					<ion-button
						@click="updateRating()"
						disabled
						:color="client?.mode != 'dark' ? 'light' : 'dark'"
						size="block"
						v-if="loading"
					>
					<ion-label > <ion-spinner color="primary"></ion-spinner></ion-label>
					</ion-button>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-content>
</template>

<script>
import {
	IonContent,
	IonCol,
	IonRow,
	IonIcon,
	IonGrid,
	IonSpinner,
		IonLabel,
	// IonHeader,
	// IonTitle,
	// IonToolbar,
	// IonButtons,
	// IonInput,
	IonButton,
	// IonLabel,
	IonTextarea,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import { star, starOutline, starHalf } from "ionicons/icons";
import { ActionTypes as Trainer } from "../../store/modules/trainers";
import { ActionTypes as Content } from "../../store/modules/content";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
//
import { store } from "../../store";
import { ActionTypes as Auth } from "../../store/modules/auth";

export default defineComponent({
	name: "TrainerModal",
	props: {
		trainer: { default: "" },
		user: { default: "" },
		title: { default: "" },
		client: { default: "" },
	},
	setup() {
		return {
			star,
			starOutline,
			starHalf,
		};
	},
	computed: {
		currentTrainer() {
			const trainer = store.getters.currentTrainer;
			return trainer;
		},
	},
	data() {
		return {
			// content: 'Content',
			rating: 0,
			review: "",
			stars: [starOutline, starOutline, starOutline, starOutline, starOutline],
			loading: false,
		};
	},
	components: {
		IonContent,
		IonCol,
		IonRow,
		IonIcon,
		IonGrid,
		IonSpinner,
		IonLabel,
		// IonHeader,
		// IonTitle,
		// IonToolbar,
		// IonButtons,
		IonButton,
		// IonLabel,
		IonTextarea,
		// IonInput
	},
	methods: {
		async close() {
			console.log("closing");
			await modalController.dismiss();
		},

		async updateRating() {
			const rateTrainer = firebase.functions().httpsCallable("rateTrainer");
			this.loading = true;
			await rateTrainer({
				rating: this.rating,
				trainer: this.trainer,
				review: this.review,
			})
				.then(async (res) => {
					// console.log(res, "Rating added");
					await store.dispatch(Content.setClasses);
					await store.dispatch(Trainer.fetchTrainers);
					const current = store.getters.getTrainer(this.trainer);
					// console.log("CURRENT", current);
					await store.dispatch(Trainer.selectCurrentTrainer, current);

					// console.log("DONE");

					// ADD EXPERIENCE POINTS FOR RATING A TRAINER
					store
						.dispatch(Auth.addXP, { name: "RateTrainer", value: 20 })
						.catch((error) => {
							console.log("Error adding XP!", error.message);
						});
				})
				.catch((err) => console.log(err, "error occured in rating"));
			// console.log("trainer modal: ", this.trainer);
			// return result;
			this.loading = false;
			await modalController.dismiss();
		},

		createStars(rating) {
			this.rating = rating;
			const unrated = Math.floor(5 - rating);
			// console.log("rating", rating);
			// console.log(unrated);
			const stars = [];
			while (rating >= 1) {
				rating--;
				stars.push(star);
			}
			if (rating != 0) {
				if (rating >= 0.3) {
					stars.push(starHalf);
				} else {
					stars.push(starOutline);
				}
			}

			for (let i = 0; i < unrated; i++) {
				stars.push(starOutline);
			}
			// console.log(stars);
			this.stars = stars;
		},

		getIcon(ratingValue) {
			return ratingValue === this.rating ? star : starOutline;
		},
	},
});
</script>
<style scoped>
.ratingTextBox {
	/* width: 90vw;
		height: 15vh; */
	border: 1px solid white;
}

.centerLabel {
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	/* padding-top: 2vh; */
}

</style>
