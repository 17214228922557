import { MutationTree } from "vuex";
import { State } from "./index";
import { Mutations } from "./types";

// (B) - mutations
// [B.1] define mutation types
// ==> { X = "SET_ X" }
export enum MutationTypes {
	SET_FOLLOWING = "SET_FOLLOWING",
	SET_FOLLOWERS = "SET_FOLLOWERS",
	reset = "RESET",
}

// [B.3] implement mutation
// ==>  [MutationTypes.X](state, payload) {
// ==>    state.x = payload;
// ==>  },
/* tslint:disable:no-empty */
export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_FOLLOWING](state, payload) {
		state.following = payload;
	},

	[MutationTypes.SET_FOLLOWERS](state, payload) {
		state.followers = payload;
	},

	[MutationTypes.reset](state) {
		state.followers = [];
		state.following = [];
	},
};
