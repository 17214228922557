import { getters,  } from "./getters";
import { mutations, } from "./mutations";
import { actions, ActionTypes } from "./actions";
// import {
// 	Store as VuexStore,
// 	Module,
// 	CommitOptions,
// 	DispatchOptions,
// } from "vuex";
import { Store } from "./types";
import { State } from "./interfaces";
import { Module } from "vuex";
import { RootState } from "@/store";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";

// TO MODIFY THE STORE
// (A) - Change state  ./
// (B) - Change mutations ./mutations.ts
// (C) - Modify actions ./actions.ts
// (D) - Modify getters ./getters.ts

//[A.1] Include the value in the state interface
// ==> x: TYPE_OF_X
// interface State {
// 	//other state typings
// 	balance: number;
// }

//[A.2] declare the state object
// ==> x: VALUE_OF_X
const state: State = {
	balance: 0
};

//define and export the module
const coinModule: Module<State, RootState> = {
	state,
	mutations,
	actions,
	getters,
};

export { State, ActionTypes, Store };
export default coinModule;
