<template>
	<ion-content color="light" class="ion-padding">
		<ion-card>
			<ion-card-header>
				<ion-toolbar color="light">
					<ion-title class="text-md">Search By Username</ion-title>
					<ion-buttons slot="end">
						<ion-button @click="close()">X</ion-button>
					</ion-buttons>
				</ion-toolbar>
			</ion-card-header>
			<ion-card-content>
				<div>
					<label>Enter full username:</label>
					<ion-input
						type="text"
						v-model="searchValue"
						:value="searchValue"
						style="border: 1px solid gray"
					></ion-input>
					<br />
					<ion-button @click="searchUser()">Search</ion-button>
				</div>
			</ion-card-content>
			<ion-card-content>
				<div >
					<h2>Results</h2>
					<br />
					<ion-grid>
						<ion-row>
							<ion-col
								v-for="result in searchResults"
								:key="result.userName"
								class="full-size"
							>
								<ion-item color="light">
									<ion-avatar slot="start">
										<img
											:src="result.avatar"
											v-if="result.avatar && result.avatar !== ''"
										/>
										<img v-else src="/assets/noavatar.jpg" />
									</ion-avatar>
									<ion-label
										><h2>{{ result.userName }}</h2>
										<h5 class="text-medium">
											{{ result.location }}
										</h5></ion-label
									>
									<div v-if="!result.isSelf">
										<ion-button
											slot="end"
											@click="followUser(result)"
											v-if="
												!userProfile.following ||
												(!userProfile.following.includes(result.id) &&
													(!followLoading || searchResult != result))
											"
											>Follow</ion-button
										>
										<ion-button
											disabled
											slot="end"
											v-if="followLoading && searchResult == result"
											class="fll-size"
										>
											<ion-spinner name="dots"></ion-spinner>
										</ion-button>
										<ion-button
											slot="end"
											disabled
											v-if="
												userProfile.following &&
												userProfile.following.includes(result.id) &&
												(!followLoading || searchResult != result)
											"
											>Following</ion-button
										>
										<ion-button
											@click="initiateVideoCall(result.id)"
											v-if="client?.coaches_enabled"
										>
											<ion-icon :icon="videocam" size="sm"></ion-icon>
										</ion-button>
										<ion-button
											@click="initiateVoiceCall(result.id)"
											v-if="client?.coaches_enabled"
										>
											<ion-icon :icon="call" size="sm"></ion-icon>
										</ion-button>
									</div>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-grid>
				</div>

				<h3 v-if="noResult">
					There is no username that matches with your search.
				</h3>
			</ion-card-content>
		</ion-card>
	</ion-content>
</template>

<script>
import {
	IonContent,
	IonCard,
	loadingController,
	alertController,
	IonButton,
	IonAvatar,
	IonSpinner,
	IonTitle,
	IonButtons,
	IonToolbar,
	IonCardHeader,
	IonCardContent,
	IonInput,
	IonRow,
	IonGrid,
} from "@ionic/vue";

import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import { store } from "../../store";
import * as fb from "../../firebase";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { ActionTypes as Social } from "../../store/modules/social";

import { call, videocam } from "ionicons/icons";
import { ActionTypes as Call } from "../../store/modules/call";

export default defineComponent({
	name: "ModalSearch",
	components: {
		IonContent,
		IonButton,
		IonAvatar,
		IonSpinner,
		IonTitle,
		IonButtons,
		IonToolbar,
		IonCardHeader,
		IonCardContent,
		IonInput,
		IonRow,
		IonGrid,
		IonCard,
	},
	setup() {
		return {
			IonCard,
			call,
			videocam,
		};
	},
	data() {
		return {
			searchValue: "",
			searchResult: undefined,
			noResult: false,
			followLoading: false,
			foundSelf: false,
			searchResults: [],
		};
	},

	computed: {
		userProfile() {
			// console.log(store.getters.userProfile);
			const userProfileData = store.getters.userProfile;
			return userProfileData;
		},
		client() {
			return store.getters.client;
		},
	},

	watch: {
		userProfile(newProfile, oldProfile) {
			// this.searchUser();
			this.followLoading = false;
		},
	},

	methods: {
		processSearchResult(doc) {
			let isFollower = false;
			let isFollowing = false;
			this.foundSelf = false;
			if (
				store.getters.userProfile.followers &&
				store.getters.userProfile.followers.includes(doc.id)
			) {
				isFollower = true;
			}
			if (
				store.getters.userProfile.following &&
				store.getters.userProfile.following.includes(doc.id)
			) {
				isFollowing = true;
			}
			const searchResult = {
				...doc.data(),
				id: doc.id,
				isFollower: isFollower,
				isFollowing: isFollowing,
				isSelf: doc.id === store.getters.userProfile.uid,
			};
			return searchResult
		},

		followUser(result) {
			this.searchResult = result;
			const payload = {
				uid: store.getters.userProfile.uid,
				followRef: this.searchResult.id,
			};
			this.followLoading = true;
			store.dispatch(Social.followUser, payload);
		},

		async searchUser() {
			const searchValue = this.searchValue.toLowerCase();
			// console.log("Search Value", searchValue);

			// Reset search result
			this.searchResults = [];

			const loading = await loadingController.create({
				cssClass: "alert-wrapper",
				message: "Please wait...",
			});
			await loading.present();

			const alert = await alertController.create({
				header: "Search Error",
				buttons: ["OK"],
			});

			if (searchValue !== "") {
				const exact = await fb.users
					.where("userName", "==", this.searchValue)
					.get();
				// console.log(exact, this.searchValue)
				this.noResult = true;
				for (const doc of exact.docs) {
					if (this.searchResults.some((user) => user.uid === doc.data().uid)) {
						console.log("Skipped");
					} else {
						this.noResult = false;
						this.searchResults.push(this.processSearchResult(doc));
					}
				}

				// Perform search query
				const userSearchSnapShot = await fb.users
					.where("userName", ">=", searchValue)
					.where("userName", "<=", searchValue + "z")
					.get()
					.then((snapshot) => {
						if (snapshot.docs.length == 0) {
							loading.dismiss();
						} else {
							this.noResult = false;
							// console.log("Result", snapshot.docs);
							loading.dismiss();
							snapshot.docs.forEach((doc) => {
								console.log(doc.data());

								if (this.searchResults.some((user) => user.uid === doc.data().uid)) {
									console.log("Skipped");
								} else {
									this.searchResults.push(this.processSearchResult(doc));
								}

								console.log(this.searchResults);

								// console.log("Results", this.searchResult);
							});
						}
					})
					.catch(async (error) => {
						loading.dismiss();

						alert.cssClass = "my-alert-class";
						alert.message = error.message;
						alert.present();

						console.log(error);
					});

				// console.log("User Search", userSearchSnapShot);
			} else {
				loading.dismiss();

				alert.message = "Please enter a search keyword.";
				alert.present();
			}
		},
		initiateVideoCall(remoteUserUID) {
			const _remoteUserUID = remoteUserUID;

			// Fire action for initiating call room
			store.dispatch(Call.initializeCallRoom, _remoteUserUID).then(() => {
				this.close();
				this.$router.push("/videocall");
			});
		},
		initiateVoiceCall(remoteUserUID) {
			const _remoteUserUID = remoteUserUID;

			// Fire action for initiating call room
			store.dispatch(Call.initializeCallRoom, _remoteUserUID).then(() => {
				this.close();
				this.$router.push("/voicecall");
			});
		},
		async close() {
			// console.log("closing");
			await modalController.dismiss();
		},
	},
});
</script>
<style scoped>
.full-size {
	min-width: 100%;
}
</style>
