<template>
	<ion-content>
		<ion-header
			class="ion-no-border border-bottom"
			style="position: fixed; top: 0"
		>
			<ion-toolbar color="light">
				<ion-title class="text-md">Manage Followers</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="close()">X</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-list style="margin-top: 50px">
			<ion-item color="light">
				<ion-label position="floating">Filter by Username</ion-label>
				<ion-input v-model="searchTerm" :value="searchTerm" type="text" clear-on-edit="true"></ion-input>
				<ion-button  shape="round" color="light" size="block" slot="end" @click="search"
					>Filter</ion-button
				>
			</ion-item>
			<div v-if="!filtered">
				<ion-item color="light" v-for="(user, index) in followers" :key="index">
				<ion-avatar slot="start">
					<img :src="user.avatar" v-if="user.avatar && user.avatar !== ''" />
					<img v-else src="/assets/noavatar.jpg" />
				</ion-avatar>
				<ion-label
					><h2>{{ user.userName }}</h2>
					<h5 class="text-medium">{{ user.location }}</h5></ion-label
				>
				<ion-button
					v-if="user.isFollower"
					@click="removeFollower(user)"
					slot="end"
					fill="outline"
					color="danger"
					shape="round"
					>Remove</ion-button
				>
				<ion-button disabled slot="end" v-if="followLoading">
					<ion-spinner name="dots"></ion-spinner>
				</ion-button>
				<ion-button
					slot="end"
					@click="followUser(user)"
					v-if="!user.isFollower && !user.isFollowing && !followLoading"
					>Follow</ion-button
				>
				<ion-button
					slot="end"
					@click="followUser(user)"
					v-if="user.isFollower && !user.isFollowing && !followLoading"
					>Follow Back</ion-button
				>
				<ion-button
					slot="end"
					disabled
					v-if="user.isFollowing && !followLoading"
					>Following</ion-button
				>
				<!-- <ion-button slot="end">Follow</ion-button> -->
				</ion-item>
			</div>
			<div v-else>
				<ion-item color="light" v-for="(user, index) in filteredUsers" :key="index">
				<ion-avatar slot="start">
					<img :src="user.avatar" v-if="user.avatar && user.avatar !== ''" />
					<img v-else src="/assets/noavatar.jpg" />
				</ion-avatar>
				<ion-label
					><h2 class ='text-scale'>{{ user.userName }}</h2>
					<h5 class="text-medium">{{ user.location }}</h5></ion-label
				>
				<ion-button
					v-if="user.isFollower"
					@click="removeFollower(user)"
					slot="end"
					fill="outline"
					color="danger"
					shape="round"
					>Remove</ion-button
				>
				<ion-button disabled slot="end" v-if="followLoading">
					<ion-spinner name="dots"></ion-spinner>
				</ion-button>
				<ion-button
					slot="end"
					@click="followUser(user)"
					v-if="!user.isFollower && !user.isFollowing && !followLoading"
					>Follow</ion-button
				>
				<ion-button
					slot="end"
					@click="followUser(user)"
					v-if="user.isFollower && !user.isFollowing && !followLoading"
					>Follow Back</ion-button
				>
				<ion-button
					slot="end"
					disabled
					v-if="user.isFollowing && !followLoading"
					>Following</ion-button
				>
				<!-- <ion-button slot="end">Follow</ion-button> -->
				</ion-item>
			</div>
			
		</ion-list>
		<div v-if='filtered' class = 'resetButton'>
			<ion-button @click='resetUsers' size='small' color='primary'>Reset Filter</ion-button>
		</div>
	</ion-content>
</template>

<script>
import {
	IonContent,
	loadingController,
	alertController,
	IonButton,
	IonSpinner,
	IonAvatar,
	IonInput
} from "@ionic/vue";

import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import { store } from "../../store";
import * as fb from "../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { ActionTypes as Social } from "../../store/modules/social";
import {filterUsers} from '../../utils/search';

export default defineComponent({
	name: "modeFollowers",
	components: {
		IonContent,
		IonButton,
		IonSpinner,
		IonAvatar,
		IonInput
	},
	setup() {
		return {};
	},
	data() {
		return {
			followLoading: false,
			searchTerm: "",
			filtered: false,
			filteredUsers: []
		};
	},

	computed: {
		followers() {
			const followers = store.getters.followers;
			return followers;
		},
		following() {
			const following = store.getters.following;
			return following;
		},
	},

	beforeMount: function () {
		store.dispatch(Social.fetchFollowerUsers);
		store.dispatch(Social.fetchFollowingUsers);
	},

	methods: {
		async removeFollower(user) {
			const payload = {
				uid: store.getters.userProfile.uid,
				unfollowRef: user.uid,
			};
			await store.dispatch(Social.removeFollower, payload);
			const index = this.filteredUsers.indexOf(user)
			this.filteredUsers.splice(index, 1)
		},
		async followUser(user) {
			const payload = {
				uid: store.getters.userProfile.uid,
				followRef: user.uid,
			};
			await store.dispatch(Social.followUser, payload);
			
			
		},
		async close() {
			console.log("closing");
			await modalController.dismiss();
		},
		async search(){
			this.filteredUsers = []

			if (this.searchTerm === ""){
				this.filtered = false
			}
			else{
				const users = await filterUsers(this.searchTerm, this.followers)
				this.filteredUsers = users;
				this.filtered = true;
			}
		},
		resetUsers(){
			this.searchTerm = ""
			this.filteredUsers = [];
			this.filtered = false;
		}
	},
});
</script>
<style scoped>
.resetButton{
	padding-top: 2vh;
	display: flex;
	justify-content: center;
}
</style>
