
import {
	IonContent,
	IonHeader,
	IonPage,
	IonRow,
	IonCol,
	IonIcon,
	isPlatform,
	IonInput,
	IonLabel,
	IonAvatar,
	IonItem,
	IonList,
	IonListHeader,
	IonSpinner,
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
	sync,
	lockClosedOutline,
	accessibilityOutline,
	searchOutline,
	openOutline,
	helpBuoyOutline,
	call,
	menu,
	addCircleOutline,
	person,
	images,
	arrowBack,
	paperPlane,
	refresh,
	search,
} from "ionicons/icons";
import Navbar from "@/components/Navbar.vue";
import Bottombar from "@/components/Bottombar.vue";
import { store } from "../store";
import { useRouter } from "vue-router";
import localStore from "../utils/localstore";
import { ActionTypes as Chat } from "../store/modules/chat";
import { ActionTypes as Coins } from "../store/modules/coins";
import * as fb from "../firebase";
import { openToastOptionsPayment } from "../utils/toast";

export default defineComponent({
	name: "Chat",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		Bottombar,
		IonRow,
		IonCol,
		IonIcon,
		IonInput,
		IonLabel,
		IonAvatar,
		IonItem,
		IonList,
		IonListHeader,
		IonSpinner,
	},
	data() {
		return {
			sync,
			lockClosedOutline,
			accessibilityOutline,
			searchOutline,
			openOutline,
			helpBuoyOutline,
			call,
			menu,
			addCircleOutline,
			person,
			images,
			arrowBack,
			paperPlane,
			refresh,
			search,
			uid: "",
			chatroom: "",
			id: "",
			message: "",
			headerTitle: "",
			headerAvatar: "",
			msgPayload: { id: "", avatar: "", name: "" },
			searchValue: "",
			sendingMessage: false,
			gettingMessages: false,
			showMsgsBackBtn: false,
			chatRoomsClasses: "",
			msgsClasses: "",
			searchingChatRooms: false,
			refreshingChatRooms: false,
			paysforMessage: false,
			messageCount: 0,
			noResults: false
		};
	},
	async ionViewWillEnter() {
		store.dispatch(Chat.resetChatrooms);
		store.dispatch(Chat.resetMessages);

		const chatroom = this.$route.params.chatroom?.toString();
		// console.log("CHATROOM", chatroom);
		if (chatroom) this.chatroom = chatroom;

		const id = this.$route.params.id?.toString();
		if (id) this.id = id;
		// console.log("id", this.id);

		if (id && chatroom === "coaches") {
			// console.log("HELLLOOOO");
			const currentChat = store.getters.getTrainer(this.id);
			console.log(currentChat);

			const payload = {
				id: currentChat.id,
				avatar: currentChat.imgURL,
				name: currentChat.name,
			};

			// const user = await store.getters.userData;
			// this.uid = user.uid;
			// console.log("user data", user);

			this.getMessages(payload);
		}

		if (this.windowWidth >= 576) {
			this.chatRoomsClasses = "col-left col-left-desktop";
			this.msgsClasses =
				"ion-hide-sm-down ion-padding col-right col-right-desktop";
			this.showMsgsBackBtn = false;
		} else {
			this.chatRoomsClasses = "col-left col-left-mobile";
			this.msgsClasses = "ion-hide";
			this.showMsgsBackBtn = true;
		}

		await this.refreshChatRooms();
	},
	setup() {
		const router = useRouter();
		const isAndroid = isPlatform("android");
		const isIOS = isPlatform("ios");
		const user = store.getters.userProfile;

		return {
			router,
			isAndroid,
			isIOS,
			user,
		};
	},
	computed: {
		isWeb() {
			return store.getters.isWeb;
		},
		windowWidth() {
			return window.innerWidth;
		},
		messages() {
			return store.getters.messages;
		},
		chatRooms() {
			return store.getters.chatRooms;
		},
	},
	methods: {
		async refreshChatRooms() {
			this.refreshingChatRooms = true;
			this.noResults = false

			if (this.chatroom == "coaches") {
				if (this.id.length > 0) await store.dispatch(Chat.getCoach, this.id);
				else await store.dispatch(Chat.getAllCoaches, "");
			} else await store.dispatch(Chat.getAllUsers, "");

			this.refreshingChatRooms = false;
		},
		async sendMessage() {
			this.sendingMessage = true;
			this.detach();

			const msg = {
				senderId: this.user.uid,
				receiverId: this.msgPayload.id,
				receiverAvatar: this.msgPayload.avatar,
				senderAvatar: this.user.avatar,
				name: this.msgPayload.name,
				message: this.message,
				time: new Date(),
			};

			// console.log("MESSAGE", msg)

			if (this.chatroom === "coaches" && this.id && this.messageCount === 0) {
				const payload = {
					coach: this.id,
					amount: store.getters.getTrainer(this.id).defaultPrices?.messaging,
					type: "transfer",
				};

				let transactionId: any = null;
				const success = await store
					.dispatch(Coins.transferCoins, payload)
					.then(async ({ header, message, success, transactionID }) => {
						transactionId = transactionID;
						await openToastOptionsPayment(header, message);
						// this.loading = false;
						return success;
					});

				if (success) {
					await store.dispatch(Chat.sendNotification, msg);
					await store.dispatch(Chat.sendMessage, msg).catch(async (error) => {
						console.log("MESSAGE ERROR", error);
						const coach = this.id;
						const type = "Refund";
						const originalTransaction = transactionId;
						const amount = payload.amount;
						const payl = { coach, amount, type, originalTransaction };

						await store
							.dispatch(Coins.userRefund, payl)
							.then(async ({ header, message, success, transactionID }) => {
								message = "Could not send message. " + message;
								openToastOptionsPayment(header, message);
							});
					});
					await store.dispatch(Chat.updateReceiver, msg);
				} else {
					this.$router.back();
				}
			} else {
				store.dispatch(Chat.sendNotification, msg);
				await store.dispatch(Chat.sendMessage, msg).catch(async error => {
						console.log("send user msg error", error);
					});
				await store.dispatch(Chat.updateReceiver, msg);
				this.messageCount++;
			}

			this.message = "";
			this.sendingMessage = false;
		},
		async getMessages(payload: { id: string; avatar: any; name: any }) {
			store.dispatch(Chat.resetMessages);

			if (this.windowWidth >= 576) {
				this.chatRoomsClasses = "col-left col-left-desktop";
				this.msgsClasses =
					"ion-hide-sm-down ion-padding col-right col-right-desktop";
				this.showMsgsBackBtn = false;
			} else {
				this.chatRoomsClasses = "ion-hide";
				this.msgsClasses = "ion-padding col-right col-right-mobile";
				this.showMsgsBackBtn = true;
			}
			this.gettingMessages = true;
			this.headerTitle = payload.name;
			this.headerAvatar = payload.avatar;

			this.msgPayload = payload;

			const newPayload = {
				senderId: this.user.uid,
				receiverId: payload.id,
			};

			try {
				await store.dispatch(Chat.getMessages, newPayload);
				store.dispatch(Chat.watchMessages, newPayload);
				this.message = "";
				this.gettingMessages = false;
			} catch (err) {
				this.gettingMessages = false;
			}
		},
		showChatRooms() {
			this.chatRoomsClasses = "col-left col-left-mobile";
			this.msgsClasses = "ion-hide";
		},
		async getChatRooms(payload: string) {
			await store.dispatch(Chat.getChatRooms, payload);
		},
		async searchChatRooms(payload: string) {
			if (payload.length > 0) {
				this.searchingChatRooms = true;
				await store.dispatch(Chat.searchChatRooms, payload);
				if(this.chatRooms.length > 0) this.noResults = false
				else this.noResults = true
				this.searchingChatRooms = false;
			}
		},
		detach() {
			fb.db.collection("messages").onSnapshot(() => {
				// Respond to data
				console.log("unsubscribed to realtime messaging updates");
			});
		},
	},
	beforeUnmount() {
		// Stop listening to realtime messaging changes
		this.detach();
		store.dispatch(Chat.resetMessages);
		store.dispatch(Chat.resetChatrooms);
	},
	ionViewWillLeave() {
		// Stop listening to realtime messaging changes
		this.detach();
		store.dispatch(Chat.resetMessages);
		store.dispatch(Chat.resetChatrooms);
	},
});
