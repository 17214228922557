
import {
	IonContent,
	IonHeader,
	IonPage,
	IonToolbar,
	IonIcon,
	IonButton,
	IonAvatar,
	IonImg,
	IonLabel,
	IonBackButton,
	IonButtons,
	IonTitle,
	IonCard,
	IonCol,
	IonRow,
	IonGrid,
	modalController,
	IonBadge,
	alertController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import Navbar from "../components/Navbar.vue";
import Bottombar from "../components/Bottombar.vue";
import { useRouter } from "vue-router";
import { ActionTypes as Content } from "../store/modules/content";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { store } from "../store";
import {
	calendar,
	personCircle,
	playCircleOutline,
	albumsOutline,
	barbellOutline,
	starSharp,
	menuOutline,
	trophyOutline,
	bookmarkOutline,
	bookmark,
	mailOutline,
	videocamOutline,
	callOutline,
	star,
	starHalf,
	starOutline,
	chatboxOutline,
} from "ionicons/icons";
import TrainerModal from "./modals/modalTrainerRating.vue";
import Modal from "./modals/modalVideo.vue";
import MessagesModal from "./modals/modalCoachMessage.vue";
import VideoBookingModal from "./modals/modalCoachBookVideo.vue";
import CallBookingModal from "./modals/modalCoachBookCall.vue";
import { ActionTypes as Video } from "../store/modules/video_player";
import modalConfirmTransactionVue from "./modals/modalConfirmTransaction.vue";
export default defineComponent({
	name: "Videos",
	components: {
		IonContent,
		Navbar,
		Bottombar,
		IonHeader,
		IonPage,
		IonCard,
		IonCol,
		IonRow,
		IonGrid,
		IonBadge,
		IonToolbar,
		IonBackButton,
		IonButtons,
		IonTitle,
		IonIcon,
		IonButton,
		IonImg,
	},
	data() {
		return {
			modalOpen: false,
			showClasses: true,
			showServices: false,
			showBio: false,
			classesColor: "primary",
			servicesColor: "dark",
			bioColor: "dark",
			rating: "",
		};
	},
	setup() {
		// const store = useStore();
		const router = useRouter();
		return {
			playCircleOutline,
			albumsOutline,
			barbellOutline,
			calendar,
			personCircle,
			starSharp,
			trophyOutline,
			menuOutline,
			alertController,
			bookmarkOutline,
			bookmark,
			mailOutline,
			videocamOutline,
			callOutline,
			star,
			starHalf,
			starOutline,
			chatboxOutline,
		};
	},

	computed: {
		isWeb() {
			return store.getters.isWeb;
		},
		trainer() {
			return store.getters.currentTrainer;
		},
		client() {
			// console.log(store.getters.client)
			return store.getters.client;
		},
		trainerVids() {
			return store.getters.getClassesByTrainer(this.$route.params?.trainerId);
		},
		userSchedule() {
			return store.getters.getUserSchedule;
		},
		classProgress() {
			return store.getters.classProgress;
		},
		bookmarkedClasses() {
			return store.getters.getBookmarkedClasses;
		},
		isAuth() {
			return store.getters.auth;
		},
	},

	
	ionViewWillEnter(){
		store.dispatch(Video.fetchWatchHistory, { userID: store.getters.userProfile?.uid });
	},

	methods: {
		returnHome() {
			this.$router.replace("/coaches");
		},
		getColor() {
			if (this.client.mode === "dark") {
				return "light";
			}
			return "dark";
		},
		getStyle() {
			if (this.client.mode === "dark") {
				return "background: var(--ion-color-light)";
			}
			return "background: var(--ion-color-dark)";
		},
		removeBookmark(classObject: firebase.firestore.DocumentData) {
			const payload = {
				classID: classObject.classID,
				userID: store.getters.userProfile.uid,
			};
			// let alert = null;
			store.dispatch(Content.removeClassBookmark, payload);
			//TODO: This is here just in  case the fucntionality
			// .then(async () => {
			// 	alert = await alertController.create({
			// 		header: "Bookmark Removed",
			// 		message: `${classObject.name} has been unbookmarked!`,
			// 		buttons: ["OK"],
			// 	});
			// 	await alert.present();
			// })
			// .catch(async (error) => {
			// 	alert = await alertController.create({
			// 		header: "Error",
			// 		message: error,
			// 		buttons: ["OK"],
			// 	});
			// 	await alert.present();
			// });
		},
		addBookmark(classObject: firebase.firestore.DocumentData) {
			const payload = {
				classID: classObject.classID,
				userID: store.getters.userProfile.uid,
			};
			// let alert = null;
			store.dispatch(Content.addClassBookmark, payload);
		},
		isBookmarked(classObject: firebase.firestore.DocumentData) {
			const classID = classObject.classID;
			if (
				this.bookmarkedClasses.some(
					(classs: firebase.firestore.DocumentData) =>
						classs.classID === classID
				)
			) {
				return true;
			}
			return false;
		},
		progressType(classID: string) {
			const progress = this.classProgress.filter(
				(classs: firebase.firestore.DocumentData) => classs.classID === classID
			);
			if (progress[0].isFinished) {
				return "Watched";
			}
			return "Watching";
		},
		hasProgressed(classObject: firebase.firestore.DocumentData) {
			const classID = classObject.classID;
			if (
				this.classProgress.some(
					(classs: firebase.firestore.DocumentData) =>
						classs.classID === classID
				)
			) {
				return true;
			}
			return false;
		},
		// Onclick function to add a trainer rating
		async updateTrainerRating() {
			const modal = await modalController.create({
				component: TrainerModal,
				cssClass: "modal-custom",
				componentProps: {
					title: "Rate Coach",
					trainer: store.getters.currentTrainer.id,
					client: this.client,
				},
			});
			return modal.present();
		},
		isRegistered(classID: string) {
			const isScheduled = this.userSchedule.filter(
				(scheduleItem: firebase.firestore.DocumentData) => {
					return scheduleItem.classID === classID;
				}
			);
			if (isScheduled.length === 0) {
				return false;
			}
			return true;
		},
		async openModal(video: any) {
			// console.log(video);
			const modal = await modalController.create({
				component: Modal,
				cssClass: "modal-custom",
				componentProps: {
					title: "Join " + video.name,
					content: video.description,
					videoimage: video.imgURL,
					eventdate: video.date,
					eventname: video.name,
					trainer: video.trainerData.name,
					isLive: video.isLive,
					video: video,
					isScheduled: this.isRegistered(video.classID),
				},
			});
			return modal.present();
		},
		async openMessages(trainer: any) {
			const confirm = await modalController.create({
				component: modalConfirmTransactionVue,
				cssClass: "modal-custom",
				componentProps: {
					type: "Messaging",
					trainer: trainer,
					amount: 100,
					onConfirmation: () => this.goToChat(),
				},
			});

			return confirm.present();
		},
		async openVideoBookings(trainer: any) {
			// console.log(trainer);
			const modal = await modalController.create({
				component: VideoBookingModal,
				cssClass: "modal-custom",
				componentProps: {
					trainer: trainer,
				},
			});
			return modal.present();
		},
		async openCallBooking(trainer: any) {
			// console.log(trainer);
			const modal = await modalController.create({
				component: CallBookingModal,
				cssClass: "modal-custom",
				componentProps: {
					trainer: trainer,
				},
			});
			return modal.present();
		},
		// async updateTrainerRating() {
		// 	if (this.modalOpen == false) {
		// 		this.modalOpen = true;
		// 		const modal = await modalController.create({
		// 			component: TrainerModal,
		// 			cssClass: "modal-custom",
		// 			componentProps: {
		// 				title: "Rate Trainer",
		// 				trainer: store.getters.currentTrainer.id,
		// 				client: this.client,
		// 			},
		// 		});
		// 		return modal.present();
		// 	}
		// },
		createStars() {
			let rating = this.trainer.trainerRating;
			const unrated = Math.floor(5 - rating);
			console.log("rating", rating);
			this.rating = rating !== undefined ? rating.toFixed(2) + "/5" : "Unrated";

			// console.log(unrated);

			const stars = [];
			while (rating >= 1) {
				rating--;
				stars.push(star);
			}
			if (rating != 0) {
				if (rating >= 0.3) {
					stars.push(starHalf);
				} else {
					stars.push(starOutline);
				}
			}

			for (let i = 0; i < unrated; i++) {
				stars.push(starOutline);
			}
			// console.log(stars)
			return stars;
		},
		goTo(url: string) {
			this.$router.push(url);
		},
		showTab(tab: string) {
			switch (tab) {
				case "classes":
					this.showClasses = true;
					this.showServices = false;
					this.showBio = false;
					this.classesColor = "primary";
					this.servicesColor = "dark";
					this.bioColor = "dark";
					break;
				case "services":
					this.showClasses = false;
					this.showServices = true;
					this.showBio = false;
					this.classesColor = "dark";
					this.servicesColor = "primary";
					this.bioColor = "dark";
					break;
				case "bio":
					this.showClasses = false;
					this.showServices = false;
					this.showBio = true;
					this.classesColor = "dark";
					this.servicesColor = "dark";
					this.bioColor = "primary";
					break;
				default:
					break;
			}
		},
		goToChat() {
			this.$router.push({ path: "/chat/coaches/" + this.trainer.id });
		},
	},
});
