<template>
	<div style="height: 100vh">
		<ion-header class="ion-no-border border-bottom">
			<ion-toolbar color="light">
				<ion-title class="text-lg">Unlock Messaging With Coach</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="close()">X</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content color="light" class="ion-padding text-center" v-if="!loading">
			<ion-avatar style="width:100px;height:100px;margin:0 auto;border">
								<img :src="trainer.imgURL">
							</ion-avatar>
			<span class="text-primary">{{trainer.name}}</span><br><br>
			<br>
			<ion-item>Amount to Pay: {{trainer.defaultPrices?.messaging || amount}} FitCoins per message</ion-item>
			<br/>
			<ion-button color="success" @click="accept()">Confirm Transaction</ion-button>
			<div class="spacer"></div>
		</ion-content>
		<ion-content color="light" v-if="loading">
			<div class="spin">
				<ion-spinner name="crescent"></ion-spinner>
			</div>
		</ion-content>
	</div>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonButton,
	IonButtons,
	IonSpinner,
  alertController
} from "@ionic/vue";
import { defineComponent } from "vue";
import { modalController, isPlatform } from "@ionic/vue";
// import { DatePicker } from "v-calendar";
import {
	star,
	starOutline,
	starHalf,
	thumbsDownOutline,
	thumbsUpOutline,
	trophyOutline,
	timerOutline,
	flameOutline,
	heartOutline,
} from "ionicons/icons";
import { ActionTypes as Coins } from "../../store/modules/coins";
import { store } from "@/store";
import { useRouter } from "vue-router";
import { openToastOptionsPayment } from "../../utils/toast";

export default defineComponent({
	name: "ConfirmTransactionModal",
	props: { 
		
		trainer: { type: Object, default: null },
        amount: { type: Number, default: 1 },
        type: { type: String, defualt: "transfer" },
		onConfirmation: {type: Function, defualt: () => this.$router.push('/chat')}
		
	},

	setup() {
		// const store = useStore();
		const router = useRouter();
		return {
			star,
			starOutline,
			starHalf,
			thumbsDownOutline,
			thumbsUpOutline,
			trophyOutline,
			timerOutline,
			flameOutline,
			heartOutline,
		};
	},
	data() {
		return {
			content: "",
			utcDate: new Date(),
			loading: false
		};
	},
	computed: {
		client() {
			return store.getters.client;
		},
	},

	components: {
		IonContent,
		IonHeader,
		IonTitle,
		IonToolbar,
		IonButton,
		IonButtons,
		IonSpinner
		// DatePicker

	},
	beforeMount(){
		console.log("Int eh modal")
	},
	methods: {


		async handlePayment(payload){
			
			this.loading = true;

			const success = await store.dispatch(Coins.transferCoins, payload)
				.then( async ({header, message, success}) =>{
					await openToastOptionsPayment(header, message);
					this.loading = false;
					return success;
				})

            await modalController.dismiss();

			if (success) {
				this.onConfirmation();
			}
		},

        async accept(){
            const payload = {
                coach: this.trainer.id,
                amount: this.trainer.defaultPrices?.messaging || this.amount,
                type: this.type,
            }

			const alert = await alertController.create({
				header: "Warning",
				message: `Each message you send to ${this.trainer.name} will be billed at ${payload.amount} FitCoins per message. Continue?`,
				buttons: [{
					text: 'No',
					role: 'cancel',
				},
				{
					text: 'Yes',
					id: 'confirm-button',
					handler: () => this.handlePayment(payload)
				}
				]
			});

			return alert.present();
            
		},
		async close() {
			await modalController.dismiss();
		},
	},
});
</script>
<style scoped>
.spacer {
	height: 150px;
}
.spin{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100%;
}
</style>
