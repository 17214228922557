
import Navbar from "../components/Navbar.vue";

import {
	IonContent,
	IonHeader,
	IonPage,
	IonRow,
	IonCol,
	IonGrid,
	IonCard,
	IonCardContent,
	isPlatform,
	IonSpinner
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { key, store } from "../store";
import { users } from '../firebase'
import firebase from 'firebase';
import { openToastOptionsPayment } from "@/utils/toast";
import { ActionTypes as Auth } from "../store/modules/auth";

export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		IonRow,
		IonCol,
		IonGrid,
		IonCard,
		IonCardContent,
		IonSpinner
	},
	setup() {
		const subscribeLink = `${process.env.VUE_APP_CRM_SUBSCRIBE}${store.getters.userProfile.uid}`;
		const router = useRouter();
		return {
			subscribeLink,
			router,
		};
	},
	methods: {
		async SkipSubscribe() {
			this.$router.replace(`/${this.focusType}`);
		},
		manageSubscription() {
			if (isPlatform("ios") || isPlatform("android")) {
				this.router.replace("/iap");
			} else {
				window.open(this.subscribeLink, "_self");
			}
		},
		async subHandler() {
			if(!this.isWebOrPWA()){
				this.router.push('/iap')
				// modalController.dismiss();
			} else {
				// window.open(this.subscribeLink, '_self')
				this.loading = true;
				const uid = store.getters.userProfile?.uid;
				console.log("UID", uid)

				const user = await users.doc(uid);

				// console.log(user.data(), "USER")

				await user.update({
					subscription: {
						isSubscribed: true,
						duration: 7,
						date: firebase.firestore.FieldValue.serverTimestamp(),
					}
				})

				const header = "Subscription Updated"
				const message = "Now Valid for 7 More Days"

				await openToastOptionsPayment(header, message);

				await store.dispatch(Auth.userProfile, uid)
					.then( () => console.log("Updated the User Profile"))

				console.log("subscription updated");
				// this.loading = false;

				this.$router.push('/coaches')

				this.loading = false;

			}
		},
		isWebOrPWA(){
			return isPlatform("desktop") || isPlatform("mobileweb");
		},
	},
	data() {
		return {
			loading: false
		}
	},
	computed: {
		isWeb() {
			return store.getters.isWeb;
		},
		client() {
			return store.getters.client;
		},
		focusType() {
			return store.getters.client["focus_type"] || "classes";
		},
	},
});
