import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState } from "@/store";
import localStore from "@/utils/localstore";
import { Getters } from "./types";

export const getters: GetterTree<State, RootState> & Getters = {
	// Get client data from state
	client: (state) => {
		return state.client;
	},
	// Check if fetching client data returned an error
	fetchClientError: (state) => {
		return state.fetchClientError;
	},
	// Get user data from state
	userData: (state) => {
		return state.value;
	},
	auth: (state) => {
		return state.authState;
	},
	// Check if user is subscribed
	subscribed: async () => {
		const user = await localStore.get("user");
		return user.subscription.isSubscribed == true;
	},
	// Get user id from local storage
	userID: async () => {
		const user = await localStore.get("user");
		if (!user) return "";
		return user.uid;
	},
	// Get username from local storage
	userName: async () => {
		const user = await localStore.get("user");
		if (!user) {
			return "";
		} else {
			// const userJSON = JSON.parse(user);
			return user.username;
		}
	},
	// Get User Profile Data
	userProfile: (state) => {
		return state.userProfile;
	},
	getUserXP: (state) => {
		return state.userXP;
	},

	getFetchingProfile: (state) => {
		return state.fetchingProfile;
	},
};
