
import {
	IonContent,
	IonHeader,
	IonPage,
	loadingController,
	alertController,
	IonInput,
	IonImg,
	IonLabel,
	IonButton,
	IonCard,
	IonGrid,
	IonRow,
	IonCol,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { store } from "../store";
import { ActionTypes as Auth } from "../store/modules/auth";
import Navbar from "../components/Navbar.vue";

export default defineComponent({
	name: "ResetLogin",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		IonInput,
		IonImg,
		IonLabel,
		IonButton,
		IonCard,
		IonGrid,
		IonRow,
		IonCol,
	},

	data() {
		return {
			email: "",
			error: "",
		};
	},

	methods: {
		validEmail(email: string) {
			const res =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (res.test(email)) {
				this.error = "";
				return true;
			} else {
				this.error = "Invalid email address";
				return false;
			}
		},
		async resetLogin() {
			if (this.validEmail(this.email)) {
				const loading = await loadingController.create({
					message: "Please wait...",
				});
				await loading.present();

				const alertResponse = await alertController.create({
					header: "Password reset email sent",
					message: "Please check your email to reset your password",
					buttons: ["OK"],
				});

				store
					.dispatch(Auth.resetPassword, this.email)
					.then(() => {
						loading.dismiss();
						alertResponse.present();
					})
					.catch(async (error) => {
						// console.log("PASSWORD RESET ERROR", error);
						loading.dismiss();
						const alert = await alertController.create({
							header: "Error",
							message: error.message,
							buttons: ["OK"],
						});
						alert.present();
					});
			}
		},
	},
	computed: {
		client() {
			return store.getters.client;
		},
	},
	setup() {
		let isWeb = false;
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			isWeb = false;
		} else {
			isWeb = true;
		}
		return { isWeb };
	},
});
