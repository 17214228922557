import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState, store } from "@/store";
import moment, { Moment } from "moment";
import { Getters } from "./types";
import { classSortDesc } from "@/utils/utils";
import { ClassData } from "@/interfaces/contentStore";

export const getters: GetterTree<State, RootState> & Getters = {
	getCategories: (state) => {
		return state.categories;
	},

	getCategoryNames: (state) => {
		return state.categoryNames;
	},

	getClasses: (state) => {
		// console.log(state.classes)
		const onDemandClasses = state.classes.filter((classObj) => {
			return !classObj.isLive;
		});

		const liveClasses = store.getters.getLiveClasses;
		const classes = onDemandClasses.concat(liveClasses);

		return classes.sort(classSortDesc);
	},

	getClassesByCategory: (state) => (category: string) => {
		// TODO
		const onDemandClasses = state.classes.filter((classObject) => {
			return classObject.categoryName === category && !classObject.isLive;
		});

		let liveClasses = store.getters.getLiveClasses;

		liveClasses = liveClasses.filter((classObject: any) => {
			return classObject.categoryName === category;
		});

		const classes = onDemandClasses.concat(liveClasses);

		return classes.sort(classSortDesc);
	},

	getClassesByTrainer: (state) => (trainerId: string) => {
		const onDemandClasses = state.classes.filter((classs) => {
			if (classs.trainerData) {
				const isHere = classs?.trainerData?.id == trainerId && !classs.isLive;
				return isHere;
			}
			return false;
		});
		let liveClasses = store.getters.getLiveClasses;
		liveClasses = liveClasses.filter((classObject: any) => {
			return classObject.trainerData?.id === trainerId;
		});
		const classes = onDemandClasses.concat(liveClasses);
		return classes.sort(classSortDesc);
	},

	getLiveClasses: (state) => {
		// this allows us to join classes at any point during their duration
		const data = state.classes?.filter((classObject) => {
			return (
				classObject.isLive &&
				moment(new Date(Date.now())).isSameOrBefore(
					new Date(
						(classObject.date.seconds + classObject.duration * 60) * 1000
					)
				)
			);
		});

		const sorted = data.sort((firstClass: any, secondClass: any) => {
			const firstDate = moment(firstClass.date.seconds * 1000);
			const secondDate = moment(secondClass.date.seconds * 1000);
			const firstAfterSec = firstDate.isAfter(secondDate);
			const secAfterFirst = secondDate.isAfter(firstDate);
			if (firstAfterSec) {
				return 1;
			} else if (secAfterFirst) {
				return -1;
			} else {
				return 0;
			}
		});

		return sorted;
	},

	getFeaturedClasses: (state) => {
		const onDemandClasses = state.classes.filter((classObject) => {
			return classObject.isFeatured && !classObject.isLive;
		});

		let liveClasses = store.getters.getLiveClasses;

		liveClasses = liveClasses.filter((classObject: ClassData) => {
			return classObject.isFeatured;
		});

		const featured = onDemandClasses.concat(liveClasses);

		return featured.sort(classSortDesc);
	},

	getClassesForDay: (state) => (date: Moment) => {
		const live = store.getters.getLiveClasses;
		const scheduleClass = store.getters.getUserSchedule;
		const liveClassIDs = [] as string[];

		// console.log("USER SCHEDULE", scheduleClass);

		const todayClass = live.filter((classObject: any) => {
			const classDate = moment(new Date(classObject.date.seconds * 1000));
			classObject["dateMoment"] = classDate;
			liveClassIDs.push(classObject.classID);
			return classDate.isSame(date, "day");
		});

		// console.log(liveClassIDs);
		// console.log(todayClass);

		//Filter the live classes from schedule List
		let filteredVod: any[] = scheduleClass.filter((classObject: any) => {
			// console.log("IN FILTERED ", classObject);
			return classObject.classID && !liveClassIDs.includes(classObject.classID);
		});

		//filter out the classes that are not happening today
		filteredVod = filteredVod.filter((classObject: any) => {
			const classDate = moment(new Date(classObject.date.seconds * 1000));
			classObject["dateMoment"] = classDate;
			// console.log(classObject);
			return classDate.isSame(date, "day");
		});

		// console.log(filteredVod);
		const scheduledVODs = [] as ClassData[];
		filteredVod.forEach((vod) => {
			let data;
			if (!vod.transactionID) {
				data = store.getters.getClass(vod.classID);
			} else {
				data = vod;
			}

			// console.log("VOD-DATA", data);

			data["dateMoment"] = vod.dateMoment;
			scheduledVODs.push(data);
		});
		// console.log(scheduledVODs)
		const bookedClasses = [] as ClassData[];
		const booked = scheduleClass.filter((classDat: any) => {
			return !classDat.classID;
		});

		// console.log("WOOOO", booked)

		booked.forEach((classDat: any) => {
			classDat.classID = classDat.id;
			classDat = {
				...classDat,
				classID: classDat.id,
			};
			if (moment(new Date(classDat.date.seconds * 1000)).isSame(date)) {
				bookedClasses.push(classDat);
				// console.log("CLASSSSS",classDat)
			}
		});

		const fullSchedule: any[] = todayClass.concat(
			scheduledVODs.concat(bookedClasses)
		);

		//sort the output
		fullSchedule.sort((class1, class2) =>
			class1.dateMoment.isAfter(class2.dateMoment) ? 1 : -1
		);
		console.log(fullSchedule);
		return fullSchedule;
	},

	getClass: (state) => (id: string) => {
		return state.classes.find((obj) => obj.classID === id);
	},

	getBookmarkedClasses: (state) => {
		if (store.getters.userProfile) {
			return state.bookmarkedClasses;
		}

		return [];
	},
};
