import { ClassData } from "@/interfaces/contentStore";
import { ClassProgress, UserWatching } from "@/interfaces/videoPlayerStore";
import { MutationTree } from "vuex";
import { State } from "./index";
import { Mutations } from "./types";

// (B) - mutations
// [B.1] define mutation types
// ==> { X = "SET_ X" }
export enum MutationTypes {
	SET_CURRENT_VIDEO = "CURRENT_VIDEO",
	SET_RECORD = "SET_RECORD",
	SET_VIEWERS = "CURRENTLY WATCHING",
	SET_USER_CLASS_PROGRESS = "SET_USER_CLASS_PROGRESS",
	reset = "RESET",
}

// [B.3] implement mutation
// ==>  [MutationTypes.X](state, payload) {
// ==>    state.x = payload;
// ==>  },
export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_CURRENT_VIDEO](state, payload) {
		state.currentVideo = payload;
	},

	[MutationTypes.SET_RECORD](state, payload) {
		state.currentVideoRecord = payload;
	},

	[MutationTypes.SET_VIEWERS](state, payload) {
		state.usersWatching = payload;
	},

	[MutationTypes.SET_USER_CLASS_PROGRESS](state, payload) {
		state.userClassProgress = payload;
	},

	[MutationTypes.reset](state) {
		state.currentVideo = {} as ClassData;
		state.currentVideoRecord = {};
		state.usersWatching = [] as UserWatching[];
		state.userClassProgress = [] as ClassProgress[];
	},
};
