import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState } from "@/store";
import { Getters } from "./types";

export const getters: GetterTree<State, RootState> & Getters = {
	// Get call room
	getCallRoom: (state) => {
		return state.callRoom;
	},
	// Get remote user object
	getRemoteUser: (state) => {
		return state.remoteUser;
	},
};
