import { ActionTree } from "vuex";
import { MutationTypes } from "./mutations";
import { State } from "./index";
import { RootState, store } from "@/store";
import * as fb from "../../../firebase";
import { Actions } from "./interfaces";

// ACTION DEFINITION (enum)
export enum ActionTypes {
	fetchLeaderboard = "FETCH_LEADERBOARD",
	reset = "RESET"
}

// DEFINE ACTIONS
export const actions: ActionTree<State, RootState> & Actions = {
	// FETCH LEADERBOARD
	[ActionTypes.fetchLeaderboard]({ commit }) {
		// Fetch client key
		const _clientID = process.env.VUE_APP_CLIENT_KEY;

		// Leaderboard Arrays
		const leaderboardDay: any[] = [];
		const leaderboardWeek: any[] = [];
		const leaderboardMonth: any[] = [];
		const leaderboardAllTime: any[] = [];

		// Leaderboard docRef
		const docRef = fb.db.collection("leaderboard").doc(_clientID);

		return new Promise<void>((resolve, reject) => {
			//
			docRef
				.get()
				.then((querySnapshot) => {
					//
					const daily = querySnapshot.data()?.daily;
					const weekly = querySnapshot.data()?.weekly;
					const monthly = querySnapshot.data()?.monthly;
					const allTime = querySnapshot.data()?.allTime;

					Object.keys(daily).forEach(function (key) {
						if (daily[key].xp > 0) {
							leaderboardDay.push(daily[key]);
						}
					});
					Object.keys(weekly).forEach(function (key) {
						if (weekly[key].xp > 0) {
							leaderboardWeek.push(weekly[key]);
						}
					});
					Object.keys(monthly).forEach(function (key) {
						if (monthly[key].xp > 0) {
							leaderboardMonth.push(monthly[key]);
						}
					});
					Object.keys(allTime).forEach(function (key) {
						if (allTime[key].xp > 0) {
							leaderboardAllTime.push(allTime[key]);
						}
					});

					commit(MutationTypes.GET_LEADERBOARD_DAY, leaderboardDay);
					commit(MutationTypes.GET_LEADERBOARD_WEEK, leaderboardWeek);
					commit(MutationTypes.GET_LEADERBOARD_MONTH, leaderboardMonth);
					commit(MutationTypes.GET_LEADERBOARD_ALL_TIME, leaderboardAllTime);
				})
				.catch((error) => {
					console.log("Error", error);
					reject(error);
				});
			//
			resolve();
		});
	},

	// RESET
	async [ActionTypes.reset]({ commit }) {
		commit(MutationTypes.reset);
	},
};
