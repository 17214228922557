import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState } from "@/store";
import { Getters } from "./types";

export const getters: GetterTree<State, RootState> & Getters = {
	userHealthData: (state) => {
		return state.userHealthData;
	},
	healthAppConnected: (state) => {
		return state.userHealthData.healthAppConnected;
	},
	height: (state) => {
		return state.userHealthData.height;
	},
	weight: (state) => {
		return state.userHealthData.weight;
	},
	heartRate: (state) => {
		return state.userHealthData.heartRate;
	},
	caloriesBurnt: (state) => {
		return state.userHealthData.caloriesBurnt;
	},
	permissionsGranted: (state) => {
		return state.userHealthData.permissionsGranted;
	},
	unAuthorized: (state) => {
		return state.userHealthData.unAuthorized;
	},
	// IOS Only
	gender: (state) => {
		return state.userHealthData.gender;
	},
	// IOS Only
	dob: (state) => {
		return state.userHealthData.dob;
	},
};
