import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState } from "@/store";
import { Getters } from "./types";

export const getters: GetterTree<State, RootState> & Getters = {
	// Get user chatrooms from state
	chatRooms: (state) => {
		return state.chatRooms;
	},
	// Get user messages from state
	messages: (state) => {
		return state.messages;
	},
	// Get live stream id
	liveStreamId: (state) => {
		return state.liveStreamId;
	},
	// Get live stream comments from state
	liveStreamComments: (state) => {
		return state.liveStreamComments;
	},
	// Get live stream comment count from state
	liveStreamCommentCount: (state) => {
		return state.liveStreamCommentCount;
	},
	// Get live stream view count from state
	liveStreamViewCount: (state) => {
		return state.liveStreamViewCount;
	},
	// Get live stream like count from state
	liveStreamLikeCount: (state) => {
		return state.liveStreamLikeCount;
	},
};
