import { ActionTree } from "vuex";
import { MutationTypes } from "./mutations";
import { State } from "./index";
import { RootState, store } from "@/store";
import * as fb from "../../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { ActionTypes as Auth } from "../auth";
import { Actions } from "./interfaces";
import { AugmentedActionContext } from "./types";
import { isPlatform } from "@ionic/vue";

// Actions
// place the action definition as a string
// ==> { setX = "MODULE__SET_ X" }
export enum ActionTypes {
	followUser = "FOLLOW_USER_ACTION",
	fetchFollowingUsers = "FETCH_USERS_WE_FOLLOW",
	fetchFollowerUsers = "FETCH_USERS_WHO_FOLLOW_US",
	unfollowUser = "UNFOLLOW_USER_ACTION",
	removeFollower = "REMOVE_FOLLOWER",
	reset = "RESET",
	sendFollowNotification = "SEND_NOTIFICATION_WHEN_FOLLOWED",
}

// [C.3] declare actions
// [ActionTypes.setX]({ commit }, payload) {
//   commit(MutationTypes.X, payload);
// },
export const actions: ActionTree<State, RootState> & Actions = {
	// FOLLOW USER
	[ActionTypes.followUser]({ commit }, payload: any) {
		console.log(payload);
		const { uid, followRef } = payload;
		fb.users.doc(followRef).update({
			followers: firebase.firestore.FieldValue.arrayUnion(uid),
		});

		fb.users
			.doc(uid)
			.update({
				following: firebase.firestore.FieldValue.arrayUnion(followRef),
			})
			.then(() => {
				store.dispatch(Auth.userProfile, uid).then(() => {
					store.dispatch(ActionTypes.fetchFollowingUsers);
					store.dispatch(ActionTypes.fetchFollowerUsers);
				});
			})
			.then(() => {
				const fcmPayload = {
					id: "",
					receiverId: followRef,
					time: new Date(),
				};
				store
					.dispatch(ActionTypes.sendFollowNotification, fcmPayload)
					// .then(() => console.log("Notification Sent"))
					.catch((error) => console.log("Error", error));
			});
	}, //more actions
	// FETCH USERS USER FOLLOWS
	[ActionTypes.fetchFollowingUsers]({ commit }: AugmentedActionContext) {
		const followingArr = store.getters.userProfile.following;

		if (followingArr && followingArr.length > 0) {
			fb.users
				.where(
					firebase.firestore.FieldPath.documentId(),
					"in",
					store.getters.userProfile.following
				)
				.get()
				.then((querySnapshot: firebase.firestore.QuerySnapshot) => {
					const data: firebase.firestore.DocumentData[] = [];

					querySnapshot.docs.forEach(
						(doc: firebase.firestore.DocumentSnapshot) => {
							data.push({ ...doc.data(), uid: doc.id });
						}
					);

					commit(MutationTypes.SET_FOLLOWING, data);
				});
		} else {
			commit(
				MutationTypes.SET_FOLLOWING,
				[] as firebase.firestore.DocumentData[]
			);
		}
	},
	// FETCH USER FOLLOWERS
	[ActionTypes.fetchFollowerUsers]({ commit }: AugmentedActionContext) {
		const followerArr = store.getters.userProfile.followers;

		if (followerArr && followerArr.length > 0) {
			fb.users
				.where(firebase.firestore.FieldPath.documentId(), "in", followerArr)
				.get()
				.then((querySnapshot: firebase.firestore.QuerySnapshot) => {
					const data: firebase.firestore.DocumentData[] = [];

					querySnapshot.docs.forEach(
						(doc: firebase.firestore.DocumentSnapshot) => {
							let isFollower = false;
							let isFollowing = false;
							if (
								store.getters.userProfile.followers &&
								store.getters.userProfile.followers.includes(doc.id)
							) {
								isFollower = true;
							}
							if (
								store.getters.userProfile.following &&
								store.getters.userProfile.following.includes(doc.id)
							) {
								isFollowing = true;
							}
							data.push({
								...doc.data(),
								uid: doc.id,
								isFollower: isFollower,
								isFollowing: isFollowing,
							});
						}
					);

					commit(MutationTypes.SET_FOLLOWERS, data);
				});
		} else {
			commit(
				MutationTypes.SET_FOLLOWERS,
				[] as firebase.firestore.DocumentData[]
			);
		}
	},
	// UNFOLLOW USER
	[ActionTypes.unfollowUser]({ commit }, payload) {
		const { uid, unfollowRef } = payload;
		// console.log("PAYLOAD FOR UNFOLLOW", payload)
		fb.users.doc(unfollowRef).update({
			followers: firebase.firestore.FieldValue.arrayRemove(uid),
		});

		fb.users
			.doc(uid)
			.update({
				following: firebase.firestore.FieldValue.arrayRemove(unfollowRef),
			})
			.then(() => {
				store.dispatch(Auth.userProfile, uid).then(() => {
					store.dispatch(ActionTypes.fetchFollowingUsers);
					store.dispatch(ActionTypes.fetchFollowerUsers);
				});
			});
	},
	// REMOVE FOLLOWER
	[ActionTypes.removeFollower]({ commit }, payload) {
		const { uid, unfollowRef } = payload;
		// console.log("PAYLOAD FOR UNFOLLOW", payload)
		fb.users.doc(unfollowRef).update({
			following: firebase.firestore.FieldValue.arrayRemove(uid),
		});

		fb.users
			.doc(uid)
			.update({
				followers: firebase.firestore.FieldValue.arrayRemove(unfollowRef),
			})
			.then(() => {
				store.dispatch(Auth.userProfile, uid).then(() => {
					store.dispatch(ActionTypes.fetchFollowingUsers);
					store.dispatch(ActionTypes.fetchFollowerUsers);
				});
			});
	},
	// SEND NOTIFICATION WHEN FOLLOWED
	async [ActionTypes.sendFollowNotification](
		{ commit },
		payload: {
			id: string;
			receiverId: string;
			// receiverAvatar: string;
			time: firebase.firestore.Timestamp | string;
		}
	) {
		// Get user payload
		const localUserObject = store.getters.userProfile;

		// FCM Payload
		const fcmPayload = {
			id: payload.id,
			senderId: localUserObject.uid,
			receiverId: payload.receiverId,
			senderAvatar: localUserObject.avatar,
			// receiverAvatar: payload.receiverAvatar,
			name: localUserObject.userName,
			message: `${localUserObject.userName} started following you`,
			time: payload.time,
		};

		//
		const receiverTokens = await firebase
			.firestore()
			.collection("users")
			.doc(payload.receiverId)
			.collection("fcmTokens")
			.get();

		if (receiverTokens) {
			receiverTokens.forEach((doc: any) => {
				const token = doc.data().token;
				if (token) {

					const toSend = {
						to: token,
						notification: {
							title: "New Follower",
							body: fcmPayload.message,
							mutable_content: true,
							icon: fcmPayload.senderAvatar, // Replace with app icon ???
							image: fcmPayload.senderAvatar,
						},
						android: {
							notification: {
								image: fcmPayload.senderAvatar,
							},
						},
						apns: {
							payload: {
								aps: {
									"mutable-content": 1,
								},
							},
							fcm_options: {
								image: fcmPayload.senderAvatar,
							},
						},
						webpush: {
							headers: {
								image: fcmPayload.senderAvatar,
							},
						},
						data: {
							imageUrl: fcmPayload.senderAvatar,
							body: fcmPayload.message,
						},
					};

					const iosPayload = {
						"message": {
							"token": token,
							"data": {
								"title": "New follower",
								"body": fcmPayload.message,
								// "image" : fcmPayload.senderAvatar
							},
							"apns": {
								"headers": {
									"apns-expiration": "1604750400"
								}
							},
							"android": {
								"ttl": "4500s"
							},
							"webpush": {
								"headers": {
									"TTL": "4500"
								}
							}
						}
					}

					const payloadSent = isPlatform("ios") ? iosPayload : toSend

					fetch("https://fcm.googleapis.com/fcm/send", {
						method: "POST",
						body: JSON.stringify(payloadSent),
						headers: {
							"Content-Type": "application/json",
							Authorization:
								"Bearer " + process.env.VUE_APP_FIREBASE_MESSAGING_TOKEN,
						},
					})
						.then((res) => res.json())
						.catch((error) => console.error("Error:", JSON.stringify(error)))
						.then((response) => console.log("Success", JSON.stringify(response)));
				}
			});
		}
	},

	// RESET
	async [ActionTypes.reset]({ commit }) {
		commit(MutationTypes.reset);
	},
};
