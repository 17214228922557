import * as fb from "../firebase";

export const searchUsers = async (searchWord: string, uid: string, followers: string[], following: string[]): Promise<any> =>{
    const searchValue = searchWord.toLowerCase();
    

    if (searchValue !== "") {
        // Perform search query
        const {isError, error, users }= await fb.users
            .where("userName", ">=", searchValue)
            .where("userName", "<=", searchValue + "z")
            .get()
            .then( async (snapshot) => {
                if (snapshot.docs.length == 0) {
                    return { result: false, users: [], error: ""}
                } else {
                    const searchResults: any = [];
                    let isFollower = false;
                    let isFollowing = false;
                    for  (const doc of snapshot.docs){
                        console.log(doc.data())
                        if (
                            followers &&
                            followers.includes(doc.id)
                        ) {
                            isFollower = true;
                        }
                        if (
                            following &&
                            following.includes(doc.id)
                        ) {
                            isFollowing = true;
                        }
                        const searchResult = {
                            ...doc.data(),
                            id: doc.id,
                            isFollower: isFollower,
                            isFollowing: isFollowing,
                            isSelf: doc.id === uid,
                        };
                        searchResults.push(searchResult);
                    }
                   
                    return {isError: false, error: "", users: searchResults};
                }
            })
            .catch((error: any) => {
                console.log(error);
                return {isError: true, error, users: []}
            });

            if (isError){
                return { result: false, users, error: error.message}
            }
            
            return { result: true, users: users, error: ""}
        // console.log("User Search", userSearchSnapShot);
    } else {
        return {result: true, users: [], error: ""}
    }
}

export const filterUsers = (keyword: string, users: any[]) =>{
    const filtered = []
    for (const user of users){
        console.log(users)
        if (user.userName.includes(keyword)){
            filtered.push(user)
        }
    }
    console.log(filtered)
    return filtered
}