
import {
	IonToolbar,
	IonSpinner,
	IonButton,
	IonAvatar,
	popoverController,
	isPlatform,
} from "@ionic/vue";
import {
	calendar,
	playCircleOutline,
	albumsOutline,
	barbellOutline,
} from "ionicons/icons";
import { defineComponent } from "vue";
import { RouteRecordName, useRouter } from "vue-router";
import { store } from "../store";
import UserDropDown from "../components/UserDropDown.vue";
export default defineComponent({
	name: "Navbar",
	components: {
		IonToolbar,
		IonButton,
		IonAvatar,
		IonSpinner,
	},
	data() {
		return {
			webNav: [""],
			pageName: "" as RouteRecordName | undefined | null,
			userName: "",
			isAuth: false,
			UserPhoto: "/assets/noavatar.jpg",
			focusType: "",
		};
	},

	setup() {
		const router = useRouter();
		return {
			playCircleOutline,
			albumsOutline,
			barbellOutline,
			calendar,
		};
	},

	async created() {
		this.userName = await store.getters.userName;
		this.isAuth = await store.getters.auth;
	},

	methods: {
		async openPopover(ev: Event) {
			const popover = await popoverController.create({
				component: UserDropDown,
				showBackdrop: false,
				event: ev,
				translucent: true,
			});
			await popover.present();
		},

		// Go to page
		goToPage(pagePath: string) {
			// console.log("Go to", pagePath);
			this.$router.push(`/${pagePath}`);
		},
	},

	computed: {
		isFetchingProfile() {
			return store.getters.getFetchingProfile;
		},
		userProfile() {
			const userProfileData = store.getters.userProfile;
			// console.log("Profile View", userProfileData);
			return userProfileData;
		},
		client() {
			return store.getters.client;
		},
		isWeb() {
			return !isPlatform("ios") && !isPlatform("android");
		},
		currentRoute() {
			return this.$route.name;
		},
		_isAuth() {
			return store.getters.auth;
		},
	},

	beforeMount: function () {
		this.pageName = this.$route.name;
		// Get Focus Type
		this.focusType = store.getters.client["focus_type"] || "classes";

		// Check whether focus is on coaches or classes
		if (this.focusType == "coaches") {
			this.webNav = [
				"HOME",
				"COACHES",
				"CLASSES",
				"FEATURED",
				"RECOMMENDED",
				"SCHEDULE",
				"ABOUT US",
				"CONTACT",
				"JOIN NOW",
			];
		} else if (this.focusType == "classes") {
			this.webNav = [
				"HOME",
				"CLASSES",
				"COACHES",
				"FEATURED",
				"RECOMMENDED",
				"SCHEDULE",
				"ABOUT US",
				"CONTACT",
				"JOIN NOW",
			];
		}
	},

	watch: {
		currentRoute(newRoute, oldRoute) {
			// console.log("ROUTE " + newRoute);
		},
	},
});
