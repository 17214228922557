
import { InAppPurchase2, IAPProduct } from "@ionic-native/in-app-purchase-2";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonRow,
	IonCol,
	IonGrid,
	IonCard,
	IonCardContent,
	IonBackButton,
	IonButtons,
	isPlatform,
	modalController,
	loadingController,
} from "@ionic/vue";
import { defineComponent, onMounted } from "vue";
import { key, store } from "../store";
import TermsModal from "./modals/modalTerms.vue";
import PrivacyModal from "./modals/modalPrivacy.vue";
import localStore from "../utils/localstore";
import { ActionTypes as Auth } from "../store/modules/auth";
export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonRow,
		IonCol,
		IonGrid,
		IonCard,
		IonCardContent,
		IonBackButton,
		IonButtons,
	},
	setup() {
		// const subscribeLink = `${process.env.VUE_APP_CRM_SUBSCRIBE}${store.getters.userData.uid}`;
		const iap = InAppPurchase2;
		const products: IAPProduct[] = [];
		let storeName = "";
		if (isPlatform("ios")) {
			storeName = "Apple";
		}
		if (isPlatform("android")) {
			storeName = "Google";
		}
		return {
			// subscribeLink,
			iap,
			products,
			storeName,
		};
	},
	data() {
		return {
			p1: [] as any,
			p2: [] as any,
			sub2: false,
			sub3: false,
			subsloading: false,
			savings: "",
			storeLoaded: false
		};
	},
	async beforeMount() {
		// document.addEventListener("deviceready", () => {

		const user = await localStore.get("user");
		this.iap.verbosity = this.iap.DEBUG;
		console.log("Loading IAP and Registering Products");
		
		this.iap.register([
			{ id: "sub002", type: this.iap.PAID_SUBSCRIPTION },
			{ id: "sub003", type: this.iap.PAID_SUBSCRIPTION },
		]);
		
		this.iap.validator =
			"https://validator.fovea.cc/v1/validate?appName=fit.platform.classes&apiKey=4b91ccbd-14e7-4ce9-8e28-310ba4c62756";
		
		this.iap.error(function (error: any) {
			console.log("store error");
			console.log(error);
		});

		
		this.iap
			.when("product")
			.approved((p: IAPProduct) => {
				// Handle the product deliverable
				if (p.id === "sub002") {
					store
					.dispatch(Auth.updateSubscription, 7)
					this.sub2 = true;
				} else if (p.id === "sub003") {
					store
					.dispatch(Auth.updateSubscription, 7)
					this.sub3 = true;
				}
				return p.verify();
			})
			.verified((p: IAPProduct) => p.finish());
		// Specific query for one ID
		this.iap.when("sub002").owned((p: IAPProduct) => {
			this.sub2 = true;
		});
		this.iap.when("sub003").owned((p: IAPProduct) => {
			this.sub3 = true;
		});

		
		
		this.iap.ready(() => {
			console.log("store ready");
			this.storeLoaded = true;
			this.iap.applicationUsername = user.uid;
			this.p1 = this.iap.get("sub002");
			this.p2 = this.iap.get("sub003");
			this.savings = Number(this.p2.priceMicros / 12000000).toLocaleString(
				"en-US",
				{ style: "currency", currency: this.p2.currency }
			);
			console.log(this.p1);
			console.log(this.p2);
		});

		console.log("refreshing store status");
		this.iap.refresh();

		// })
	},
	methods: {
		async restorePurchases() {
			this.iap.refresh();
		},
		async purchase(id: string) {
			this.iap.order(id);
		},
		ManageSub() {
			this.iap.manageSubscriptions();
		},
		async showTerms() {
			const modal = await modalController.create({
				component: TermsModal,
			});
			return modal.present();
		},
		async showPrivacy() {
			const modal = await modalController.create({
				component: PrivacyModal,
			});
			return modal.present();
		},
	},
	computed: {
		isWeb() {
			return store.getters.isWeb;
		},
		client() {
			return store.getters.client;
		},
	},
});
