
import {
	IonContent,
	IonHeader,
	IonPage,
	IonToolbar,
	IonRow,
	IonCol,
	IonGrid,
	IonCard,
	modalController,
	loadingController,
	IonSpinner,
	IonIcon,
	IonButton,
	IonFab,
	IonBadge,
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
	calendar,
	personCircle,
	filterCircleOutline,
	playCircleOutline,
	albumsOutline,
	barbellOutline,
	starSharp,
	menuOutline,
	trophyOutline,
	bookmarkOutline,
	bookmark,
} from "ionicons/icons";

import { store } from "../store";
import Modal from "./modals/modalVideo.vue";
import FilterModal from "./modals/modalFilter.vue";
import * as fb from "../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { ActionTypes as Content } from "../store/modules/content";
import { ActionTypes as VideoPlayer } from "../store/modules/video_player";
// import { ActionTypes } from "../store/modules/auth";
import Bottombar from "../components/Bottombar.vue";
import Navbar from "../components/Navbar.vue";

export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		Bottombar,
		IonRow,
		IonCol,
		IonGrid,
		IonSpinner,
		IonCard,
		IonIcon,
		IonButton,
		IonFab,
		IonBadge,
	},

	data() {
		return {
			filteredClasses: [],
			toggleFilter: false,
			classesLoading: true,
			toggleValues: {
				live: false,
				onDemand: false,
				liveReplay: false,
				minute10: false,
				minute30: false,
				minute45: false,
				minute60: false,
				bookmarked: false,
				hideWatched: false,
				// low: false,
				// moderate: false,
				// high: false,
			},
		};
	},

	ionViewWillEnter(){
		store.dispatch(VideoPlayer.fetchWatchHistory, { userID: store.getters.userProfile?.uid });
	},

	computed: {
		content() {
			return {
				classes: store.getters.getClasses,
				categories: store.getters.getCategories,
				featuredClasses: store.getters.getFeaturedClasses,
			};
		},
		userSchedule() {
			return store.getters.getUserSchedule;
		},
		classProgress() {
			return store.getters.classProgress;
		},
		isWeb() {
			return store.getters.isWeb;
		},
		bookmarkedClasses() {
			return store.getters.getBookmarkedClasses;
		},
		isAuth() {
			return store.getters.auth;
		},
	},

	async beforeMount() {
		this.classesLoading = true;
		await store.dispatch(Content.setCategories);
		await store.dispatch(Content.setClasses).then(() => {
			this.classesLoading = false;
		});
	},
	watch: {
		//TODO, remove console.logs once complete
		content(newContent, oldContent) {
			this.filteredClasses = newContent.featuredClasses;
			// console.log(this.filteredClasses)
		},
	},
	methods: {
		removeBookmark(classObject: firebase.firestore.DocumentData) {
			const payload = {
				classID: classObject.classID,
				userID: store.getters.userProfile.uid,
			};
			store.dispatch(Content.removeClassBookmark, payload);
		},
		addBookmark(classObject: firebase.firestore.DocumentData) {
			const payload = {
				classID: classObject.classID,
				userID: store.getters.userProfile.uid,
			};
			store.dispatch(Content.addClassBookmark, payload);
		},

		isBookmarked(classObject: firebase.firestore.DocumentData) {
			const classID = classObject.classID;
			if (
				this.bookmarkedClasses.some(
					(classs: firebase.firestore.DocumentData) =>
						classs.classID === classID
				)
			) {
				return true;
			}

			return false;
		},
		progressType(classID: string) {
			const progress = this.classProgress.filter(
				(classs: firebase.firestore.DocumentData) => classs.classID === classID
			);
			if (progress[0].isFinished) {
				return "Watched";
			}
			return "Watching";
		},
		hasProgressed(classObject: firebase.firestore.DocumentData) {
			const classID = classObject.classID;
			if (
				this.classProgress.some(
					(classs: firebase.firestore.DocumentData) =>
						classs.classID === classID
				)
			) {
				return true;
			}
			return false;
		},
		isRegistered(classID: string) {
			const isScheduled = this.userSchedule.filter(
				(scheduleItem: firebase.firestore.DocumentData) => {
					return scheduleItem.classID === classID;
				}
			);

			if (isScheduled.length === 0) {
				return false;
			}
			return true;
		},
		async openModal(featuredClass: any) {
			const modal = await modalController.create({
				component: Modal,
				cssClass: "modal-custom",
				componentProps: {
					title: "Join " + featuredClass?.name,
					content: featuredClass?.description || "No Description",
					videoimage: featuredClass?.imgURL,
					eventdate: featuredClass.date || {},
					eventname: featuredClass?.name,
					trainer: featuredClass?.trainerData.name,
					isLive: featuredClass?.isLive,
					video: featuredClass,
					isScheduled: this.isRegistered(featuredClass.classID),
				},
			});
			return modal.present();
		},
		//
		// Setter for the filter
		setFilter(finalResult: any) {
			this.filteredClasses = finalResult;
			this.toggleFilter = true;
		},
		// Clear Filter
		showAllClasses() {
			this.filteredClasses = this.content.featuredClasses;
			this.toggleFilter = false;
		},
		toggleHandler(toggleValues: any) {
			let filteredToggleValues = this.toggleValues;
			filteredToggleValues = toggleValues;
			this.toggleValues = filteredToggleValues;

			const toggleCheck =
				this.toggleValues["live"] ||
				this.toggleValues["onDemand"] ||
				this.toggleValues["liveReplay"] ||
				this.toggleValues["minute10"] ||
				this.toggleValues["minute30"] ||
				this.toggleValues["minute45"] ||
				this.toggleValues["minute60"] ||
				this.toggleValues["bookmarked"] ||
				this.toggleValues["hideWatched"];

			// Check if all Toggles are set to false (XOR)
			if (!toggleCheck) {
				this.toggleFilter = false;
				this.filteredClasses = this.content.featuredClasses;
			}
		},
		// Filter Modal
		async openFilterModal() {
			const modal = await modalController.create({
				component: FilterModal,
				componentProps: {
					classes: this.content.featuredClasses,
					setFilter: this.setFilter,
					showAllClasses: this.showAllClasses,
					filterBookmarked: this.filterBookmarked,
					hideWatched: this.hideWatched,
					toggleHandler: this.toggleHandler,
					toggleValuesInitial: this.toggleValues,
				},
			});
			return modal.present();
		},
		// Filter Bookmarked
		filterBookmarked(payload: any) {
			let classes = payload;
			classes = [];
			payload.forEach((item: firebase.firestore.DocumentData) => {
				if (this.isBookmarked(item)) {
					classes.push(item);
				}
			});
			return classes;
		},
		// Hide Watched Videos
		hideWatched(payload: any) {
			let classes = payload;
			classes.forEach((item: firebase.firestore.DocumentData) => {
				if (this.hasProgressed(item)) {
					const check = this.progressType(item["classID"]) || "";
					if (check === "Watched") {
						classes = classes.filter(
							(data: firebase.firestore.DocumentData) => data != item
						);
					}
				}
			});
			return classes;
		},
	},

	setup() {
		return {
			filterCircleOutline,
			bookmarkOutline,
			bookmark,
		};
	},
});
