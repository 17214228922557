import { ActionContext, ActionTree } from "vuex";
import { AugmentedActionContext } from "./types";
// import { Mutations, MutationTypes } from "./mutations";
import { Actions } from './interfaces'
import { State } from "./index";
import { RootState, store } from "@/store";
import * as fb from "../../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { ActionTypes as Auth } from "../auth";
import { MutationTypes } from "./mutations";

// Actions
// place the action definition as a string
// ==> { setX = "MODULE__SET_ X" }
export enum ActionTypes {
	addCoins = "ADD_COINS",
	transferCoins = "TRANSFER_COINS",
	userRefund = "REFUND_USER",
	reset = "RESET"
}

// Actual Action
export const actions: ActionTree<State, RootState> & Actions = {
	//more actions
	async [ActionTypes.addCoins]({ commit }: AugmentedActionContext, payload: any) {
		const { amount, purchasing } = payload;

		console.log(purchasing)

		const type = "Buy"
		const coach = "N/A"

		const client = store.getters.client.id;
		const uid = await store.getters.userID;
		const timestamp = Date.now();

		payload = { client, uid, type, coach, amount, timestamp };

		const transactionDoc = fb.db.collection("transactions").doc();
		const userDoc = fb.users.doc(uid)

		console.log('in actions', payload)

		//firebase transaction
		const { success, balance } = await fb.db.runTransaction(async (transaction) => {
			const user = await transaction.get(userDoc)
			const userData = user.data();

			const balanceBefore = userData?.balance ? userData?.balance : 0;
			const newBalance = balanceBefore + amount;

			await transaction.update(userDoc, { balance: newBalance });

			await transaction.set(transactionDoc, payload);

			return { success: true, balance: newBalance };
		})

		if (!success) {
			return {
				header: "Transaction Unsuccessful",
				message: `Error in Transaction, current balance is ${balance} FitCoins`,
				success: false
			}
		}

		await store.dispatch(Auth.userProfile, uid)
		return {
			header: "Transaction Successful!",
			message: `Your new balance is ${balance} FitCoins`,
			success: true
		};

	},
	async [ActionTypes.transferCoins]({ commit }: AugmentedActionContext, payload: any) {
		const { type, coach, amount } = payload;

		const client = store.getters.client.id;
		const uid = await store.getters.userID;
		const timestamp = Date.now()

		payload = { client, uid, type, coach, amount, timestamp };

		const transactionDoc = fb.db.collection("transactions").doc();
		const userDoc = fb.users.doc(uid);
		const coachDoc = fb.trainers.doc(coach);

		//firebase transaction
		const { success, balance, id } = await fb.db.runTransaction(async (transaction) => {
			// Check user has enough coins for transaction
			const user = await transaction.get(userDoc)
			const userData = user.data();

			const userBalanceBefore = userData?.balance ? userData?.balance : 0;
			const userNewBalance = userBalanceBefore - amount;

			if (userNewBalance < 0) {
				return { success: false, balance: userBalanceBefore };
			}

			//Get Coach Data
			const coach = await transaction.get(coachDoc);
			const coachData = coach.data();

			const coachBalance = coachData?.balance ? coachData?.balance : 0;
			const coachNewBalance = coachBalance + amount;

			//update documents
			await transaction.update(coachDoc, { balance: coachNewBalance });
			await transaction.update(userDoc, { balance: userNewBalance });
			await transaction.set(transactionDoc, { ...payload, userBalance: userNewBalance, coachBalance: coachNewBalance })

			return { success: true, balance: userNewBalance, id: transactionDoc.id };
		})

		if (!success) {
			return {
				header: "Transaction Unsuccessful",
				message: `Insufficient funds, current balance is ${balance} FitCoins`,
				success: false,
				transactionID: id
			}
		}

		return {
			header: "Transaction Successful!",
			message: `Your new balance is ${balance} FitCoins`,
			success: success,
			transactionID: id
		};
	},

	async [ActionTypes.userRefund]({ commit }: AugmentedActionContext, payload: any) {
		const { type, coach, amount, originalTransaction } = payload;

		const client = store.getters.client.id;
		const uid = await store.getters.userID;

		payload = { client, uid, type, coach, amount, originalTransaction };

		const transactionDoc = fb.db.collection("transactions").doc();
		const userDoc = fb.users.doc(uid);
		const coachDoc = fb.trainers.doc(coach);

		//firebase transaction
		const { success, balance, id } = await fb.db.runTransaction(async (transaction) => {
			// Check user has enough coins for transaction
			const user = await transaction.get(userDoc)
			const userData = user.data();

			const userBalanceBefore = userData?.balance ? userData?.balance : 0;
			const userNewBalance = userBalanceBefore + amount;

			if (userNewBalance < 0) {
				return { success: false, balance: userBalanceBefore };
			}

			//Get Coach Data
			const coach = await transaction.get(coachDoc);
			const coachData = coach.data();

			const coachBalance = coachData?.balance ? coachData?.balance : 0;
			const coachNewBalance = coachBalance - amount;

			//update documents
			await transaction.update(coachDoc, { balance: coachNewBalance });
			await transaction.update(userDoc, { balance: userNewBalance });
			await transaction.set(transactionDoc, payload)

			return { success: true, balance: userNewBalance, id: transactionDoc.id };
		})

		// if (!success){
		// 	return{
		// 		header: "Transaction Unsuccessful",
		// 		message: `Insufficient funds, current balance is ${balance} FitCoins`,
		// 		success: false,
		// 		transactionID: id
		// 	}
		// }

		return {
			header: "Refund Successful!",
			message: `${payload.amount} FitCoins have been refunded. Your new balance is ${balance} FitCoins`,
			success: success,
			transactionID: id
		};
	},

	// RESET
	async [ActionTypes.reset]({ commit }) {
		commit(MutationTypes.reset);
	},
};
