import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions, ActionTypes } from "./actions";
import { Store } from "./types";
import { State } from "./interfaces";
import { Module } from "vuex";
import { RootState } from "@/store";

//
const state: State = {
	remoteUser: {},
	callRoom: {},
};

//
const callModule: Module<State, RootState> = {
	state,
	mutations,
	actions,
	getters,
};

export { State, ActionTypes, Store };
export default callModule;
