import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions, ActionTypes } from "./actions";
import { Store } from "./types";
import { State } from "./interfaces";
import { Module } from "vuex";
import { RootState } from "@/store";

//[A.2] declare the state object
// ==> x: VALUE_OF_X
const state: State = {
	client: {},
	fetchClientError: undefined,
	value: undefined,
	// User Profile
	userProfile: {},
	userXP: {},
	fetchingProfile: false,
	authState: false,
};

//define and export the Auth module
const authModule: Module<State, RootState> = {
	state,
	mutations,
	actions,
	getters,
};

export { State, ActionTypes, Store };
export default authModule;
