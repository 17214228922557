import { MutationTree } from "vuex";
import { State } from "./index";
import { Mutations } from "./types";

// Define Mutation Function Names
export enum MutationTypes {
	setClasses = "SET_CLASSES",
	setCategories = "SET_CATEGORIES",
	setBookmarkedClasses = "SET_BOOOKMARKED_CLASSES",
	reset = "RESET",
	setCategoryNames = "SET_CATEGORY_NAMES"
}

// Define Actual Mutations according to method signatures
export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.setClasses](state, payload) {
		state.classes = payload;
	},

	[MutationTypes.setCategories](state, payload) {
		state.categories = payload;
	},

	[MutationTypes.setBookmarkedClasses](state, payload) {
		state.bookmarkedClasses = payload;
	},

	[MutationTypes.reset](state) {
		state.bookmarkedClasses = [];
	},

	[MutationTypes.setCategoryNames](state, payload) {
		state.categoryNames = payload;
	}
};
