import { MutationTree } from "vuex";
import { State } from "./interfaces";
import { Mutations } from "./types";

//
export enum MutationTypes {
	setCallRoom = "SET_VIDEO_CALL_ROOM",
	setRemoteUser = "SET_REMOTE_USER",
	resetCallStore = "RESET_CALL_STORE",
}

//
export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.setCallRoom](state, payload) {
		state.callRoom = payload;
	},
	[MutationTypes.setRemoteUser](state, payload) {
		state.remoteUser = payload;
	},
	[MutationTypes.resetCallStore](state) {
		state.callRoom = {};
		state.remoteUser = {};
	},
};
