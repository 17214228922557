<template>
	<div style="height: 100vh">
		<ion-header class="ion-no-border border-bottom">
			<ion-toolbar color="light">
				<ion-title class="text-lg" >Buy Coins</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="close()">X</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content color="light" class="ion-padding text-center" v-if="!loading">
                <ion-list >
                    <ion-radio-group>
                        <ion-item v-for="option in coinDisplay" :key="option.amount" class = 'coin-radio'>
                            <!-- <ion-label> </ion-label> -->
							<div v-if="!isWeb" class="spacing"></div>
                            <ion-img v-if="isWeb" src="assets/icons/coin.png" class="coin-icon"></ion-img>
							<ion-img v-if="!isWeb" src="assets/icons/coin.png" class="coin-icon-mobile"></ion-img>
							<div v-if="!isWeb" class="spacing"></div>
                            <ion-label class="coin-title"> {{option.title}} </ion-label>
							<!-- <div v-if="!isWeb" style="width:6vw;border:1px solid red"></div> -->
							<div v-if="!isWeb" class="spacing"></div>
                            <ion-label class="coin-cost"> {{option.price}} </ion-label>
							
                            <ion-radio 
                                @click="()=> choice = option"
                                :value="option.price"
								mode='md'
                            >
                            </ion-radio>
                        </ion-item>
                    
                    </ion-radio-group>
                </ion-list>
			<br/>
			<ion-button color="success" @click="initiatePurchase()">Confirm Transaction</ion-button>
			<!-- {{data}} -->
			<div class="spacer"></div>
		</ion-content>
		<ion-content color="light" v-if="loading">
			<div class="spin">
				<ion-spinner name="crescent"></ion-spinner>
			</div>
		</ion-content>
	</div>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonButton,
	IonButtons,
    IonRadio,
    IonRadioGroup,
	IonSpinner
} from "@ionic/vue";
import { defineComponent } from "vue";
import { modalController, isPlatform , IonImg} from "@ionic/vue";
// import { DatePicker } from "v-calendar";
import {
	star,
	starOutline,
	starHalf,
	thumbsDownOutline,
	thumbsUpOutline,
	trophyOutline,
	timerOutline,
	flameOutline,
	heartOutline,
} from "ionicons/icons";
import { ActionTypes as Coins } from "../../store/modules/coins";
import { store } from "@/store";
import { useRouter } from "vue-router";
import { openToastOptionsPayment } from "../../utils/toast";
import iap, {coinOptions, subOptions , iapSetup} from '../../utils/iap'

export default defineComponent({
	name: "CoinPurchaseModalIAP",
	setup() {
		// const store = useStore();
		const router = useRouter();
        const inApp = iapSetup(iap); 

		return {
			star,
			starOutline,
			starHalf,
			thumbsDownOutline,
			thumbsUpOutline,
			trophyOutline,
			timerOutline,
			flameOutline,
			heartOutline,
            inApp
		};
	},
	data() {
		return {
			purchasing: "",
			choice: {}, 
            options: coinOptions,
			loading: true,		
			coinDisplay: [],
			data: ""

		};
	},
	computed: {
		client() {
			return store.getters.client;
		},
		isWeb() {
			return store.getters.isWeb;
		},
	},

	components: {
		IonContent,
		IonHeader,
		IonTitle,
		IonToolbar,
		IonButton,
		IonButtons,
        IonRadio,
        IonRadioGroup,
        IonImg,
		IonSpinner,
	},
	beforeMount(){
		this.inApp.ready(() => {

			console.log("store ready");
			this.inApp.applicationUsername = "testing";
			
			let i = 0;

			for (const option of this.options){
				this.coinDisplay[i] = this.inApp.get(option.id)
				i++;
			}

			this.loading = false;
			
		});
		this.inApp
			.when("product")
			.approved(async (p) => {
				// Handle the product purchase
				// Get purchase IDs to ensure it is a coin purchase
				const ids = coinOptions.map(coin => coin.id);

				if (ids.includes(p.id)) {
					// completeTransaction returns p.verify() which
					// will chain on to the .verified to complete
					if (this.purchasing === p.id){
						return await this.completeTransaction(p)
					}
					
				}
				
			})
			.verified((p) => {
				return p.finish()
			});

		
	},
	methods: {
        initiatePurchase(){
			// Initiate Purchase in App Store
			this.inApp.order(this.choice.id);
			this.purchasing = this.choice.id;

        },
		async completeTransaction(product){
			// Get Amount of Coins from Title
			const amount = parseInt(product.title.split(" ")[0]);
			this.data = amount;
			this.purchasing = ""
			this.loading = true
			// Make DB Transaction
            const success = await store.dispatch(Coins.addCoins, {amount, purchasing: this.purchasing})
				.then( async ({header, message, success}) =>{
					this.loading = false;	
					await openToastOptionsPayment(header, message);
					return success;
					
				})

            await modalController.dismiss();

			// Return Verification to Complete Purchase
			if (success){
				return product.verify()
			}

			
		},
		async close() {
			await modalController.dismiss();
		},
	},
});
</script>
<style scoped>
.spacer {
	height: 150px;
}
.coin-icon{
    height: 8vh;
    padding-right: 3vw;
    padding-top: 1vh;
    padding-bottom: 1.5vh;
}
.coin-icon-mobile{
    /* height: 3.5vh; */
	width: 8.5vw;
    padding-right: 2vw;
    padding-top: 1vh;
    padding-bottom: 1.5vh;
}
.coin-radio{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.spin{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.coin-cost{
	font-size: 3.75vw;
}
.coin-title{
	font-size:4vw;
	min-width: fit-content
}
.spacing{
	width: 3%;
}

</style>
