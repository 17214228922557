<template>
	<div style="height: 100vh; margin-bottom: 6%">
		<ion-header class="ion-no-border border-bottom">
			<ion-toolbar color="light">
				<ion-title class="text-lg">Terms and Conditions</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="close()">X</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content style="margin-bottom: 6%" class="text-light ion-padding text-center" v-if="client.mode=='light'">
			<div color = "dark" v-html="client.terms" v-if="client.terms"></div>
			<div v-else>
				<ion-label>
					This Client has Yet to update their Ts and Cs :(
				</ion-label>
			</div>
		</ion-content>
		<ion-content style="margin-bottom: 6%" class="text-dark ion-padding text-center" v-if="client.mode=='dark'">
			<div color = "dark" v-html="client.terms" v-if="client.terms"></div>
			<div v-else>
				<ion-label>
					This Client has Yet to update their Ts and Cs :(
				</ion-label>
			</div>
		</ion-content>

	</div>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonButton,
	IonButtons,
	IonLabel,
	IonImg,
	IonCol,
	IonRow,
	IonGrid,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { modalController, isPlatform } from "@ionic/vue";
import {
	star,
	starOutline,
	starHalf,
	thumbsDownOutline,
	thumbsUpOutline,
	trophyOutline,
	timerOutline,
	flameOutline,
	heartOutline,
} from "ionicons/icons";
import { ActionTypes as Auth } from "../../store/modules/auth";
import { store } from "@/store";

export default defineComponent({
	name: "TermsModal",

	setup() {
		// const store = useStore();
		return {
			star,
			starOutline,
			starHalf,
			thumbsDownOutline,
			thumbsUpOutline,
			trophyOutline,
			timerOutline,
			flameOutline,
			heartOutline,
		};
	},
	data() {
		return {
			content: "",
		};
	},
	computed: {
		client() {
			return store.getters.client;
		},
	},

	components: {
		IonContent,
		IonHeader,
		IonTitle,
		IonToolbar,
		IonButton,
		IonButtons,
		IonLabel,

		// IonButtons,
		// IonLabel,
	},
	async beforeMount() {
		this.content = await store.getters.client.terms;
		this.id = await store.getters.client.id;
	},
	methods: {
		async close() {
			await modalController.dismiss();
		},
	},
});
</script>

<style scoped>
/* ion-button {
  --background-hover: #000;
  --background-hover-opacity: 1;
  float:right;
} */

</style>
