
import {
	IonContent,
	IonHeader,
	IonPage,
	IonIcon,
	IonImg,
	IonButton,
	// loadingController,
	IonCol,
	IonRow,
	IonGrid,
	IonCard,
	IonSlide,
	IonSlides,
	IonBadge,
	IonSpinner,
	modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
	calendar,
	personCircle,
	playCircleOutline,
	albumsOutline,
	barbellOutline,
	starSharp,
	menuOutline,
	trophyOutline,
} from "ionicons/icons";
import { store } from "../store";
// import * as fb from "../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { ActionTypes as Content } from "../store/modules/content";
import Navbar from "../components/Navbar.vue";
import Bottombar from "../components/Bottombar.vue";
import Modal from "./modals/modalVideo.vue";
export default defineComponent({
	name: "Home",
	components: {
		IonIcon,
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		Bottombar,
		IonSpinner,
		IonButton,
		IonCol,
		IonRow,
		IonGrid,
		IonCard,
	},

	data() {
		return {
			classesLoading: false,
		};
	},
	async beforeMount() {
		// const loading = await loadingController.create({
		// 	message: "Please wait...",
		// });

		const categories = await store.getters.getClasses;

		if (!categories || categories.length <= 0) {
			this.classesLoading = true;
			await store.dispatch(Content.setCategories);
			await store.dispatch(Content.setClasses);

			this.classesLoading = false;
		}
	},
	setup() {
		return {
			playCircleOutline,
			albumsOutline,
			barbellOutline,
			calendar,
			personCircle,
			starSharp,
			trophyOutline,
			menuOutline,
			IonSlide,
			IonSlides,
			IonImg,
			IonBadge,
		};
	},

	computed: {
		content() {
			const categories = store.getters.getCategories;
			const classes = store.getters.getClasses;
			const liveClasses = store.getters.getLiveClasses.slice(0,3);

			return {
				classes: classes,
				categories: categories,
				liveClasses: liveClasses,
			};
		},
		userSchedule() {
			return store.getters.getUserSchedule;
		},

		client() {
			return store.getters.client;
		}
	},

	watch: {
		//TODO, remove console.logs once complete
		content(newContent, oldContent) {
			// console.log("In Watcher");
			// console.log(newContent.classes);
			// console.log(newContent.categories);
		},
	},
	methods: {
		pullClasses(categoryName: string) {
			//navigate to videos vue
			this.$router.push({
				path: `/videos/${categoryName}`,
			});
		},
		goToClass(classID: string) {
			this.$router.push(`/videoPlayer/${classID}`);
		},
		isRegistered(classID: string) {
			const isScheduled = this.userSchedule.filter(
				(scheduleItem: firebase.firestore.DocumentData) => {
					return scheduleItem.classID === classID;
				}
			);

			if (isScheduled.length === 0) {
				return false;
			}

			return true;
		},
		async openModal(classObject: any) {
			// console.log(classObject);
			const modal = await modalController.create({
				component: Modal,
				cssClass: "modal-custom",
				componentProps: {
					title: "Join " + classObject?.name,
					content: classObject?.description || "No Description",
					videoimage: classObject?.imgURL,
					eventdate: classObject.date || {},
					eventname: classObject?.name,
					trainer: classObject?.trainerData.name,
					isLive: classObject?.isLive,
					video: classObject,
					isScheduled: this.isRegistered(classObject.classID),
				},
			});
			return modal.present();
		},
	},
});
