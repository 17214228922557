
import { IonContent, IonHeader, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
import Bottombar from "@/components/Bottombar.vue";
export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		Bottombar,
	},
	setup() {
		return {};
	},
});
