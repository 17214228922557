<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<div
				class="video-logo"
				v-bind:style="{
					'background-image': 'url(' + client.logo + ')',
				}"
			></div>
			<div id="test"></div>

			<div class="video-player">
				<ion-grid>
					<ion-row class="row-border-dark" style="margin-bottom: 10px">
						<ion-col size="12" size-lg="10" size-md="12" size-sm="12">
							<!-- <div @click="openTipModal()">
								Tip Coach
							</div> -->
							<div
								class="video-player-embed"
								v-bind:style="[
									video?.mirror ? { height: '88vh !important' } : {},
								]"
								id="embed"
								ref="embed"
							>
								<div
									id="videoPlayer"
									v-bind:class="[isActive ? 'ShowMe' : 'HideMe']"
								></div>
								<!-- Hack to hide the grey video icon for android -->
								<div
									v-if="isAndroid"
									:class="[posterHidden ? 'HideMe' : 'poster']"
									v-bind:style="{
										'background-image': 'url(' + client.favIconLink + ')',
									}"
								></div>
								<div
									v-if="video?.isLive"
									:class="[liveStarted ? 'HideMe' : 'messageOverlay']"
									v-bind:style="{
										display: 'flex',
										'align-items': 'center',
										'justify-content': 'center',
									}"
								>
									<h4 v-if="!liveStarted && video?.isLive">
										This class has been delayed. It will begin shortly
									</h4>
								</div>
							</div>
						</ion-col>
						<ion-col
							v-if="isWeb"
							class="web-only"
							size="12"
							size-lg="2"
							size-md="12"
							size-sm="12"
						>
							<ion-list
								v-if="width > 991"
								class="text-sm ion-padding-top"
								:style="getStyle()"
							>
								<ion-label class="text-center row-border-dark">
									<h2>Online Now {{ viewers.length + 1 }}</h2>
								</ion-label>
								<ion-item :color="getColor()">
									<ion-avatar slot="start">
										<img v-if="currentUser.avatar" :src="currentUser?.avatar" />
										<img v-else src="/assets/noavatar.jpg" />
									</ion-avatar>
									<ion-label
										><h2>
											{{ currentUser?.userName }}
										</h2>
										<h5 class="text-medium">
											{{ currentUser?.location }}
										</h5></ion-label
									>
									<!-- <ion-avatar slot="end"> -->
									<!-- <img src="/assets/Heart-2s-200px.gif" /> -->
									<!-- <img src="/assets/Heart-100s-200px.png" /> -->
									<!-- </ion-avatar> -->
								</ion-item>
								<ion-item
									:color="getColor()"
									v-for="(viewer, index) in viewers"
									:key="index"
								>
									<ion-avatar slot="start">
										<img v-if="viewer?.avatar" :src="viewer?.avatar" />
										<img v-else src="/assets/noavatar.jpg" />
									</ion-avatar>
									<ion-label
										><h2>{{ viewer?.userName }}</h2>
										<!-- <h5 class="text-medium">
											{{ viewer.location }}
										</h5> -->
									</ion-label>
									<!-- <ion-avatar slot="end"> -->
									<!-- <img src="/assets/Heart-100s-200px.png" /> -->
									<!-- </ion-avatar> -->
								</ion-item>
							</ion-list>
						</ion-col>

						<ion-col size="12">
							<div class="rating-display">
								<ion-icon
									color="primary"
									v-for="(starIcon, index) in createStars()"
									:key="index"
									:icon="starIcon"
								></ion-icon>
							</div>
							<div class="rating-average-display">({{ rating }})</div>
						</ion-col>
					</ion-row>
					<ion-row class="centered" v-if="video?.mirror && isWeb">
						<ion-col
							v-bind:style="[
								video?.mirror && !isWeb ? { display: 'none !important' } : {},
							]"
							size="3"
							size-lg="1"
						>
							<ion-avatar>
								<img :src="video?.trainerData.imgURL" />
							</ion-avatar>
						</ion-col>
						<ion-col
							v-bind:style="[
								video?.mirror && !isWeb ? { display: 'none !important' } : {},
							]"
							size="9"
							size-lg="9"
							size-sm="9"
						>
							<h4>{{ video?.name }}</h4>
							<h7 class="text-sm">{{ video?.trainerData.name }}</h7>

							<ion-row v-if="width < 992" style="margin-top: 1%">
								<span class="text-sm" v-if="viewers.length < 1"
									>{{ viewers.length + 1 }} User Watching Now</span
								>
								<span class="text-sm" v-else
									>{{ viewers.length + 1 }} Users Watching Now</span
								>
							</ion-row>
							<ion-row v-if="client?.coaches_enabled">
								<ion-button
									@click="goToChat({ chatroom: 'coaches' })"
									color="primary"
									size="medium"
									><ion-icon size="medium" :icon="chatboxOutline"></ion-icon
								></ion-button>
								<ion-button
									@click="openVideoBookings(video?.trainerData)"
									color="primary"
									size="medium"
									><ion-icon size="medium" :icon="videocamOutline"></ion-icon
								></ion-button>
								<ion-button
									@click="openCallBooking(video?.trainerData)"
									color="primary"
									size="medium"
									><ion-icon size="medium" :icon="callOutline"></ion-icon
								></ion-button>
								<ion-button
									@click="openTipModal(video?.trainerData)"
									color="primary"
									size="medium"
								>
									<ion-icon size="medium" :icon="cashOutline"></ion-icon>
								</ion-button>
							</ion-row>
						</ion-col>
						<ion-col
							size="12"
							size-lg="2"
							size-sm="12"
							class="ion-align-items-center"
						>
							<ion-button
								float-right
								:color="getColor()"
								:size="video?.mirror && !isWeb ? 'small' : 'block'"
								@click="getUserWatchStatus()"
								v-bind:style="[
									video?.mirror && !isWeb ? { width: '100% !important' } : {},
								]"
								>End Class</ion-button
							>
							<!-- <ion-button size="block" @click="onComplete()">
								Get statistics
							</ion-button> -->
						</ion-col>
						<ion-col
							v-if="isWeb && video?.mirror"
							class="mobile-only"
							size="12"
							size-lg="12"
							size-md="12"
							size-sm="12"
						>
							<ion-list
								v-if="width > 991"
								class="text-sm ion-padding-top"
								:style="getStyle()"
							>
								<ion-label class="text-center row-border-dark">
									<h2>Online Now {{ viewers.length + 1 }}</h2>
								</ion-label>
								<ion-item :color="getColor()">
									<ion-avatar slot="start">
										<img v-if="currentUser.avatar" :src="currentUser?.avatar" />
										<img v-else src="/assets/noavatar.jpg" />
									</ion-avatar>
									<ion-label
										><h2>
											{{ currentUser?.userName }}
										</h2>
										<h5 class="text-medium">
											{{ currentUser?.location }}
										</h5></ion-label
									>
									<!-- <ion-avatar slot="end"> -->
									<!-- <img src="/assets/Heart-2s-200px.gif" /> -->
									<!-- <img src="/assets/Heart-100s-200px.png" /> -->
									<!-- </ion-avatar> -->
								</ion-item>
								<ion-item
									:color="getColor()"
									v-for="(viewer, index) in viewers"
									:key="index"
								>
									<ion-avatar slot="start">
										<img v-if="viewer?.avatar" :src="viewer?.avatar" />
										<img v-else src="/assets/noavatar.jpg" />
									</ion-avatar>
									<ion-label
										><h2>{{ viewer?.userName }}</h2>
										<h5 class="text-medium">
											{{ viewer?.location }}
										</h5></ion-label
									>
									<!-- <ion-avatar slot="end"> -->
									<!-- <img src="/assets/Heart-100s-200px.png" /> -->
									<!-- </ion-avatar> -->
								</ion-item>
							</ion-list>
						</ion-col>
					</ion-row>
					<ion-row v-else style="margin-top: 10%">
						<ion-col size="3" size-lg="1" class="ion-align-items-center">
							<ion-avatar>
								<img :src="video?.trainerData.imgURL" />
							</ion-avatar>
						</ion-col>
						<ion-col size="9" size-lg="9" size-sm="9">
							<h4>{{ video?.name }}</h4>
							<span class="text-sm">{{ video?.trainerData.name }}</span>
							<ion-row v-if="client?.coaches_enabled">
								<ion-button @click="goToChat()" color="primary" size="medium"
									><ion-icon size="medium" :icon="chatboxOutline"></ion-icon
								></ion-button>
								<ion-button
									@click="openVideoBookings(video?.trainerData)"
									color="primary"
									size="medium"
									><ion-icon size="medium" :icon="videocamOutline"></ion-icon
								></ion-button>
								<ion-button
									@click="openCallBooking(video?.trainerData)"
									color="primary"
									size="medium"
									><ion-icon size="medium" :icon="callOutline"></ion-icon
								></ion-button>
								<ion-button
									@click="openTipModal(video?.trainerData)"
									color="primary"
									size="medium"
								>
									<ion-icon size="medium" :icon="cashOutline"></ion-icon>
								</ion-button>
							</ion-row>
						</ion-col>

						<ion-col
							size="12"
							size-lg="2"
							size-sm="12"
							class="ion-align-items-center"
						>
							<ion-button
								:color="getColor()"
								:size="video?.mirror && !isWeb ? 'small' : 'block'"
								@click="getUserWatchStatus()"
								v-bind:style="[
									video?.mirror && !isWeb ? { width: '100% !important' } : {},
								]"
								>End Class</ion-button
							>
						</ion-col>
						<ion-col
							v-if="!isWeb && !video?.mirror"
							class="mobile-only"
							size="12"
							size-lg="12"
							size-md="12"
							size-sm="12"
						>
							<ion-list class="text-sm ion-padding-top" :style="getStyle()">
								<ion-label class="text-center row-border-dark">
									<h2>Online Now {{ viewers.length + 1 }}</h2>
								</ion-label>
								<ion-item :color="getColor()">
									<ion-avatar slot="start">
										<img v-if="currentUser.avatar" :src="currentUser?.avatar" />
										<img v-else src="/assets/noavatar.jpg" />
									</ion-avatar>
									<ion-label
										><h2>
											{{ currentUser?.userName }}
										</h2>
										<h5 class="text-medium">
											{{ currentUser?.location }}
										</h5></ion-label
									>
									<!-- <ion-avatar slot="end"> -->
									<!-- <img src="/assets/Heart-2s-200px.gif" /> -->
									<!-- <img src="/assets/Heart-100s-200px.png" /> -->
									<!-- </ion-avatar> -->
								</ion-item>
								<ion-item
									:color="getColor()"
									v-for="(viewer, index) in viewers"
									:key="index"
								>
									<ion-avatar slot="start">
										<img v-if="viewer?.avatar" :src="viewer?.avatar" />
										<img v-else src="/assets/noavatar.jpg" />
									</ion-avatar>
									<ion-label
										><h2>{{ viewer?.userName }}</h2>
										<h5 class="text-medium">
											{{ viewer?.location }}
										</h5></ion-label
									>
									<!-- <ion-avatar slot="end"> -->
									<!-- <img src="/assets/Heart-100s-200px.png" /> -->
									<!-- </ion-avatar> -->
								</ion-item>
							</ion-list>
						</ion-col>
					</ion-row>
				</ion-grid>
			</div>
		</ion-content>
	</ion-page>
</template>
<script>
import {
	IonContent,
	IonPage,
	IonGrid,
	IonRow,
	IonCol,
	modalController,
	IonSpinner,
	IonAvatar,
	IonLabel,
	IonItem,
	IonList,
	IonButton,
	IonImg,
	IonBadge,
	isPlatform,
	toastController,
} from "@ionic/vue";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";
import useStore, { store } from "../store";
import Modal from "./modals/modalStatistics.vue";
import { ActionTypes as Video } from "../store/modules/video_player";
import VideoBookingModal from "./modals/modalCoachBookVideo.vue";
import CallBookingModal from "./modals/modalCoachBookCall.vue";
// import { ActionTypes as Auth } from "../store/modules/auth";
import {
	star,
	starOutline,
	starHalf,
	calendar,
	personCircle,
	playCircleOutline,
	albumsOutline,
	barbellOutline,
	starSharp,
	menuOutline,
	trophyOutline,
	bookmarkOutline,
	bookmark,
	mailOutline,
	videocamOutline,
	callOutline,
	chatboxOutline,
	cashOutline,
} from "ionicons/icons";
import { ActionTypes as Content } from "../store/modules/content";
import { Storage } from "@ionic/storage";
import Player from "@vimeo/player";
import { ActionTypes as HealthActions } from "../store/modules/health";
import axios from "axios";
import { ActionTypes as Auth } from "../store/modules/auth";
import modalTipCoachVue from "./modals/modalTipCoach.vue";
import { io } from "socket.io-client";
import moment from "moment";
import { getCategory } from "@/utils/utils";

export default defineComponent({
	name: "VideoPlayer",
	components: {
		IonContent,
		IonAvatar,
		IonLabel,
		IonItem,
		IonList,
		IonButton,
		IonPage,
		IonGrid,
		IonRow,
		IonCol,
	},
	data() {
		return {
			item: {},
			isActive: false,
			//myPlayer: undefined,
			userID: undefined,
			complete: false,
			watching: true,
			playing: false,
			compkey: "",
			playerAPI: undefined,
			playerElement: undefined,
			listener: undefined,
			startDate: undefined,
			endDate: undefined,
			modalOpen: false,
			posterHidden: false,
			healthAppConnected: false,
			isMobile: false,
			isAndroid: false,
			liveStarted: false,
			liveChecker: undefined,
			rating: "",
			progressInSeconds: 0,
		};
	},

	beforeMount: function () {
		this.socketIO.connect();
	},

	setup() {
		const router = useRouter();
		const width = ref(window.innerWidth);

		const options = {
			path: "/watching/",
			query: {
				uid: store.getters.userProfile?.uid,
				classID: store.getters.currentVideo?.classID,
				avatar: store.getters.userProfile?.avatar,
				userName: store.getters.userProfile?.userName,
			},
			transports: ["websocket"],
		};

		const socketIO = io(process.env.VUE_APP_SOCKET_SERVER_URL, options);

		socketIO.on("connect", () => {
			socketIO.emit("join");
			console.log("connected on socket");
		});

		socketIO.on("online", (onlineUsers) => {
			store.dispatch(Video.fetchCurrentViewingUsers, onlineUsers);
		});

		onMounted(async () => {
			window.addEventListener("resize", () => {
				width.value = window.innerWidth;
			});
		});
		onUnmounted(() => {
			window.removeEventListener("resize", () => {
				width.value = window.innerWidth;
			});
		});

		console.log(width);
		return {
			socketIO,
			width,
			router,
			star,
			starOutline,
			starHalf,
			calendar,
			personCircle,
			playCircleOutline,
			albumsOutline,
			barbellOutline,
			starSharp,
			menuOutline,
			trophyOutline,
			bookmarkOutline,
			bookmark,
			mailOutline,
			videocamOutline,
			callOutline,
			chatboxOutline,
			cashOutline,
		};
	},
	async ionViewDidEnter() {
		const localStore = new Storage();
		localStore.create();

		if (this.video.isLive) {
			//check every 1.5s if the stream has started
			this.liveChecker = setInterval(() => {
				const url = this.video.video.url;

				const myURL = url.slice(
					url.lastIndexOf("com/") + 4,
					url.lastIndexOf("/")
				);

				axios
					.get("https://api.vimeo.com/videos/" + myURL, {
						headers: {
							Authorization: "bearer " + process.env.VUE_APP_VIMEO_KEY, //the token is a variable which holds the token
							Accept: "application/vnd.vimeo.*+json;version=3.4",
						},
					})
					.then((res) => {
						if (res.data.embed.badges.live.streaming) {
							this.liveStarted = true;
							// console.log("LIVEEEEEE STREEEEEEEAM");
						}
					});
			}, 1500);
		}

		this.userID = store.getters.userProfile.uid;
		const payload = {
			classID: store.getters.currentVideo?.classID,
			userID: store.getters.userProfile.uid,
		};

		store.dispatch(Video.addUserClassProgress, {
					currentTime: 0,
					classID: store.getters.currentVideo?.classID,
					userID: store.getters.userProfile.uid,
					currentlyViewing: true,
					isFinished: false
				})
		store.dispatch(Video.updateVideoWatchMilestone, {
			class: store.getters.currentVideo,
			userID: this.userID,
		});
		store.dispatch(Video.addUserWatchedVideo, {
			...payload,
			video: this.video,
		});

		// ADD EXPERIENCE POINTS FOR WATCHING A CLASS
		store
			.dispatch(Auth.addXP, { name: "WatchClass", value: 10 })
			//TODO: uncomment this at a later stage
			// .then(async () => {
			// 			const toast = await toastController.create({
			// 			header: "Achievement Unlocked ",
			// 			message: `Started A Class \n 10 XP`,
			// 			position: "top",
			// 			duration: 2000,
			// 			color: "dark"
			// 		});
			// 		return toast.present();
			// })
			.catch((error) => {
				console.log("Error adding XP!", error.message);
			});

		await store.dispatch(HealthActions.checkPermissions);
		this.healthAppConnected = store.getters.healthAppConnected;
	},
	methods: {
		goToChat() {
			this.$router.push({
				path: "/chat/coaches/" + this.video?.trainerData.id,
			});
		},
		getColor() {
			if (this.client.mode === "dark") {
				return "light";
				// return "dark"
			}
			// return "light";
			return "dark";
		},
		async openVideoBookings(trainer) {
			console.log(trainer);
			const modal = await modalController.create({
				component: VideoBookingModal,
				cssClass: "modal-custom",
				componentProps: {
					trainer: trainer,
				},
			});
			return modal.present();
		},
		async openCallBooking(trainer) {
			// console.log(trainer);
			const modal = await modalController.create({
				component: CallBookingModal,
				cssClass: "modal-custom",
				componentProps: {
					trainer: trainer,
				},
			});
			return modal.present();
		},
		async openTipModal(trainer) {
			const modal = await modalController.create({
				component: modalTipCoachVue,
				cssClass: "modal-custom",
				componentProps: {
					trainer: trainer,
				},
			});
			return modal.present();
		},
		getStyle() {
			if (this.client.mode === "dark") {
				return "background: var(--ion-color-light)";
				// return "background: var(--ion-color-dark)";
			}
			// return "background: var(--ion-color-light)";
			return "background: var(--ion-color-dark)";
		},
		async getUserWatchStatus() {
			this.onPause();

			this.endDate = new Date();

			const timeRemaining =
				(await this.myPlayer.getDuration()) / 60 -
				(await this.myPlayer.getCurrentTime()) / 60;

			// If time remaining less than or equal to 1 min => return onComplete()
			if (timeRemaining <= 1) {
				return this.onComplete();
			}

			// Get video duration
			const videoDuration = this.video.duration;

			// Get watched duration
			const duration = await this.myPlayer.getCurrentTime();

			// MET (metabolic equivalent for task)
			const MET = 6;

			let calories = "";

			let caloriesBurnt = 0;
			// Total calories burned = Duration (in minutes)*(MET*3.5*weight in kg)/200

			// Calories Calculation
			if (!(this.currentUser?.weight === "") && this.currentUser?.weight > 0) {
				const weight = this.currentUser?.weight;
				caloriesBurnt = ((duration / 60) * (MET * 3.5 * weight)) / 200;
				calories = `±  ${caloriesBurnt.toFixed(3)}  cal`;
				const kcal = caloriesBurnt / 1000;

				if (
					this.healthAppConnected &&
					(isPlatform("ios") || isPlatform("android"))
				) {
					await store.dispatch(HealthActions.connectHealthApp);

					const healthData = {
						startDate: this.startDate,
						endDate: new Date(),
						// value: getCategory(this.video?.categoryName),
						value: kcal,
						dataType: "calories",
					};
					store.dispatch(HealthActions.setHealthData, healthData);
				}
			} else {
				if (
					this.healthAppConnected &&
					(isPlatform("ios") || isPlatform("android"))
				){
					calories = "Please update your latest weight in your health app to calculate calories";
				} else {
				calories = "Please update your weight in your profile settings to calculate calories";
				}
			}

			store.dispatch(Video.fetchWatchHistory, { userID: this.userID });
			await store.dispatch(Video.fetchWatchHistory, { userID: this.userID });
			store.dispatch(Content.fetchBookmarkedClasses, { userID: this.userID });
			if (!this.complete) {
				const modal = await modalController.create({
					component: Modal,
					cssClass: "modal-custom",
					componentProps: {
						title: timeRemaining > 1 ? "Leaving Aleady?" : "Workout Completed",
						status: "incomplete",
						duration,
						calories,
						client: this.client,
						class: this.video.classID,
						completed: timeRemaining <= 1,
					},
				});
				return modal.present();
			}
		},
		async onPlay() {
			// console.log("Playing");
			this.startDate = new Date();

			this.complete = false;
			this.playing = true;

			if (this.isAndroid) setTimeout(this.setPosterHidden, 750);
		},
		setPosterHidden() {
			this.posterHidden = true;
		},
		async onPause() {
			console.log("Pausing");
			this.playing = false;

			if (this.myPlayer) {
				this.myPlayer
					.pause()
					.then(() => {
						console.log("PAUSED");
					})
					.catch((err) => {
						console.log("ERROR", err);
					});
				// Check if complete = true => Skip
				if (this.complete) {
					return;
				}
			}
		},

		async updateClassProgress(eventData){
			const diff = eventData.seconds - this.progressInSeconds;
			const atTheBeginning = eventData.seconds >= 1.2 && eventData.seconds <= 1.55;
			if( diff >= 10 || diff <= -10 || atTheBeginning){
				console.log('called');
				this.progressInSeconds = eventData.seconds;
				
				await store.dispatch(Video.addUserClassProgress, {
					currentTime: this.progressInSeconds,
					classID: store.getters.currentVideo?.classID,
					userID: store.getters.userProfile?.uid,
					currentlyViewing: true,
					isFinished: false
				})
				.catch((err) => console.log('ERRROR',err));
			}
		},
		cleanup() {
			const obj = document.getElementById("videoPlayer");
			if (obj) {
				obj.remove();
				delete this.playerElement;
				this.myPlayer.destroy();
			}
			this.socketIO.disconnect();
		},
		createElements() {
			const embed = this.$refs.embed;
			// console.log('VIDEO ELEMENT', JSON.stringify(this.video));
			this.myPlayer = new Player(embed, {
				url: this.video.video.url,
				responsive: true,
				autoplay: true,
				controls: true,
				playbar: true,
			});
			this.myPlayer.on("play", this.onPlay);
			this.myPlayer.on("pause", this.onPause);
			this.myPlayer.on("ended", this.onComplete);
			this.myPlayer.on("ready", this.onReady);
			this.myPlayer.on("timeupdate", this.updateClassProgress);

			if (this.progressInSeconds > 0)
				this.myPlayer.setCurrentTime(this.progressInSeconds);
			this.myPlayer.play();
		},
		async onComplete() {
			// console.log("completed");
			this.complete = true;

			this.endDate = new Date();

			// Get video duration
			const videoDuration = this.video.duration;

			// Get watched duration
			const duration = await this.myPlayer.getCurrentTime();

			// MET (metabolic equivalent for task)
			const MET = 6;

			let calories = 0;
			let caloriesBurnt = 0;

			// Calories Calculation
			if (!(this.currentUser?.weight === "") && this.currentUser?.weight > 0) {
				const weight = this.currentUser?.weight;
				caloriesBurnt = ((duration / 60) * (MET * 3.5 * weight)) / 200;
				calories = `±  ${caloriesBurnt.toFixed(3)}  cal`;
				const kcal = caloriesBurnt / 1000;

				if (
					this.healthAppConnected &&
					(isPlatform("ios") || isPlatform("android"))
				) {
					await store.dispatch(HealthActions.connectHealthApp);

					const healthData = {
						startDate: this.startDate,
						endDate: new Date(),
						// value: getCategory(this.video?.categoryName),
						value: kcal,
						dataType: "calories",
					};
					store.dispatch(HealthActions.setHealthData, healthData);
				}
			} else {
				if (
					this.healthAppConnected &&
					(isPlatform("ios") || isPlatform("android"))
				){
					calories = "Please update your latest weight in your health app to calculate calories";
				} else {
				calories = "Please update your weight in your profile settings to calculate calories";
				}
			}

			const timeRemaining =
				(await this.myPlayer?.getDuration()) -
				(await this.myPlayer?.getCurrentTime());

			// const payload = {
			// 	startTime: (new Date()).toISOString(),
			// 	currentTime: await this.myPlayer.getCurrentTime(),
			// 	calories: caloriesBurnt,
			// 	heartRate,
			// 	isFinished: true,
			// }
			// this.socketIO.emit('completed', payload );

			// .then(async () => {
			// ADD EXPERIENCE POINTS FOR FINISHING A CLASS
			store
				.dispatch(Video.setVideoComplete, {
					classID: store.getters.currentVideo?.classID,
					userID: store.getters.userProfile.uid,
					currentlyViewing: false,
					currentTime: await this.myPlayer?.getDuration(),
					isFinished: true,
					calories: caloriesBurnt,
					// heartRate,
			})
				// .then(async () => {
					// ADD EXPERIENCE POINTS FOR FINISHING A CLASS
					store
						.dispatch(Auth.addXP, { name: "FinishClass", value: 50 })
						.catch((error) => {
							console.log("Error adding XP!", error.message);
						});
					// UPDATE VIDEO DAILY WATCH MILESTONE
					store.dispatch(Video.updateDailyWatchMilestone, {
						class: store.getters.currentVideo,
						userID: this.userID,
					});
					// OPEN STATISTICS MODAL
					if (this.modalOpen == false) {
						this.modalOpen = true;
						const modal = await modalController.create({
							component: Modal,
							cssClass: "modal-custom",
							componentProps: {
								title: "Workout Completed",
								duration,
								calories,
								client: this.client,
								class: this.video.classID,
								completed: true,
							},
						});
						return modal.present();
					}
				// });
		},
		onReady() {
			this.isActive = true;
			// this.myPlayer.volume(1);
			this.myPlayer.setVolume(100);

			// console.log("setting volume")
		},
		createStars() {
			let rating = this.video.videoRating;
			const unrated = Math.floor(5 - rating);
			console.log("rating", rating);
			console.log(unrated);
			this.rating = rating !== undefined ? rating.toFixed(2) + "/5" : "Unrated";
			const stars = [];
			while (rating >= 1) {
				rating--;
				stars.push(star);
			}
			if (rating != 0) {
				if (rating >= 0.3) {
					stars.push(starHalf);
				} else {
					stars.push(starOutline);
				}
			}

			for (let i = 0; i < unrated; i++) {
				stars.push(starOutline);
			}
			// console.log(stars)
			return stars;
		},
	},
	async mounted() {
		const progressData = await store.dispatch(Video.fetchClassProgressData, {
			classID: store.getters.currentVideo?.classID,
			userID: store.getters.userProfile?.uid,
		});
		if (progressData.currentTime > 0) {
			this.progressInSeconds = progressData.currentTime;
		}
		this.createElements();
		this.healthAppConnected = store.getters.healthAppConnected;
		this.isAndroid = isPlatform("android");
		this.isMobile = isPlatform("ios") || this.isAndroid;
	},
	computed: {
		video() {
			const video = store.getters.currentVideo;
			return video;
		},
		videoRecord() {
			const record = store.getters.record;
			return record;
		},
		client() {
			return store.getters.client;
		},
		viewers() {
			return store.getters.videoViewers;
		},
		currentUser() {
			return store.getters.userProfile;
		},
		isWeb() {
			return store.getters.isWeb;
		},
	},

	ionViewWillLeave() {
		clearInterval(this.liveChecker);
		if (this.playing || !this.complete) {
			this.watching = false;
			this.onPause();
			this.myPlayer.destroy();
		}
		this.cleanup();
		store.dispatch(Video.clearWatchList);
	},
	unmounted() {
		this.cleanup();
	},
	async ionViewWillEnter() {
		this.createElements();
	},
});
</script>
<style scoped>
.HideMe {
	display: none;
}
.ShowMe {
	display: block;
}
.video-player {
	background: #000;
	height: 100vh;
	color: #fff;
}
.video-close-btn {
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 10;
}
.poster {
	background: center center;
	background-repeat: no-repeat;
	background-size: 20%;
	background-color: #000;
	bottom: 0;
	left: 0;
	opacity: 1;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 10;
	animation: animatedBackground 1s linear infinite alternate;
}
.messageOverlay {
	background: center center;
	background-repeat: no-repeat;
	background-size: 20%;
	background-color: #000;
	bottom: 0;
	left: 0;
	opacity: 1;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
}
.centered {
	position: fixed;
	top: 75%;
	padding-left: 2.5%;
	padding-right: 1.75%;
	width: 100vw;
}
.rating-display {
	/* border:1px solid red; */
	justify-content: center;
	display: flex;
	margin-bottom: 0.3%;
	margin-top: 0.4%;
}
.rating-average-display {
	justify-content: center;
	display: flex;
	font-size: 1.3vh;
}
@keyframes animatedBackground {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(1.2);
	}
}
</style>
