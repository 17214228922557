<template>
	<ion-content>
		<ion-header
			class="ion-no-border border-bottom"
			style="position: fixed; top: 0;"
		>
			<ion-toolbar color="light">
				<ion-title class="text-md">Profile Update</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="close()">X</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-list>
			<div style="height: 50px; width: 100%"></div>
			<ion-item color="light">
				<ion-label position="stacked">Name</ion-label>
				<ion-input
					:placeholder="name"
					type="text"
					clear-on-edit="true"
					v-model="payload.name"
					v-bind:value="payload.name"
					v-on:ionInput="payload.name = $event.target.value"
				></ion-input>
			</ion-item>
			<ion-item color="light">
				<ion-label position="stacked">Username</ion-label>
				<ion-input
					:placeholder="userProfile.userName"
					type="text"
					clear-on-edit="true"
					v-model="payload.userName"
					v-bind:value="payload.userName"
					v-on:ionInput="payload.userName = $event.target.value"
				></ion-input>
			</ion-item>
			<ion-item color="light">
				<ion-label position="stacked">Email Address</ion-label>
				<ion-input
					:placeholder="userProfile.email"
					type="text"
					clear-on-edit="true"
					v-model="payload.email"
					v-bind:value="payload.email"
					v-on:ionInput="payload.email = $event.target.value"
					:readonly="true"
				></ion-input>
				<!-- DISABLED UNTIL WE ARE ABLE TO CUSTOMIZE MESSAGES PER CLIENT -->
				<!-- <ion-button
					color="primary"
					size="small"
					v-if="!isEmailVerified && authProvider === 'password'"
					@click="sendEmailVerificationLink()"
					>VERIFY EMAIL</ion-button
				> -->
			</ion-item>
			<!-- Date Picker for Web -->
			<ion-item color="light" v-if="isWeb">
				<ion-label position="stacked">Birthday</ion-label>
				<ion-input
					:placeholder="DateOfBirth || 'Pick a date'"
					type="text"
					readonly
				></ion-input>
				<div>
					<DatePicker mode="date" v-model="payload.dob" />
				</div>
				<ion-label v-if="payload.dob" position="stacked"
					>Selected date: {{ newDate }}</ion-label
				>
				<br />
			</ion-item>
			<!-- Date Picker for Mobile -->
			<ion-item color="light" v-else>
				<ion-label position="stacked"
					>Birthday <small class="tiny-text">(YYYY/MM/DD)</small></ion-label
				>
				<ion-datetime
					:interface="selectType"
					display-format="YYYY/MM/DD"
					:placeholder="DateOfBirth || 'Pick a date'"
					v-model="payload.dob"
				></ion-datetime>
			</ion-item>
			<ion-item color="light">
				<ion-label position="stacked">Location</ion-label>
				<ion-input
					:placeholder="userProfile.location"
					type="text"
					clear-on-edit="true"
					v-model="payload.location"
					v-bind:value="payload.location"
					v-on:ionInput="payload.location = $event.target.value"
				></ion-input>
			</ion-item>
			<ion-item color="light">
				<ion-label position="stacked">Gender</ion-label>
				<ion-select
					:interface="selectType"
					v-model="payload.gender"
					:placeholder="userProfile.gender"
				>
					<ion-select-option v-bind:value="'Female'">Female</ion-select-option>
					<ion-select-option v-bind:value="'Male'">Male</ion-select-option>
					<ion-select-option v-bind:value="'Other'">Other</ion-select-option>
				</ion-select>
			</ion-item>
			<ion-item color="light">
				<ion-label position="stacked">Height [cm]</ion-label>
				<ion-input
					:placeholder="userProfile.height"
					type="text"
					clear-on-edit="true"
					v-model="payload.height"
					v-bind:value="payload.height"
					v-on:ionInput="payload.height = $event.target.value"
				></ion-input>
			</ion-item>
			<ion-item color="light">
				<ion-label position="stacked">Weight [kg]</ion-label>
				<ion-input
					:placeholder="userProfile.weight"
					type="text"
					clear-on-edit="true"
					v-model="payload.weight"
					v-bind:value="payload.weight"
					v-on:ionInput="payload.weight = $event.target.value"
				></ion-input>
			</ion-item>
			<ion-item color="light">
				<ion-label position="stacked">Fitness Level</ion-label>
				<ion-select
					:interface="selectType"
					v-model="payload.level"
					:placeholder="userProfile.level"
				>
					<ion-select-option v-bind:value="'Beginner'"
						>Beginner</ion-select-option
					>
					<ion-select-option v-bind:value="'Intermediate'"
						>Intermediate</ion-select-option
					>
					<ion-select-option v-bind:value="'Advanced'"
						>Advanced</ion-select-option
					>
				</ion-select>
			</ion-item>
			<ion-item text-center color="light" v-if="!profileUpdateLoading">
				<div class="center-button">
					<ion-button
						class="ion-padding save-btn"
						color="primary"
						size="block"
						@click="updateUserProfile()"
						>Save Profile</ion-button
					>
				</div>
			</ion-item>
			<ion-item text-center color="light" v-if="profileUpdateLoading">
				<div class="center-button">
					<ion-button color="primary" size="block" :disabled="true">
						<ion-spinner name="crescent"></ion-spinner>
					</ion-button>
				</div>
			</ion-item>
		</ion-list>
	</ion-content>
</template>
<script>
import {
	alertController,
	IonSelect,
	IonSelectOption,
	IonDatetime,
} from "@ionic/vue";

import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import * as fb from "../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
// import { Storage } from "@ionic/storage";
import { store } from "../../store";
import { ActionTypes as Auth } from "../../store/modules/auth";
import moment from "moment";
import { toDateTime, getBlobfromImg } from "../../utils/utils";
import localStore from "../../utils/localstore";
import { DatePicker } from "v-calendar";

export default defineComponent({
	name: "modalUpdateProfile",
	components: {
		// IonContent,
		IonSelect,
		IonSelectOption,
		IonDatetime,
		DatePicker,
	},
	setup() {
		let selectType = "";
		if (store.getters.isWeb) {
			selectType = "popover";
		} else {
			selectType = "action-sheet";
		}
		return {
			selectType,
		};
	},
	data() {
		return {
			// userPhoto: null,
			payload: {
				avatar: "",
				name: "",
				userName: "",
				email: "",
				gender: "",
				dob: "",
				location: "",
				height: "",
				weight: "",
				level: "",
			},
			profileUpdateLoading: false,
			dateOfBirth: "",
			date: undefined,
			name: "",
			isEmailVerified: false,
			authProvider: "",
		};
	},
	async beforeMount() {
		// Get User ID
		const user = store.getters.userProfile;
		const uid = user?.uid;

		// Get email verification status
		this.isEmailVerified = firebase.auth().currentUser.emailVerified;
		console.log("Email Verified", this.isEmailVerified);

		// Get Auth Provider
		this.authProvider = firebase.auth().currentUser.providerData[0].providerId;

		const userProfile = await store
			.dispatch(Auth.userProfile, uid)
			.then(() => true)
			.catch((error) => {
				console.log(error);
				// this.date = userProfile.dob
			});

		if (user.name) {
			const firstName = user.name.first;
			if (firstName) {
				this.name = user.name.first + " " + user.name.last;
				console.log("full name", this.name);
			} else {
				this.name = user.name;
				console.log("name", this.name);
			}
		} else {
			this.name = "";
		}
	},
	methods: {
		// DISABLED UNTIL WE ARE ABLE TO CUSTOMIZE MESSAGES PER CLIENT
		// SEND EMAIL VERIFICATION LINK
		// async sendEmailVerificationLink() {
		// 	firebase
		// 		.auth()
		// 		.currentUser.sendEmailVerification()
		// 		.then(async () => {
		// 			// console.log("Email Verification Link Sent!");
		// 			const alert = await alertController.create({
		// 				header: "Verification Link Sent",
		// 				message: `Email has been sent to ${this.userProfile.email}`,
		// 				buttons: ["OK"],
		// 			});
		// 			alert.present();
		// 		});
		// },
		async updateUserProfile() {
			(this.profileUpdateLoading = true),
				(this.payload.userName = this.payload.userName.toLowerCase());

			const payload = { ...this.payload };
			const updatedUser = this.filterEmptyValues();
			// Get User ID
			
			const uid = store.getters.userProfile?.uid;

			// Update Firebase fields
			await fb.users.doc(uid).update(updatedUser);

			// // Update Store
			await store
				.dispatch(Auth.userProfile, uid)
				.then(() => (this.profileUpdateLoading = false))
				.catch((error) => {
					console.log(error);
				});
			modalController.dismiss();
		},
		filterEmptyValues() {
			const update = { ...store.getters.userProfile };
			// const name = this.userProfile.name;
			const payload = { ...this.payload };
			if (payload.name !== "" || payload.name !== "") {
				update.name = payload.name;
			}

			Object.keys(payload).forEach(function (key) {
				if (key === "dob" && payload[key] !== "") {
					const date = moment(payload[key]).toDate();
					const timestamp = firebase.firestore.Timestamp.fromDate(date);
					update[key] = timestamp;
				} else if (payload[key] !== "") {
					update[key] = payload[key];
				}
			});
			return update;
		},
		// Close Modal
		async close() {
			await modalController.dismiss();
		},
	},
	computed: {
		isWeb() {
			return store.getters.isWeb;
		},
		userProfile() {
			const userProfileData = store.getters.userProfile;
			console.log(userProfileData);
			return userProfileData;
		},
		// Conversion of dob to be displayed on date-picker
		DateOfBirth() {
			const dob = toDateTime(this.userProfile.dob?.seconds);
			return dob;
		},
		// Date Format Conversion for DatePicker
		newDate() {
			let _newDate = " ";
			if (this.payload.dob) {
				const date = new Date(this.payload.dob);
				const year = date.getFullYear();
				let month = date.getMonth() + 1;
				let day = date.getDate();

				if (month < 10) {
					month = "0" + month;
				}
				if (day < 10) {
					day = "0" + day;
				}
				_newDate = year + "/" + month + "/" + day;
			}
			return _newDate;
		},
	},
	watch: {
		userProfile(newContent, oldContent) {
			this.dateOfBirth = toDateTime(newContent.dob?.seconds);
			// this.payload.dob = this.dateOfBirth;
		},
	},
});
</script>
<style scoped>
ion-row {
	border-bottom: 1px solid lightgray;
	margin-bottom: 1em;
}
ion-input {
	font-size: 1.2em;
}
ion-radio {
	font-size: 0.5em;
	margin-right: 1em;
	padding-top: 0.8em;
}
ion-button {
	width: 45%;
}
.center-button {
	position: relative;
	width: 100%;
	/* height: 100%; */
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 4%;
}
.save-btn {
	padding: 5px;
}
.tiny-text {
	font-size: 65%;
}
</style>
