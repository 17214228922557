import { ActionContext, ActionTree } from "vuex";
import { AugmentedActionContext } from "./types";
import { MutationTypes } from "./mutations";
import { Actions } from './interfaces'
import { State } from "./index";
import { RootState, store } from "@/store";
import * as fb from "../../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import moment from "moment"
import { ActionTypes as Auth } from "../auth";
import { googleAuthPlugin } from "@/utils/utils";

// Actions
// place the action definition as a string
// ==> { setX = "MODULE__SET_ X" }
export enum ActionTypes {
	makeBooking = "MAKE_BOOKING",
	cancelBooking = "CANCEL_BOOKING",
	reset = "RESET",
	// transferCoins = "TRANSFER_COINS"
}

// Actual Action
export const actions: ActionTree<State, RootState> & Actions = {
	//more actions
	async [ActionTypes.makeBooking]({ commit }: AugmentedActionContext, bookingInfo: any) {
		fb.bookings.add(bookingInfo)

		//add to the user schedule
		fb.users
			.doc(bookingInfo.user)
			.collection("schedule")
			.doc(bookingInfo.transactionID)
			.set({
				added: true,
				addedAt: firebase.firestore.FieldValue.serverTimestamp(),
				date: new firebase.firestore.Timestamp(
					new Date(bookingInfo.date).getTime() / 1000,
					0,
				),
				classID: null,
				className: bookingInfo.type === "consultation" ? "Consultation with " + bookingInfo.coachName : "One-on-one with " + bookingInfo.coachName,
				coachName: bookingInfo.coachName,
				trainerData: bookingInfo.trainerData,
				duration: bookingInfo.duration,
				transactionID: bookingInfo.transactionID,
				dateISO: bookingInfo.date,
				amount: bookingInfo.amount,
				uid: bookingInfo.user,
				dateString: new Date(bookingInfo.date).toUTCString(),
				timeString: moment(bookingInfo.date).format('HH:mm'),

			})
			.then(() => {
				// ADD EXPERIENCE POINTS FOR SCHEDULING A CLASS
				store
					.dispatch(Auth.addXP, { name: "ScheduleClass", value: 20 })
					.catch((error) => {
						console.log("Error adding XP!", error.message);
					});
			});
	},

	[ActionTypes.cancelBooking]({ commit }: AugmentedActionContext, bookingInfo: any) {
		//delete from schedule
		console.log(bookingInfo)
		fb.users
			.doc(store.getters.userProfile.uid)
			.collection("schedule")
			.doc(bookingInfo.transactionID)
			.delete()

		//delete booking 
		fb.bookings
			.where("transactionID", "==", bookingInfo.transactionID)
			.get()
			.then((querySnap: firebase.firestore.QuerySnapshot) => {
				const toDelete = querySnap.docs[0].id

				fb.bookings.doc(toDelete).delete()

			})

		//apply cancellation policy 
	},

	// RESET
	async [ActionTypes.reset]({ commit }) {
		commit(MutationTypes.reset);
	},
};
