import { MutationTree } from "vuex";
import { State } from "./interfaces";
import { Mutations } from "./types";

export enum MutationTypes {
	setHealthAppConnected = "SET_HEALTH_APP_CONNECTED",
	setUserHeight = "SET_USER_HEIGHT",
	setUserWeight = "SET_USER_WEIGHT",
	setUserGender = "SET_USER_GENDER",
	setUserDOB = "SET_USER_DOB",
	setUnAuthorized = "SET_UNAUTHORIZED",
	setPermissionsGranted = "SET_PERMISSIONS_GRANTED",
	setHeartRate = "SET_HEART_RATE",
	setCaloriesBurnt = "SET_CALORIES_BURNT",
	reset = "RESET",
}

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.setHealthAppConnected](state, payload) {
		state.userHealthData.healthAppConnected = payload;
	},
	[MutationTypes.setUserHeight](state, payload) {
		state.userHealthData.height = payload;
	},
	[MutationTypes.setUserWeight](state, payload) {
		state.userHealthData.weight = payload;
	},
	[MutationTypes.setPermissionsGranted](state, payload) {
		state.userHealthData.permissionsGranted = payload;
	},
	[MutationTypes.setHeartRate](state, payload) {
		state.userHealthData.heartRate = payload;
	},
	[MutationTypes.setCaloriesBurnt](state, payload) {
		state.userHealthData.caloriesBurnt = payload;
	},
	[MutationTypes.setUnAuthorized](state, payload) {
		state.userHealthData.unAuthorized = payload;
	},
	// IOS Only
	[MutationTypes.setUserGender](state, payload) {
		state.userHealthData.gender = payload;
	},
	// IOS Only
	[MutationTypes.setUserDOB](state, payload) {
		state.userHealthData.dob = payload;
	},
	[MutationTypes.reset](state) {
		state.userHealthData = {
			height: null,
			weight: null,
			gender: "",
			heartRate: null,
			dob: "",
			activity: "",
			activityStart: "",
			activityEnd: "",
			caloriesBurnt: null,
			permissionsGranted: false,
			healthAppConnected: false,
			unAuthorized: false,
		};
	},
};
